import { useEffect, Fragment } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Box, Button, Container, Link, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import gtm from "../../helpers/gtm";

const UnknownError = ({ resetErrorBoundary }) => {

  // Hooks

  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  // Google Tag Manager

useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  // Handle Retry & Refresh Browser Page & Go Home

  const handleRetry = () => {
    resetErrorBoundary();
  };

  const handleRefreshBrowserPage = () => {
    window.location.reload();
  };

  const handleGoHome = () => {
    resetErrorBoundary();
    navigate("/app");
  };

  // Rendering

  return (
    <Fragment>
      <Helmet>
        <title>Lean ads | Unknown Error</title>
      </Helmet>
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "background.default",
          display: "flex",
          minHeight: "100%",
          px: 3,
          py: "80px",
        }}
      >
        <Container>
          <Typography
            align="center"
            color="textPrimary"
            variant={mobileDevice ? "h4" : "h1"}
          >
            Unknown Error
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 0.5 }}
            variant="subtitle2"
          >
            Hmm... you've reached an unclassified error 💥{" "}
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 0.5 }}
            variant="subtitle2"
          >
            <Typography
              display="inline"
              color="textSecondary"
              variant="subtitle2"
            >
              Thats pretty rare.{" "}
            </Typography>
            <Typography
              display="inline"
              style={{ textDecoration: "line-through" }}
              color="textSecondary"
              variant="subtitle2"
            >
              Congratulations
            </Typography>
            <Typography
              display="inline"
              color="textSecondary"
              variant="subtitle2"
            >
              {" "}
              Apologies.
            </Typography>
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 2 }}
            variant="subtitle2"
          >
            You can cross your fingers 🤞 and{" "}
            <Link
              color="textPrimary"
              href="#"
              underline="hover"
              onClick={handleRetry}
            >
              try again
            </Link>
            .
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 0.5 }}
            variant="subtitle2"
          >
            If it does not work, you can{" "}
            <Link
              color="textPrimary"
              href="#"
              underline="hover"
              onClick={handleRefreshBrowserPage}
            >
              refresh the page
            </Link>
            .
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 0.5 }}
            variant="subtitle2"
          >
            {/*//TODO insert link "contact us"*/}
            If it still does not work, please feel free to contact us.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Box
              alt="Server Error"
              component="img"
              src={`/static/error/error500_${theme.palette.mode}.svg`}
              sx={{
                height: "auto",
                maxWidth: "100%",
                width: 400,
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Button
              color="primary"
              component={RouterLink}
              onClick={handleGoHome}
              to="/app/"
              variant="outlined"
            >
              Back Home
            </Button>
          </Box>
        </Container>
      </Box>
    </Fragment>
  );
};

export default UnknownError;

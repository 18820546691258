import { Navigate } from 'react-router-dom';
import { Fragment } from 'react'
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";

const UserEmailVerifiedGuard = ({ children }) => {

  // Hooks

  const {isAuthenticated, currentUser } = useSelector((state) => state.auth);

  // Rendering

  if (isAuthenticated && currentUser.hasVerifiedEmail) {
    return <Navigate to="/app/" />;
  }

  return <Fragment>{children}</Fragment>;
};

UserEmailVerifiedGuard.propTypes = {
  children: PropTypes.node
};

export default UserEmailVerifiedGuard;

import { createSlice } from "@reduxjs/toolkit";

const initialFilters = {
  tab: "all",
  text: "",
  sortBy: "startDate-desc",
  startDate: null,
  endDate: null,
};

// INITIAL STATE
const initialState = {
  lineItems: initialFilters,
  campaigns: [],
  offers: [],
};

const lineItemFiltersSlice = createSlice({
  name: "lineItemFilters",
  initialState,
  reducers: {
    setTabFilter: (state, action) => {
      switch (action.payload.module) {
        case "lineItems":
          state.lineItems.tab = action.payload.tab;
          break;
        case "campaigns":
          state.campaigns = state.campaigns.map((campaign) => {
            if (campaign.id === action.payload.id) {
              const updatedFilters = {
                ...campaign.filters,
                tab: action.payload.tab,
              };
              return { ...campaign, filters: updatedFilters };
            } else {
              return campaign;
            }
          });
          break;
        case "offers":
          state.offers = state.offers.map((offer) => {
            if (offer.id === action.payload.id) {
              const updatedFilters = {
                ...offer.filters,
                tab: action.payload.tab,
              };
              return { ...offer, filters: updatedFilters };
            } else {
              return offer;
            }
          });
          break;

        default:
          break;
      }
    },
    setTextFilter: (state, action) => {
      switch (action.payload.module) {
        case "lineItems":
          state.lineItems.text = action.payload.text;
          break;
        case "campaigns":
          state.campaigns = state.campaigns.map((campaign) => {
            if (campaign.id === action.payload.id) {
              const updatedFilters = {
                ...campaign.filters,
                text: action.payload.text,
              };
              return { ...campaign, filters: updatedFilters };
            } else {
              return campaign;
            }
          });
          break;
        case "offers":
          state.offers = state.offers.map((offer) => {
            if (offer.id === action.payload.id) {
              const updatedFilters = {
                ...offer.filters,
                text: action.payload.text,
              };
              return { ...offer, filters: updatedFilters };
            } else {
              return offer;
            }
          });
          break;

        default:
          break;
      }
    },
    setStartDate: (state, action) => {
      switch (action.payload.module) {
        case "lineItems":
          state.lineItems.startDate = action.payload.startDate;
          break;
        case "campaigns":
          state.campaigns = state.campaigns.map((campaign) => {
            if (campaign.id === action.payload.id) {
              const updatedFilters = {
                ...campaign.filters,
                startDate: action.payload.startDate,
              };
              return { ...campaign, filters: updatedFilters };
            } else {
              return campaign;
            }
          });
          break;
        case "offers":
          state.offers = state.offers.map((offer) => {
            if (offer.id === action.payload.id) {
              const updatedFilters = {
                ...offer.filters,
                startDate: action.payload.startDate,
              };
              return { ...offer, filters: updatedFilters };
            } else {
              return offer;
            }
          });
          break;

        default:
          break;
      }
    },
    setEndDate: (state, action) => {
      switch (action.payload.module) {
        case "lineItems":
          state.lineItems.endDate = action.payload.endDate;
          break;
        case "campaigns":
          state.campaigns = state.campaigns.map((campaign) => {
            if (campaign.id === action.payload.id) {
              const updatedFilters = {
                ...campaign.filters,
                endDate: action.payload.endDate,
              };
              return { ...campaign, filters: updatedFilters };
            } else {
              return campaign;
            }
          });
          break;
        case "offers":
          state.offers = state.offers.map((offer) => {
            if (offer.id === action.payload.id) {
              const updatedFilters = {
                ...offer.filters,
                endDate: action.payload.endDate,
              };
              return { ...offer, filters: updatedFilters };
            } else {
              return offer;
            }
          });
          break;

        default:
          break;
      }
    },
    sortByNameAsc: (state, action) => {
      switch (action.payload.module) {
        case "lineItems":
          state.lineItems.sortBy = "name-asc";
          break;
        case "campaigns":
          state.campaigns = state.campaigns.map((campaign) => {
            if (campaign.id === action.payload.id) {
              const updatedFilters = {
                ...campaign.filters,
                sortBy: "name-asc",
              };
              return { ...campaign, filters: updatedFilters };
            } else {
              return campaign;
            }
          });
          break;
        case "offers":
          state.offers = state.offers.map((offer) => {
            if (offer.id === action.payload.id) {
              const updatedFilters = {
                ...offer.filters,
                sortBy: "name-asc",
              };
              return { ...offer, filters: updatedFilters };
            } else {
              return offer;
            }
          });
          break;
          default:
          break;
      }
    },
    sortByNameDesc: (state, action) => {
      switch (action.payload.module) {
        case "lineItems":
          state.lineItems.sortBy = "name-desc";
          break;
        case "campaigns":
          state.campaigns = state.campaigns.map((campaign) => {
            if (campaign.id === action.payload.id) {
              const updatedFilters = {
                ...campaign.filters,
                sortBy: "name-desc",
              };
              return { ...campaign, filters: updatedFilters };
            } else {
              return campaign;
            }
          });
          break;
        case "offers":
          state.offers = state.offers.map((offer) => {
            if (offer.id === action.payload.id) {
              const updatedFilters = {
                ...offer.filters,
                sortBy: "name-desc",
              };
              return { ...offer, filters: updatedFilters };
            } else {
              return offer;
            }
          });
          break;
          default:
          break;
      }
    },
    sortByClientBudgetAsc: (state, action) => {
      switch (action.payload.module) {
        case "lineItems":
          state.lineItems.sortBy = "clientBudget-asc";
          break;
        case "campaigns":
          state.campaigns = state.campaigns.map((campaign) => {
            if (campaign.id === action.payload.id) {
              const updatedFilters = {
                ...campaign.filters,
                sortBy: "clientBudget-asc",
              };
              return { ...campaign, filters: updatedFilters };
            } else {
              return campaign;
            }
          });
          break;
        case "offers":
          state.offers = state.offers.map((offer) => {
            if (offer.id === action.payload.id) {
              const updatedFilters = {
                ...offer.filters,
                sortBy: "clientBudget-asc",
              };
              return { ...offer, filters: updatedFilters };
            } else {
              return offer;
            }
          });
          break;
          default:
          break;
      }
    },
    sortByClientBudgetDesc: (state, action) => {
      switch (action.payload.module) {
        case "lineItems":
          state.lineItems.sortBy = "clientBudget-desc";
          break;
        case "campaigns":
          state.campaigns = state.campaigns.map((campaign) => {
            if (campaign.id === action.payload.id) {
              const updatedFilters = {
                ...campaign.filters,
                sortBy: "clientBudget-desc",
              };
              return { ...campaign, filters: updatedFilters };
            } else {
              return campaign;
            }
          });
          break;
        case "offers":
          state.offers = state.offers.map((offer) => {
            if (offer.id === action.payload.id) {
              const updatedFilters = {
                ...offer.filters,
                sortBy: "clientBudget-desc",
              };
              return { ...offer, filters: updatedFilters };
            } else {
              return offer;
            }
          });
          break;
          default:
          break;
      }
    },
    sortByStartDateAsc: (state, action) => {
      switch (action.payload.module) {
        case "lineItems":
          state.lineItems.sortBy = "startDate-asc";
          break;
        case "campaigns":
          state.campaigns = state.campaigns.map((campaign) => {
            if (campaign.id === action.payload.id) {
              const updatedFilters = {
                ...campaign.filters,
                sortBy: "startDate-asc",
              };
              return { ...campaign, filters: updatedFilters };
            } else {
              return campaign;
            }
          });
          break;
        case "offers":
          state.offers = state.offers.map((offer) => {
            if (offer.id === action.payload.id) {
              const updatedFilters = {
                ...offer.filters,
                sortBy: "startDate-asc",
              };
              return { ...offer, filters: updatedFilters };
            } else {
              return offer;
            }
          });
          break;
          default:
          break;
      }
    },
    sortByStartDateDesc: (state, action) => {
      switch (action.payload.module) {
        case "lineItems":
          state.lineItems.sortBy = "startDate-desc";
          break;
        case "campaigns":
          state.campaigns = state.campaigns.map((campaign) => {
            if (campaign.id === action.payload.id) {
              const updatedFilters = {
                ...campaign.filters,
                sortBy: "startDate-desc",
              };
              return { ...campaign, filters: updatedFilters };
            } else {
              return campaign;
            }
          });
          break;
        case "offers":
          state.offers = state.offers.map((offer) => {
            if (offer.id === action.payload.id) {
              const updatedFilters = {
                ...offer.filters,
                sortBy: "startDate-desc",
              };
              return { ...offer, filters: updatedFilters };
            } else {
              return offer;
            }
          });
          break;
          default:
          break;
      }
    },
    initFilterSet: (state, action) => {
      switch (action.payload.module) {
        case "lineItems":
          state.lineItems = initialFilters;
          break;
        case "campaigns":
          if (
            !state.campaigns.find(
              (campaign) => campaign.id === action.payload.id
            )
          ) {
            state.campaigns.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;
        case "offers":
          if (!state.offers.find((offer) => offer.id === action.payload.id)) {
            state.offers.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;

        default:
          break;
      }
    },
  },
});

export const {
  setTabFilter,
  setTextFilter,
  setStartDate,
  setEndDate,
  sortByNameAsc,
  sortByNameDesc,
  sortByClientBudgetAsc,
  sortByClientBudgetDesc,
  sortByStartDateAsc,
  sortByStartDateDesc,
  initFilterSet,
} = lineItemFiltersSlice.actions;
export const lineItemFiltersReducer = lineItemFiltersSlice.reducer;

import { ProblemTypeBreadcrumb } from "../breadcrumbs/problemType";
import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import { DATA } from "../constants";
import postRoutes from "./post";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";
import PermissionsGuard from "../components/routes/PermissionsGuard";

// Pages
const ProblemTypeList = Loadable(
  lazy(() => import("../pages/problemTypes/ProblemTypeList"))
);
const ProblemTypeCreate = Loadable(
  lazy(() => import("../pages/problemTypes/ProblemTypeCreate"))
);
const ProblemTypeView = Loadable(
  lazy(() => import("../pages/problemTypes/ProblemTypeView"))
);
const ProblemTypeEdit = Loadable(
  lazy(() => import("../pages/problemTypes/ProblemTypeEdit"))
);

// Direct parent pages
const PlatformView = Loadable(
  lazy(() => import("../pages/platforms/PlatformView"))
);
const PlatformProblemTypeCreate = Loadable(
  lazy(() => import("../pages/platforms/PlatformProblemTypeCreate"))
);

const problemTypesRoutes = (directParentType = "", grandParentTypes = []) => {
  const listComponent =
    directParentType === DATA.PLATFORM.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.PLATFORM.type, action: "READ" },
          { feature: DATA.PROBLEMTYPE.type, action: "READ" },
        ]}
      >
        <PlatformView
          tab="problemTypes"
          parentTypes={
            directParentType ? [...grandParentTypes, directParentType] : []
          }
        />
      </PermissionsGuard>
    ) : (
      <PermissionsGuard
        neededPermissions={[{ feature: DATA.PROBLEMTYPE.type, action: "READ" }]}
      >
        <ProblemTypeList />
      </PermissionsGuard>
    );
  const createComponent =
    directParentType === DATA.PLATFORM.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.PLATFORM.type, action: "READ" },
          { feature: DATA.PROBLEMTYPE.type, action: "CREATE" },
        ]}
      >
        <PlatformProblemTypeCreate
          parentTypes={
            directParentType ? [...grandParentTypes, directParentType] : []
          }
        />
      </PermissionsGuard>
    ) : (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.PROBLEMTYPE.type, action: "CREATE" },
        ]}
      >
        <ProblemTypeCreate />
      </PermissionsGuard>
    );

  const route = {
    path: "problemTypes",
    children: [
      {
        path: "",
        element: listComponent,
        breadcrumb: capitalizeFirstLetter(DATA.PROBLEMTYPE.readablePlural),
      },
      {
        path: "create",
        element: createComponent,
        breadcrumb: "Create new",
      },
      {
        path: ":problemTypeId",
        children: [
          {
            path: "",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.PROBLEMTYPE.type, action: "READ" },
                ]}
              >
                <ProblemTypeView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: ProblemTypeBreadcrumb,
          },
          {
            path: "edit",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.PROBLEMTYPE.type, action: "UPDATE" },
                ]}
              >
                <ProblemTypeEdit
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Edit",
          },
          {
            path: "about",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.PROBLEMTYPE.type, action: "READ" },
                ]}
              >
                <ProblemTypeView
                  tab="about"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "About",
          },
          {
            ...postRoutes(
              DATA.PROBLEMTYPE.type,
              directParentType ? [...grandParentTypes, directParentType] : []
            ),
          },
        ],
      },
    ],
  };

  return route;
};

export default problemTypesRoutes;

import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import { useGetETLRun } from "../queries/etlRuns";

export const ETLRunBreadcrumb = ({ match }) => {
    const {
      data: etlRun,
      isLoading,
      isError,
      error,
    } = useGetETLRun(match.params.etlRunId);
  
    if (isLoading) {
      //return <span>Loading...</span>;
      return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
    }
  
    if (isError && error instanceof NotFoundException) {
      return <span>Not found</span>;
    }
  
    if (etlRun) {
      return <span>{etlRun.name}</span>;
    }
  
    return null;
  };

  
import { ETLRunBreadcrumb } from "../breadcrumbs/etlRun";
import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import { DATA } from "../constants";
import etlTaskRoutes from "./etlTask";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";
import PermissionsGuard from "../components/routes/PermissionsGuard";

// Pages
const ETLRunList = Loadable(lazy(() => import("../pages/etlRuns/ETLRunList")));
const ETLRunView = Loadable(lazy(() => import("../pages/etlRuns/ETLRunView")));

const ETLRunRoutes = (directParentType = "", grandParentTypes = []) => {
  // there is no parent for etlRun
  const listComponent = (
    <PermissionsGuard
      neededPermissions={[{ feature: DATA.ETLRUN.type, action: "READ" }]}
    >
      <ETLRunList />
    </PermissionsGuard>
  );

  const route = {
    path: "ETLRuns",
    children: [
      {
        path: "",
        element: listComponent,
        breadcrumb: capitalizeFirstLetter(DATA.ETLRUN.readablePlural),
      },
      {
        path: ":etlRunId",
        children: [
          {
            path: "",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ETLRUN.type, action: "READ" },
                ]}
              >
                <ETLRunView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />{" "}
              </PermissionsGuard>
            ),
            breadcrumb: ETLRunBreadcrumb,
          },
          {
            path: "about",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ETLRUN.type, action: "READ" },
                ]}
              >
                <ETLRunView
                  tab="about"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "About",
          },
          {
            ...etlTaskRoutes(
              DATA.ETLRUN.type,
              directParentType ? [...grandParentTypes, directParentType] : []
            ),
          },
        ],
      },
    ],
  };

  return route;
};

export default ETLRunRoutes;

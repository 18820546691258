import * as api from "../apis/brandsApi";
import { NotFoundException } from "../customExceptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetBrands = () =>
  useQuery(["brands"], api.getBrands, {
    useErrorBoundary: true,
  });

export const useGetBrandsByAdvertiser = (advertiserId) => {
  const queryClient = useQueryClient();

  return useQuery(
    ["advertiser.brands", advertiserId],
    () => api.getBrandsByAdvertiser(advertiserId),
    {
      enabled: Boolean(advertiserId),
      useErrorBoundary: true,
      initialData: () =>
        queryClient
          .getQueryData(["brands"])
          ?.filter((brand) => brand.advertiser.id === advertiserId),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(["brands"])?.dataUpdatedAt,
    }
  );
};

export const useGetBrand = (id) => {
  const queryClient = useQueryClient();
  let queryKey;
  return useQuery(["brand", id], () => api.getBrand(id), {
    enabled: Boolean(id),
    useErrorBoundary: (error) => !(error instanceof NotFoundException),
    initialData: () => {
      // if "brands" query exist, we use it to init data
      if (queryClient.getQueryState(["brands"])) {
        queryKey = ["brands"];
        return queryClient
          .getQueryData(["brands"])
          ?.find((brand) => brand.id === id);
      }

      // otherwise, we try to find the brand in one of the "advertiser.brands" queries
      let found;

      queryClient.getQueriesData(["advertiser.brands"]).forEach((query) => {
        const data = query[1];
        data.forEach((brand) => {
          if (brand.id === id) {
            found = brand;
            queryKey = query[0];
          }
        });
      });
      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

import { createSlice } from "@reduxjs/toolkit";

const initialFilters = {
  tab: "all",
  text: "",
  sortBy: "name-desc",
};

// INITIAL STATE
const initialState = {
  adOpsCampaigns: initialFilters,
  lineItems: [],
};

const adOpsCampaignFiltersSlice = createSlice({
  name: "adOpsCampaignFilters",
  initialState,
  reducers: {
    setTabFilter: (state, action) => {
      switch (action.payload.module) {
        case "adOpsCampaigns":
          state.adOpsCampaigns.tab = action.payload.tab;
          break;
        case "lineItems":
          state.lineItems = state.lineItems.map((lineItem) => {
            if (lineItem.id === action.payload.id) {
              const updatedFilters = {
                ...lineItem.filters,
                tab: action.payload.tab,
              };
              return { ...lineItem, filters: updatedFilters };
            } else {
              return lineItem;
            }
          });
          break;
        default:
          break;
      }
    },
    setTextFilter: (state, action) => {
      switch (action.payload.module) {
        case "adOpsCampaigns":
          state.adOpsCampaigns.text = action.payload.text;
          break;
        case "lineItems":
          state.lineItems = state.lineItems.map((lineItem) => {
            if (lineItem.id === action.payload.id) {
              const updatedFilters = {
                ...lineItem.filters,
                text: action.payload.text,
              };
              return { ...lineItem, filters: updatedFilters };
            } else {
              return lineItem;
            }
          });
          break;
        default:
          break;
      }
    },
    sortByNameAsc: (state, action) => {
      switch (action.payload.module) {
        case "adOpsCampaigns":
          state.adOpsCampaigns.sortBy = "name-asc";
          break;
        case "lineItems":
          state.lineItems = state.lineItems.map((lineItem) => {
            if (lineItem.id === action.payload.id) {
              const updatedFilters = {
                ...lineItem.filters,
                sortBy: "name-asc",
              };
              return { ...lineItem, filters: updatedFilters };
            } else {
              return lineItem;
            }
          });
          break;

        default:
          break;
      }
    },
    sortByNameDesc: (state, action) => {
      switch (action.payload.module) {
        case "adOpsCampaigns":
          state.adOpsCampaigns.sortBy = "name-desc";
          break;
        case "lineItems":
          state.lineItems = state.lineItems.map((lineItem) => {
            if (lineItem.id === action.payload.id) {
              const updatedFilters = {
                ...lineItem.filters,
                sortBy: "name-desc",
              };
              return { ...lineItem, filters: updatedFilters };
            } else {
              return lineItem;
            }
          });
          break;

        default:
          break;
      }
    },

    initFilterSet: (state, action) => {
      switch (action.payload.module) {
        case "adOpsCampaigns":
          state.adOpsCampaigns = initialFilters;
          break;
        case "lineItems":
          if (
            !state.lineItems.find((lineItem) => lineItem.id === action.payload.id)
          ) {
            state.lineItems.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;
        
        default:
          break;
      }
    },
  },
});

export const {
  setTabFilter,
  setTextFilter,
  sortByNameAsc,
  sortByNameDesc,
  initFilterSet,
} = adOpsCampaignFiltersSlice.actions;
export const adOpsCampaignFiltersReducer = adOpsCampaignFiltersSlice.reducer;

import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import { useGetCountry } from "../queries/countries";

export const CountryBreadcrumb = ({ match }) => {
    const {
      data: country,
      isLoading,
      isError,
      error,
    } = useGetCountry(match.params.countryId);
  
    if (isLoading) {
      //return <span>Loading...</span>;
      return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
    }
  
    if (isError && error instanceof NotFoundException) {
      return <span>Not found</span>;
    }
  
    if (country) {
      return <span>{country.name}</span>;
    }
  
    return null;
  };
import { LineItemBreadcrumb } from "../breadcrumbs/lineItem";
import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import { DATA } from "../constants";
import adOpsCampaignRoutes from "./adOpsCampaign";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";
import PermissionsGuard from "../components/routes/PermissionsGuard";

// Pages
const LineItemList = Loadable(
  lazy(() => import("../pages/lineItems/LineItemList"))
);
const LineItemCreate = Loadable(
  lazy(() => import("../pages/lineItems/LineItemCreate"))
);
const LineItemView = Loadable(
  lazy(() => import("../pages/lineItems/LineItemView"))
);
const LineItemEdit = Loadable(
  lazy(() => import("../pages/lineItems/LineItemEdit"))
);

// Direct parent pages
const CampaignView = Loadable(
  lazy(() => import("../pages/campaigns/CampaignView"))
);
const CampaignLineItemCreate = Loadable(
  lazy(() => import("../pages/campaigns/CampaignLineItemCreate"))
);

const OfferView = Loadable(lazy(() => import("../pages/offers/OfferView")));
const OfferLineItemCreate = Loadable(
  lazy(() => import("../pages/offers/OfferLineItemCreate"))
);

const lineItemRoutes = (directParentType = "", grandParentTypes = []) => {
  const listComponent =
    directParentType === DATA.CAMPAIGN.type ? (
      // Here we call the parent component. So we pass grand-parents as parents
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.CAMPAIGN.type, action: "READ" },
          { feature: DATA.LINEITEM.type, action: "READ" },
        ]}
      >
        <CampaignView tab="lineItems" parentTypes={grandParentTypes} />
      </PermissionsGuard>
    ) : directParentType === DATA.OFFER.type ? (
      // Here we call the parent component. So we pass grand-parents as parents
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.OFFER.type, action: "READ" },
          { feature: DATA.LINEITEM.type, action: "READ" },
        ]}
      >
        <OfferView tab="lineItems" parentTypes={grandParentTypes} />
      </PermissionsGuard>
    ) : (
      <PermissionsGuard
        neededPermissions={[{ feature: DATA.LINEITEM.type, action: "READ" }]}
      >
        <LineItemList />
      </PermissionsGuard>
    );

  const createComponent =
    directParentType === DATA.CAMPAIGN.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.CAMPAIGN.type, action: "READ" },
          { feature: DATA.LINEITEM.type, action: "CREATE" },
        ]}
      >
        <CampaignLineItemCreate
          parentTypes={
            directParentType ? [...grandParentTypes, directParentType] : []
          }
        />
      </PermissionsGuard>
    ) : directParentType === DATA.OFFER.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.OFFER.type, action: "READ" },
          { feature: DATA.LINEITEM.type, action: "CREATE" },
        ]}
      >
        <OfferLineItemCreate
          parentTypes={
            directParentType ? [...grandParentTypes, directParentType] : []
          }
        />
      </PermissionsGuard>
    ) : (
      <PermissionsGuard
        neededPermissions={[{ feature: DATA.LINEITEM.type, action: "CREATE" }]}
      >
        <LineItemCreate />
      </PermissionsGuard>
    );

  const route = {
    path: "lineItems",
    children: [
      {
        path: "",
        element: listComponent,
        breadcrumb: capitalizeFirstLetter(DATA.LINEITEM.readablePlural),
      },
      {
        path: "create",
        element: createComponent,
        breadcrumb: "Create new",
      },
      {
        path: ":lineItemId",
        children: [
          {
            path: "",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.LINEITEM.type, action: "READ" },
                ]}
              >
                <LineItemView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: LineItemBreadcrumb,
          },
          {
            path: "edit",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.LINEITEM.type, action: "UPDATE" },
                ]}
              >
                <LineItemEdit
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Edit",
          },
          {
            path: "about",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.LINEITEM.type, action: "READ" },
                ]}
              >
                <LineItemView
                  tab="about"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "About",
          },
          {
            path: "goals",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.LINEITEM.type, action: "READ" },
                ]}
              >
                <LineItemView
                  tab="goals"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Goals",
          },
          {
            path: "assets",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.LINEITEM.type, action: "READ" },
                ]}
              >
                <LineItemView
                  tab="assets"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Assets",
          },
          {
            path: "setup",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.LINEITEM.type, action: "READ" },
                ]}
              >
                <LineItemView
                  tab="setup"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Setup",
          },
          {
            path: "performance",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.LINEITEM.type, action: "READ" },
                ]}
              >
                <LineItemView
                  tab="performance"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Performance",
          },
          {
            path: "dataCheck",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.LINEITEM.type, action: "READ" },
                ]}
              >
                <LineItemView
                  tab="dataCheck"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Data check",
          },
          {
            path: "presentation",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.LINEITEM.type, action: "READ" },
                ]}
              >
                <LineItemView
                  tab="presentation"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Presentation",
          },
          {
            ...adOpsCampaignRoutes(
              DATA.LINEITEM.type,
              directParentType ? [...grandParentTypes, directParentType] : []
            ),
          },
        ],
      },
    ],
  };

  return route;
};

export default lineItemRoutes;

import { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { experimentalStyled } from "@material-ui/core/styles";
import AppNavbar from "./Navbar";
import AppSidebar from "./Sidebar";
import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../../error/ErrorFallback";
import { useSelector } from "react-redux";
import ContentLayout from "./ContentLayout";
import styled from '@emotion/styled';


// Styles

const AppLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const AppLayoutWrapper = styled.div(({ theme, isCollapsed }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  //paddingTop: "64px",
  paddingTop: "48px",
  paddingBottom: "10px",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: isCollapsed ? "80px" : "280px",
  },
}));

const AppLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const AppLayoutContent = experimentalStyled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});

const AppLayout = () => {
  // Hooks

  const location = useLocation();
  const { pageTitle } = useSelector((state) => state.appLayout);
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
  const { reset } = useQueryErrorResetBoundary();
  const [isCollapsed, setIsCollapsed] = useState(false);
  
  // Handle Log Error

  const logError = (error, info) => {
    // A fake logging service.
    const logErrorToServices = console.log;

    logErrorToServices(
      "Status",
      error.constructor.name,
      "Message",
      error.message?.toString(),
      "Stack",
      info.componentStack
    );
  };

  // Rendering

  return (
    // this error boundary catches errors OUTSIDE of AppLayoutContent
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
      <AppLayoutRoot>
        <AppNavbar onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)} />
        <AppSidebar
          onMobileClose={() => setIsSidebarMobileOpen(false)}
          openMobile={isSidebarMobileOpen}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
        <AppLayoutWrapper isCollapsed={isCollapsed}>
          <AppLayoutContainer>
            <AppLayoutContent>
              <ErrorBoundary
                // the key parameter is used to unmount the error boundary
                // fallback component each time the location is changing.
                // So that other routes will still work even if an error occured on 1 route
                key={location.pathname}
                FallbackComponent={ErrorFallback}
                onReset={reset}
                onError={logError}
              >
                {pageTitle ? (
                  <ContentLayout>
                    <Outlet />
                  </ContentLayout>
                ) : (
                  <Outlet />
                )}
              </ErrorBoundary>
            </AppLayoutContent>
          </AppLayoutContainer>
        </AppLayoutWrapper>
      </AppLayoutRoot>
    </ErrorBoundary>
  );
};

export default AppLayout;

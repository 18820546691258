import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import PermissionsGuard from "../components/routes/PermissionsGuard";

// Pages
const DataImports = Loadable(
  lazy(() => import("../pages/dataImports/DataImports"))
);

const dataImportsRoute = () => ({
  path: "dataImports",
  element: (
    <PermissionsGuard
      neededPermissions={[{ feature: "dataImports", action: "READ" }]}
    >
      <DataImports />
    </PermissionsGuard>
  ),
  breadcrumb: "Data imports",
});

export default dataImportsRoute;

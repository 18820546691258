import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import PermissionsGuard from "../components/routes/PermissionsGuard";

// Pages
const CampaignAnalytics = Loadable(
  lazy(() => import("../pages/campaignAnalytics/CampaignAnalytics"))
);

const campaignAnalyticsRoute = () => ({
  path: "campaignAnalytics",
  element: (
    <PermissionsGuard
      neededPermissions={[{ feature: "campaignAnalytics", action: "READ" }]}
    >
      <CampaignAnalytics />
    </PermissionsGuard>
  ),
  breadcrumb: "Analytics",
});

export default campaignAnalyticsRoute;

import AnalyticsIcon from "./icons/ChartPie";
import DoctorIcon from "@material-ui/icons/MedicalServices";
import AgendaIcon from "@mui/icons-material/DateRange";
import UserIcon from "./icons/Users";
import CampaignIcon from "@material-ui/icons/Campaign";
import ETLRunIcon from "@material-ui/icons/DataUsage";
import ETLTaskIcon from "@material-ui/icons/Engineering";
import BrandIcon from "@material-ui/icons/LocalMall";
import AdvertiserIcon from "./icons/Briefcase";
import AccountIcon from "@material-ui/icons/Tag";
import PlatformIcon from "@material-ui/icons/Apps";
import PostIcon from "@material-ui/icons/Image";
import PostBoostCampaignIcon from "@material-ui/icons/Construction";
import ProblemTypeIcon from "@material-ui/icons/Warning";
import DataOriginIcon from "@material-ui/icons/FolderOpen";
import DataImportIcon from "@material-ui/icons/Upload";
import AdAccountIcon from "@material-ui/icons/Home";
import CountryIcon from "@material-ui/icons/Language";
import MetricIcon from "@material-ui/icons/Equalizer";
import OfferIcon from "@material-ui/icons/LocalOffer";
import AdOpsCampaignIcon from "@material-ui/icons/Construction";
import LineItemIcon from "@mui/icons-material/Toc";
import AdPlatformIcon from "@material-ui/icons/Apps";
import AdFormatIcon from "@mui/icons-material/FitScreen";
import AdOpsObjectiveIcon from "@mui/icons-material/SportsScore";
import SpecTemplateIcon from "@mui/icons-material/ImageAspectRatio";

export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

export const REPORTING_TEMPLATES = [
  "Video",
  "Image 3 phases - Competition",
  "Image 3 phases - Engagement",
  "Image 3 phases - Reach",
  "Carousel Reach",
  "Not supported",
];

export const ASSET_TYPES = ["Upload", "Post", "Dark post", "Post creator"];

export const DATA = {
  CAMPAIGN: {
    type: "campaign",
    typePlural: "campaigns",
    readable: "campaign",
    readablePlural: "campaigns",
    canCreate: true,
    canEdit: true,
    canDelete: true,
  },
  ADVERTISER: {
    type: "advertiser",
    typePlural: "advertisers",
    readable: "advertiser",
    readablePlural: "advertisers",
    canCreate: true,
    canEdit: true,
    canDelete: true,
  },
  BRAND: {
    type: "brand",
    typePlural: "brands",
    readable: "brand",
    readablePlural: "brands",
    canCreate: true,
    canEdit: true,
    canDelete: true,
  },
  PLATFORM: {
    type: "platform",
    typePlural: "platforms",
    readable: "platform",
    readablePlural: "platforms",
    canCreate: true,
    canEdit: true,
    canDelete: true,
  },
  ACCOUNT: {
    type: "account",
    typePlural: "accounts",
    readable: "account",
    readablePlural: "accounts",
    canCreate: true,
    canEdit: true,
    canDelete: true,
  },
  POST: {
    type: "post",
    typePlural: "posts",
    readable: "post",
    readablePlural: "posts",
    canCreate: false,
    canEdit: true,
    canDelete: false,
  },
  DATAORIGIN: {
    type: "dataOrigin",
    typePlural: "dataOrigins",
    readable: "data origin",
    readablePlural: "data origins",
    canCreate: false,
    canEdit: true,
    canDelete: false,
  },
  POSTBOOSTCAMPAIGN: {
    type: "postBoostCampaign",
    typePlural: "postBoostCampaigns",
    readable: "post boost campaign",
    readablePlural: "post boost campaigns",
    canCreate: false,
    canEdit: false,
    canDelete: false,
  },
  USER: {
    type: "user",
    typePlural: "users",
    readable: "user",
    readablePlural: "users",
    canCreate: true,
    canEdit: true,
    canDelete: true,
  },
  PROBLEMTYPE: {
    type: "problemType",
    typePlural: "problemTypes",
    readable: "problem type",
    readablePlural: "problem types",
    canCreate: true,
    canEdit: true,
    canDelete: true,
  },
  ETLRUN: {
    type: "etlRun",
    typePlural: "etlRuns",
    readable: "ETL run",
    readablePlural: "ETL runs",
    canCreate: false,
    canEdit: false,
    canDelete: false,
  },
  ETLTASK: {
    type: "etlTask",
    typePlural: "etlTasks",
    readable: "ETL task",
    readablePlural: "ETL tasks",
    canCreate: false,
    canEdit: false,
    canDelete: false,
  },
  ADACCOUNT: {
    type: "adAccount",
    typePlural: "adAccounts",
    readable: "ad account",
    readablePlural: "ad accounts",
    canCreate: false,
    canEdit: true,
    canDelete: false,
  },
  COUNTRY: {
    type: "country",
    typePlural: "countries",
    readable: "country",
    readablePlural: "countries",
    canCreate: true,
    canEdit: true,
    canDelete: true,
  },
  METRIC: {
    type: "metric",
    typePlural: "metrics",
    readable: "metric",
    readablePlural: "metrics",
    canCreate: true,
    canEdit: true,
    canDelete: true,
  },
  OFFER: {
    type: "offer",
    typePlural: "offers",
    readable: "offer",
    readablePlural: "offers",
    canCreate: true,
    canEdit: true,
    canDelete: true,
  },
  LINEITEM: {
    type: "lineItem",
    typePlural: "lineItems",
    readable: "line item",
    readablePlural: "line items",
    canCreate: true,
    canEdit: true,
    canDelete: true,
  },
  ADOPSCAMPAIGN: {
    type: "adOpsCampaign",
    typePlural: "adOpsCampaigns",
    readable: "ad ops campaign",
    readablePlural: "ad ops campaigns",
    canCreate: false,
    canEdit: false,
    canDelete: false,
  },
  ADPLATFORM: {
    type: "adPlatform",
    typePlural: "adPlatforms",
    readable: "ad platform",
    readablePlural: "ad platforms",
    canCreate: false,
    canEdit: false,
    canDelete: false,
  },
  ADFORMAT: {
    type: "adFormat",
    typePlural: "adFormats",
    readable: "ad format",
    readablePlural: "ad formats",
    canCreate: true,
    canEdit: true,
    canDelete: true,
  },
  SPECTEMPLATE: {
    type: "specTemplate",
    typePlural: "specTemplates",
    readable: "spec template",
    readablePlural: "spec templates",
    canCreate: true,
    canEdit: true,
    canDelete: true,
  },
  ADOPSOBJECTIVE: {
    type: "adOpsObjective",
    typePlural: "adOpsObjectives",
    readable: "objective",
    readablePlural: "objectives",
    canCreate: true,
    canEdit: true,
    canDelete: true,
  },
};

export const URL_REGEX =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;

export const LIST_ICONS = {
  EXTERNAL: "external",
  EYE: "eye",
  DOCUMENT: "document",
  GOAL: "goal",
  BAR: "bar",
  REPORTING: "reporting",
  CONTENT: "content",
  WORKFLOW: "workflow",
  IMAGE: "image",
  SETTINGS: "settings",
};

export const GENDERS = [
  {
    id: "ALL",
    name: "Mixte",
  },
  {
    id: "FEMALE",
    name: "Femmes",
  },
  {
    id: "MALE",
    name: "Hommes",
  },
];

export const AGES = Array(101)
  .fill(0)
  .map((n, i) => n + i);

export const CAMPAIGN_STATUS = [
  {
    name: "Brief",
    bgcolor: "#67b4e0",
    color: "black",
    description: "Please enter a proposal",
  },
  {
    name: "Proposal",
    bgcolor: "#0f3999",
    color: "white",
    description: "Please validate the proposal",
  },
  {
    name: "Assets",
    bgcolor: "#e0b641",
    color: "black",
    description: "Please enter and validate line items assets",
  },
  {
    name: "Setup",
    bgcolor: "#a86c05",
    color: "white",
    description: "Please do the setup",
  },
  {
    name: "Waiting start",
    bgcolor: "#67e098",
    color: "black",
    description: "Please wait for start date",
  },
  {
    name: "Waiting LIVE check",
    bgcolor: "orange",
    color: "black",
    description: "Please check if LIVE",
  },
  {
    name: "Live",
    bgcolor: "green",
    color: "white",
    description: "Please wait for end date",
  },
  {
    name: "Report",
    bgcolor: "#d96de3",
    color: "black",
    description: "Please finalize margin and send end report to client",
  },
  {
    name: "Invoice",
    bgcolor: "#63046b",
    color: "white",
    description: "Please do the invoicing",
  },
  {
    name: "Finished",
    bgcolor: "black",
    color: "white",
    description: "Nothing to do",
  },
];

export const CAMPAIGN_DOCTOR_OFFSET_DAYS = {
  // relative to start date
  PROPOSAL: -5,
  PROPOSAL_VALIDATED: -4,
  ASSETS_VALID: -3,
  SETUP_DONE: -1,
  LIVE_CHECK: 0,
  EMAIL_START: 0,
  TARGETING_CHECK: 1,
  CONFIG_TPC_REPORTING: 2,
  GOAL_CHECK_START: 3,
  // relative to end date
  GOAL_CHECK_MID: -5,
  EMAIL_END: 1,
  MARGIN_FINALIZED: 3,
  REPORT: 14,
  INVOICE: 20,
};

export const MODULES = {
  SOCIAL: "social",
  CAMPAIGN: "campaigns",
  CLIENT: "clients",
  OFFER: "offers",
  ADMIN: "admin",
  ETL: "ETL",
};

export const MODULES_LIST = [
  {
    id: "social",
    name: "Social",
    shortName: "Soc",
  },
  {
    id: "campaigns",
    name: "Campaigns",
    shortName: "Cam",
  },
  {
    id: "clients",
    name: "Clients",
    shortName: "Cli",
  },
  {
    id: "offers",
    name: "Offers",
    shortName: "Off",
  },
  {
    id: "admin",
    name: "Admin",
    shortName: "Adm",
  },
  {
    id: "ETL",
    name: "ETL",
    shortName: "ETL",
  },
];

export const FEATURES = [
  // SOCIAL MODULE
  {
    id: "account",
    name: "Accounts",
    module: "social",
    options: {
      canCreate: DATA.ACCOUNT.canCreate,
      canUpdate: DATA.ACCOUNT.canEdit,
      canDelete: DATA.ACCOUNT.canDelete,
    },
    path: "/app/accounts",
    icon: <AccountIcon fontSize="small" />,
  },
  {
    id: "post",
    name: "Posts",
    module: "social",
    options: {
      canCreate: DATA.POST.canCreate,
      canUpdate: DATA.POST.canEdit,
      canDelete: DATA.POST.canDelete,
    },
    path: "/app/posts",
    icon: <PostIcon fontSize="small" />,
  },
  {
    id: "postBoostCampaign",
    name: "Post boost campaigns",
    module: "social",
    options: {
      canCreate: DATA.POSTBOOSTCAMPAIGN.canCreate,
      // the code is different here
      // thats because POSTBOOSTCAMPAIGN is not editable (=> DATA.POSTBOOSTCAMPAIGN.canEdit is FALSE)
      // unless in the Doctor => so we need canUpdate to be true so that we can handle update permission in the Doctor
      canUpdate: true,
      canDelete: DATA.POSTBOOSTCAMPAIGN.canDelete,
    },
    path: "/app/postBoostCampaigns",
    icon: <PostBoostCampaignIcon fontSize="small" />,
  },
  {
    id: "postBoostDoctor",
    name: "Doctor",
    module: "social",
    options: {
      canCreate: false,
      canUpdate: false,
      canDelete: false,
    },
    path: "/app/postBoostDoctor/",
    icon: <DoctorIcon fontSize="small" />,
  },
  // CAMPAIGNS MODULE
  {
    id: "campaign",
    name: "Campaigns",
    module: "campaigns",
    options: {
      canCreate: DATA.CAMPAIGN.canCreate,
      canUpdate: DATA.CAMPAIGN.canEdit,
      canDelete: DATA.CAMPAIGN.canDelete,
    },
    path: "/app/campaigns",
    icon: <CampaignIcon fontSize="small" />,
  },
  {
    id: "lineItem",
    name: "Line items",
    module: "campaigns",
    options: {
      canCreate: DATA.LINEITEM.canCreate,
      canUpdate: DATA.LINEITEM.canEdit,
      canDelete: DATA.LINEITEM.canDelete,
    },
    path: "/app/lineItems",
    icon: <LineItemIcon fontSize="small" />,
  },
  {
    id: "adOpsCampaign",
    name: "Ad Ops Campaigns",
    module: "campaigns",
    options: {
      canCreate: DATA.ADOPSCAMPAIGN.canCreate,
      canUpdate: DATA.ADOPSCAMPAIGN.canEdit,
      canDelete: DATA.ADOPSCAMPAIGN.canDelete,
    },
    path: "/app/adOpsCampaigns",
    icon: <AdOpsCampaignIcon fontSize="small" />,
  },
  {
    id: "campaignAnalytics",
    name: "Analytics",
    module: "campaigns",
    options: {
      canCreate: false,
      canUpdate: false,
      canDelete: false,
    },
    path: "/app/campaignAnalytics",
    icon: <AnalyticsIcon fontSize="small" />,
  },
  {
    id: "campaignDoctor",
    name: "Doctor",
    module: "campaigns",
    options: {
      canCreate: false,
      canUpdate: true,
      canDelete: false,
    },
    path: "/app/campaignDoctor/",
    icon: <DoctorIcon fontSize="small" />,
  },
  {
    id: "campaignAgenda",
    name: "Agenda",
    module: "campaigns",
    options: {
      canCreate: false,
      canUpdate: false,
      canDelete: false,
    },
    path: "/app/campaignAgenda/",
    icon: <AgendaIcon fontSize="small" />,
  },
  // CLIENT MODULE
  {
    id: "advertiser",
    name: "Advertisers",
    module: "clients",
    options: {
      canCreate: DATA.ADVERTISER.canCreate,
      canUpdate: DATA.ADVERTISER.canEdit,
      canDelete: DATA.ADVERTISER.canDelete,
    },
    path: "/app/advertisers",
    icon: <AdvertiserIcon fontSize="small" />,
  },
  {
    id: "brand",
    name: "Brands",
    module: "clients",
    options: {
      canCreate: DATA.BRAND.canCreate,
      canUpdate: DATA.BRAND.canEdit,
      canDelete: DATA.BRAND.canDelete,
    },
    path: "/app/brands",
    icon: <BrandIcon fontSize="small" />,
  },
  // OFFER MODULE
  {
    id: "platform",
    name: "Platforms",
    module: "offers",
    options: {
      canCreate: DATA.PLATFORM.canCreate,
      canUpdate: DATA.PLATFORM.canEdit,
      canDelete: DATA.PLATFORM.canDelete,
    },
    path: "/app/platforms",
    icon: <PlatformIcon fontSize="small" />,
  },
  {
    id: "adFormat",
    name: "Ad formats",
    module: "offers",
    options: {
      canCreate: DATA.ADFORMAT.canCreate,
      canUpdate: DATA.ADFORMAT.canEdit,
      canDelete: DATA.ADFORMAT.canDelete,
    },
    path: "/app/adFormats",
    icon: <AdFormatIcon fontSize="small" />,
  },
  {
    id: "specTemplate",
    name: "Spec templates",
    module: "offers",
    options: {
      canCreate: DATA.SPECTEMPLATE.canCreate,
      canUpdate: DATA.SPECTEMPLATE.canEdit,
      canDelete: DATA.SPECTEMPLATE.canDelete,
    },
    path: "/app/specTemplates",
    icon: <SpecTemplateIcon fontSize="small" />,
  },
  {
    id: "metric",
    name: "Metrics",
    module: "offers",
    options: {
      canCreate: DATA.METRIC.canCreate,
      canUpdate: DATA.METRIC.canEdit,
      canDelete: DATA.METRIC.canDelete,
    },
    path: "/app/metrics",
    icon: <MetricIcon fontSize="small" />,
  },
  {
    id: "offer",
    name: "Offers",
    module: "offers",
    options: {
      canCreate: DATA.OFFER.canCreate,
      canUpdate: DATA.OFFER.canEdit,
      canDelete: DATA.OFFER.canDelete,
    },
    path: "/app/offers",
    icon: <OfferIcon fontSize="small" />,
  },
  // ADMIN MODULE
  {
    id: "user",
    name: "Users",
    module: "admin",
    options: {
      canCreate: DATA.USER.canCreate,
      canUpdate: DATA.USER.canEdit,
      canDelete: DATA.USER.canDelete,
    },
    path: "/app/users",
    icon: <UserIcon fontSize="small" />,
  },
  {
    id: "dataOrigin",
    name: "Data origins",
    module: "admin",
    options: {
      canCreate: DATA.DATAORIGIN.canCreate,
      canUpdate: DATA.DATAORIGIN.canEdit,
      canDelete: DATA.DATAORIGIN.canDelete,
    },
    path: "/app/dataOrigins",
    icon: <DataOriginIcon fontSize="small" />,
  },
  {
    id: "adPlatform",
    name: "Ad platforms",
    module: "admin",
    options: {
      canCreate: DATA.ADPLATFORM.canCreate,
      // the code is different here
      // thats because ADPLATFORM is not editable (=> DATA.ADPLATFORM.canEdit is FALSE)
      // except the logo => so we need canUpdate to be true so that we can handle update permission in the UI
      canUpdate: true,
      canDelete: DATA.ADPLATFORM.canDelete,
    },
    path: "/app/adPlatforms",
    icon: <AdPlatformIcon fontSize="small" />,
  },
  {
    id: "adAccount",
    name: "Ad accounts",
    module: "admin",
    options: {
      canCreate: DATA.ADACCOUNT.canCreate,
      canUpdate: DATA.ADACCOUNT.canEdit,
      canDelete: DATA.ADACCOUNT.canDelete,
    },
    path: "/app/adAccounts",
    icon: <AdAccountIcon fontSize="small" />,
  },
  {
    id: "problemType",
    name: "Problem types",
    module: "admin",
    options: {
      canCreate: DATA.PROBLEMTYPE.canCreate,
      canUpdate: DATA.PROBLEMTYPE.canEdit,
      canDelete: DATA.PROBLEMTYPE.canDelete,
    },
    path: "/app/problemTypes",
    icon: <ProblemTypeIcon fontSize="small" />,
  },
  {
    id: "country",
    name: "Countries",
    module: "admin",
    options: {
      canCreate: DATA.COUNTRY.canCreate,
      canUpdate: DATA.COUNTRY.canEdit,
      canDelete: DATA.COUNTRY.canDelete,
    },
    path: "/app/countries",
    icon: <CountryIcon fontSize="small" />,
  },
  {
    id: "adOpsObjective",
    name: "Ad ops objectives",
    module: "admin",
    options: {
      canCreate: DATA.ADOPSOBJECTIVE.canCreate,
      canUpdate: DATA.ADOPSOBJECTIVE.canEdit,
      canDelete: DATA.ADOPSOBJECTIVE.canDelete,
    },
    path: "/app/adOpsObjectives",
    icon: <AdOpsObjectiveIcon fontSize="small" />,
  },
  {
    id: "dataImports",
    name: "Data imports",
    module: "admin",
    options: {
      canCreate: false,
      canUpdate: false,
      canDelete: false,
    },
    path: "/app/dataImports",
    icon: <DataImportIcon fontSize="small" />,
  },
  // ETL MODULE
  {
    id: "etlRun",
    name: "ETL Runs",
    module: "ETL",
    options: {
      canCreate: DATA.ETLRUN.canCreate,
      canUpdate: DATA.ETLRUN.canEdit,
      canDelete: DATA.ETLRUN.canDelete,
    },
    path: "/app/etlRuns",
    icon: <ETLRunIcon fontSize="small" />,
  },
  {
    id: "etlTask",
    name: "ETL Tasks",
    module: "ETL",
    options: {
      canCreate: DATA.ETLTASK.canCreate,
      canUpdate: DATA.ETLTASK.canEdit,
      canDelete: DATA.ETLTASK.canDelete,
    },
    path: "/app/etlTasks",
    icon: <ETLTaskIcon fontSize="small" />,
  },
];

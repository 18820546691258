import * as api from "../apis/authApi";
import { ValidationException } from "../customExceptions";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";

// Mutations

export const useInitAuth = () => {
  const auth = useSelector((state) => state.auth);
  const { mutate: mutateRefreshToken } = useRefreshToken();
  const { mutate: mutateLogout } = useLogout();

  return useMutation(api.initAuth, {
    useErrorBoundary: false,
    onSuccess: (result) => {
      startRefreshTokenTimer(result.authTokenExpiresIn, mutateRefreshToken);
      storeToLocalStorage(result.xsrfToken, result.user.themeSettings);
    },
    onError: () => {
      if (auth.isAuthenticated) {
        mutateLogout();
      }
    },
  });
};

export const useLogin = () => {
  const { mutate: mutateRefreshToken } = useRefreshToken();
  return useMutation(api.login, {
    useErrorBoundary: (error) => !(error instanceof ValidationException),
    onSuccess: (result) => {
      startRefreshTokenTimer(result.authTokenExpiresIn, mutateRefreshToken);
      storeToLocalStorage(result.xsrfToken, result.user.themeSettings);
    },
  });
};

export const useLogout = () => {
  return useMutation(api.logout, {
    useErrorBoundary: false,
    onSettled: () => {
      stopRefreshTokenTimer();
    },
  });
};

const useRefreshToken = () => {
  const auth = useSelector((state) => state.auth);
  const { mutate: mutateLogout } = useLogout();
  const { mutate: mutateRefreshToken } = useMutation(api.refreshToken, {
    useErrorBoundary: true,
    onSuccess: (result) => {
      startRefreshTokenTimer(result.authTokenExpiresIn, mutateRefreshToken);
      storeToLocalStorage(result.xsrfToken, result.user.themeSettings);
    },
    onError: () => {
      if (auth.isAuthenticated) {
        mutateLogout();
      }
    },
  });

  return { mutate: mutateRefreshToken };
};

export const useSignup = () => {
  return useMutation(api.signup, {
    useErrorBoundary: (error) => !(error instanceof ValidationException),
  });
};

export const useVerifyEmail = () => {
  const { mutate: mutateRefreshToken } = useRefreshToken();

  return useMutation(api.verifyEmail, {
    useErrorBoundary: (error) => !(error instanceof ValidationException),
    onSuccess: (result) => {
      startRefreshTokenTimer(result.authTokenExpiresIn, mutateRefreshToken);
      storeToLocalStorage(result.xsrfToken, result.user.themeSettings);
    },
  });
};

export const useForgotPassword = () => {
  return useMutation(api.forgotPassword, {
    useErrorBoundary: (error) => !(error instanceof ValidationException),
  });
};

export const useResetPassword = () => {
  return useMutation(api.resetPassword, {
    useErrorBoundary: (error) => !(error instanceof ValidationException),
  });
};

// Helpers : Refresh token timer

let refreshTokenTimeout;

const startRefreshTokenTimer = (expiresIn, callback) => {
  const timeout = expiresIn - 60 * 1000;
  refreshTokenTimeout = setTimeout(() => {
    callback();
  }, timeout);
};

const stopRefreshTokenTimer = () => {
  clearTimeout(refreshTokenTimeout);
};

// Helper : store xsrfToken and theme settings to local storage

const storeToLocalStorage = (xsrfToken, themeSettings) => {
  localStorage.setItem("xsrfToken", xsrfToken);
  if (themeSettings) {
    localStorage.setItem("themeSettings", JSON.stringify(themeSettings));
  }
};

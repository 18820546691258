import Loadable from "../components/general/Loadable";
import { lazy } from "react";

// Pages
const NotFound = Loadable(lazy(() => import("../pages/NotFound")));

const defaultRoute = () => ({
  path: "*",
  element: <NotFound origin="app" />,
  breadcrumb: null,
});

export default defaultRoute;

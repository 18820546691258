import ServerError from "./ServerError";
import UnknownError from "./UnknownError";
import UnauthorizedError from "./UnauthorizedError";
import NotFoundError from "./NotFoundError";
import {
  NotFoundException,
  ServerException,
  UnauthorizedException,
} from "../../customExceptions";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  if (error instanceof UnauthorizedException) {
    return <UnauthorizedError resetErrorBoundary={resetErrorBoundary} />;
  }
  if (error instanceof NotFoundException) {
    return <NotFoundError resetErrorBoundary={resetErrorBoundary} />;
  }
  if (error instanceof ServerException) {
    return <ServerError resetErrorBoundary={resetErrorBoundary} />;
  }
  return <UnknownError resetErrorBoundary={resetErrorBoundary} />;
};

export default ErrorFallback;

import * as api from "../apis/adAccountsApi";
import { NotFoundException } from "../customExceptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetAdAccounts = () => {
  return useQuery(["adAccounts"], api.getAdAccounts, {
    useErrorBoundary: true,
  });
};

export const useGetAdAccountsByAccount = (accountId) => {
  const queryClient = useQueryClient();

  return useQuery(
    ["account.adAccounts", accountId],
    () => api.getAdAccountsByAccount(accountId),
    {
      enabled: Boolean(accountId),
      useErrorBoundary: true,
      initialData: () =>
        queryClient
          .getQueryData(["adAccounts"])
          ?.filter((adAccount) => adAccount.accounts.find(acc => acc.id === accountId)),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(["adAccounts"])?.dataUpdatedAt,
    }
  );
};

export const useGetAdAccountsByAdPlatform = (adPlatformId) => {
  const queryClient = useQueryClient();

  return useQuery(
    ["adPlatform.adAccounts", adPlatformId],
    () => api.getAdAccountsByAdPlatform(adPlatformId),
    {
      enabled: Boolean(adPlatformId),
      useErrorBoundary: true,
      initialData: () =>
        queryClient
        .getQueryData(["adAccounts"])
          ?.filter((adAccount) => adAccount.adPlatform.id === adPlatformId),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(["adAccounts"])?.dataUpdatedAt,
    }
  );
};

export const useGetAdAccount = (id) => {
  const queryClient = useQueryClient();
  let queryKey;

  return useQuery(["adAccount", id], () => api.getAdAccount(id), {
    enabled: Boolean(id),
    useErrorBoundary: (error) => !(error instanceof NotFoundException),
    initialData: () => {
      // if "adAccounts" query exist, we use it to init data
      if (queryClient.getQueryState(["adAccounts"])) {
        queryKey = ["adAccounts"];
        return queryClient
          .getQueryData(["adAccounts"])
          ?.find((adAccount) => adAccount.id === id);
      }

      // otherwise, we try to find the adAccount in one of the "account.adAccounts" queries
      let found;

      queryClient.getQueriesData(["account.adAccounts"]).forEach((query) => {
        const data = query[1];
        data.forEach((adAccount) => {
          if (adAccount.id === id) {
            found = adAccount;
            queryKey = query[0];
          }
        });
      });

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

import { AdFormatBreadcrumb } from "../breadcrumbs/adFormat";
import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import { DATA } from "../constants";
import offerRoutes from "./offer";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";
import PermissionsGuard from "../components/routes/PermissionsGuard";

const AdFormatList = Loadable(
  lazy(() => import("../pages/adFormats/AdFormatList"))
);
const AdFormatCreate = Loadable(
  lazy(() => import("../pages/adFormats/AdFormatCreate"))
);
const AdFormatView = Loadable(
  lazy(() => import("../pages/adFormats/AdFormatView"))
);
const AdFormatEdit = Loadable(
  lazy(() => import("../pages/adFormats/AdFormatEdit"))
);

const adFormatRoutes = (directParentType = "", grandParentTypes = []) => {
  // there is no parent for adFormat
  const listComponent = (
    <PermissionsGuard
      neededPermissions={[{ feature: DATA.ADFORMAT.type, action: "READ" }]}
    >
      <AdFormatList />
    </PermissionsGuard>
  );
  const createComponent = (
    <PermissionsGuard
      neededPermissions={[{ feature: DATA.ADFORMAT.type, action: "CREATE" }]}
    >
      <AdFormatCreate />;
    </PermissionsGuard>
  );

  const route = {
    path: "adFormats",
    children: [
      {
        path: "",
        element: listComponent,
        bbreadcrumb: capitalizeFirstLetter(DATA.ADFORMAT.readablePlural),
      },
      {
        path: "create",
        element: createComponent,
        breadcrumb: "Create new",
      },
      {
        path: ":adFormatId",
        children: [
          {
            path: "",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ADFORMAT.type, action: "READ" },
                ]}
              >
                <AdFormatView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: AdFormatBreadcrumb,
          },
          {
            path: "edit",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ADFORMAT.type, action: "UPDATE" },
                ]}
              >
                <AdFormatEdit
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Edit",
          },
          {
            path: "about",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ADFORMAT.type, action: "READ" },
                ]}
              >
                <AdFormatView
                  tab="about"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "About",
          },
          {
            ...offerRoutes(
              DATA.ADFORMAT.type,
              directParentType ? [...grandParentTypes, directParentType] : []
            ),
          },
        ],
      },
    ],
  };

  return route;
};

export default adFormatRoutes;

import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import { useGetCampaign } from "../queries/campaigns";

export const CampaignBreadcrumb = ({ match }) => {
    const {
      data: campaign,
      isLoading,
      isError,
      error,
    } = useGetCampaign(match.params.campaignId);
  
    if (isLoading) {
      //return <span>Loading...</span>;
      return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
    }
  
    if (isError && error instanceof NotFoundException) {
      return <span>Not found</span>;
    }
  
    if (campaign) {
      return <span>{campaign.name}</span>;
    }
  
    return null;
  };
import { BrandBreadcrumb } from "../breadcrumbs/brand";
import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import { DATA } from "../constants";
import campaignRoutes from "./campaign";
import postRoutes from "./post";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";
import PermissionsGuard from "../components/routes/PermissionsGuard";

// Pages
const BrandList = Loadable(lazy(() => import("../pages/brands/BrandList")));
const BrandCreate = Loadable(lazy(() => import("../pages/brands/BrandCreate")));
const BrandView = Loadable(lazy(() => import("../pages/brands/BrandView")));
const BrandEdit = Loadable(lazy(() => import("../pages/brands/BrandEdit")));

// Direct parent pages
const AdvertiserView = Loadable(
  lazy(() => import("../pages/advertisers/AdvertiserView"))
);
const AdvertiserBrandCreate = Loadable(
  lazy(() => import("../pages/advertisers/AdvertiserBrandCreate"))
);

const brandRoutes = (directParentType = "", grandParentTypes = []) => {
  const listComponent =
    directParentType === DATA.ADVERTISER.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.ADVERTISER.type, action: "READ" },
          { feature: DATA.BRAND.type, action: "READ" },
        ]}
      >
        <AdvertiserView tab="brands" />
      </PermissionsGuard>
    ) : (
      <PermissionsGuard
        neededPermissions={[{ feature: DATA.BRAND.type, action: "READ" }]}
      >
        <BrandList />
      </PermissionsGuard>
    );
  const createComponent =
    directParentType === DATA.ADVERTISER.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.ADVERTISER.type, action: "READ" },
          { feature: DATA.BRAND.type, action: "CREATE" },
        ]}
      >
        <AdvertiserBrandCreate
          parentTypes={
            directParentType ? [...grandParentTypes, directParentType] : []
          }
        />
      </PermissionsGuard>
    ) : (
      <PermissionsGuard
        neededPermissions={[{ feature: DATA.BRAND.type, action: "CREATE" }]}
      >
        <BrandCreate />
      </PermissionsGuard>
    );

  const route = {
    path: "brands",
    children: [
      {
        path: "",
        element: listComponent,
        breadcrumb: capitalizeFirstLetter(DATA.BRAND.readablePlural),
      },
      {
        path: "create",
        element: createComponent,
        breadcrumb: "Create new",
      },
      {
        path: ":brandId",
        children: [
          {
            path: "",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.BRAND.type, action: "READ" },
                ]}
              >
                <BrandView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: BrandBreadcrumb,
          },
          {
            path: "edit",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.BRAND.type, action: "UPDATE" },
                ]}
              >
                <BrandEdit
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Edit",
          },
          {
            path: "about",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.BRAND.type, action: "READ" },
                ]}
              >
                <BrandView
                  tab="about"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "About",
          },
          {
            ...campaignRoutes(
              DATA.BRAND.type,
              directParentType ? [...grandParentTypes, directParentType] : []
            ),
          },
          {
            ...postRoutes(
              DATA.BRAND.type,
              directParentType ? [...grandParentTypes, directParentType] : []
            ),
          },
        ],
      },
    ],
  };

  return route;
};

export default brandRoutes;

import * as api from "../apis/adOpsCampaignsApi";
import { NotFoundException } from "../customExceptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetAdOpsCampaigns = ({
  filterText,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  return useQuery(
    ["adOpsCampaigns", filterText, filterDeleted, sortBy, page, nbDocsPerPage],
    () =>
      api.getAdOpsCampaigns({
        filterText,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    {
      useErrorBoundary: true,
      keepPreviousData: true,
    }
  );
};

export const useGetAdOpsCampaignsByLineItem = ({
  lineItemId,
  filterText,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  return useQuery(
    [
      "lineItem.adOpsCampaigns",
      lineItemId,
      filterText,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    () =>
      api.getAdOpsCampaignsByLineItem({
        lineItemId,
        filterText,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    {
      enabled: Boolean(lineItemId),
      useErrorBoundary: true,
      keepPreviousData: true,
    }
  );
};

export const useGetAdOpsCampaignsByCampaign = (campaignId) =>
  useQuery(
    ["campaign.adOpsCampaigns", campaignId],
    () => api.getAdOpsCampaignsByCampaign(campaignId),
    {
      enabled: Boolean(campaignId),
      useErrorBoundary: true,
    }
  );

export const useGetAdOpsCampaign = (id) => {
  const queryClient = useQueryClient();
  let queryKey;

  return useQuery(["adOpsCampaign", id], () => api.getAdOpsCampaign(id), {
    enabled: Boolean(id),
    useErrorBoundary: (error) => !(error instanceof NotFoundException),
    initialData: () => {
      // to init data, we try to find the adOpsCampaign in one of the "adOpsCampaigns" queries
      let found;

      queryClient.getQueriesData(["adOpsCampaigns"]).forEach((query) => {
        const data = query[1]?.data;
        if (data) {
          data.forEach((adOpsCampaign) => {
            if (adOpsCampaign.id === id) {
              found = adOpsCampaign;
              queryKey = query[0];
            }
          });
        }
      });

      // otherwise, we try to find the adOpsCampaign in one of the "lineItem.adOpsCampaigns" queries

      if (!found) {
        queryClient
          .getQueriesData(["lineItem.adOpsCampaigns"])
          .forEach((query) => {
            const data = query[1]?.data;
            if (data) {
              data.forEach((adOpsCampaign) => {
                if (adOpsCampaign.id === id) {
                  found = adOpsCampaign;
                  queryKey = query[0];
                }
              });
            }
          });
      }

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

export const useGetAdOpsCampaignStatsByDay = (adOpsCampaignId) => {
  return useQuery(
    ["adOpsCampaign.statsByDay", adOpsCampaignId],
    () => api.getAdOpsCampaignStatsByDay(adOpsCampaignId),
    {
      enabled: Boolean(adOpsCampaignId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

export const useGetAdOpsCampaignStatsByAdSet = (adOpsCampaignId) => {
  return useQuery(
    ["adOpsCampaign.statsByAdSet", adOpsCampaignId],
    () => api.getAdOpsCampaignStatsByAdSet(adOpsCampaignId),
    {
      enabled: Boolean(adOpsCampaignId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

export const useGetAdOpsCampaignStatsByAd = (adOpsCampaignId) => {
  return useQuery(
    ["adOpsCampaign.statsByAd", adOpsCampaignId],
    () => api.getAdOpsCampaignStatsByAd(adOpsCampaignId),
    {
      enabled: Boolean(adOpsCampaignId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

export const useGetAdOpsCampaignStatsByDevice = (adOpsCampaignId) => {
  return useQuery(
    ["adOpsCampaign.statsByDevice", adOpsCampaignId],
    () => api.getAdOpsCampaignStatsByDevice(adOpsCampaignId),
    {
      enabled: Boolean(adOpsCampaignId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

export const useGetAdOpsCampaignStatsByDevicePlatform = (adOpsCampaignId) => {
  return useQuery(
    ["adOpsCampaign.statsByDevicePlatform", adOpsCampaignId],
    () => api.getAdOpsCampaignStatsByDevicePlatform(adOpsCampaignId),
    {
      enabled: Boolean(adOpsCampaignId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

export const useGetAdOpsCampaignStatsByPlatform = (adOpsCampaignId) => {
  return useQuery(
    ["adOpsCampaign.statsByPlatform", adOpsCampaignId],
    () => api.getAdOpsCampaignStatsByPlatform(adOpsCampaignId),
    {
      enabled: Boolean(adOpsCampaignId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

export const useGetAdOpsCampaignStatsByPlacement = (adOpsCampaignId) => {
  return useQuery(
    ["adOpsCampaign.statsByPlacement", adOpsCampaignId],
    () => api.getAdOpsCampaignStatsByPlacement(adOpsCampaignId),
    {
      enabled: Boolean(adOpsCampaignId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

export const useGetAdOpsCampaignStatsByAge = (adOpsCampaignId) => {
  return useQuery(
    ["adOpsCampaign.statsByAge", adOpsCampaignId],
    () => api.getAdOpsCampaignStatsByAge(adOpsCampaignId),
    {
      enabled: Boolean(adOpsCampaignId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

export const useGetAdOpsCampaignStatsByGender = (adOpsCampaignId) => {
  return useQuery(
    ["adOpsCampaign.statsByGender", adOpsCampaignId],
    () => api.getAdOpsCampaignStatsByGender(adOpsCampaignId),
    {
      enabled: Boolean(adOpsCampaignId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

export const useGetAdOpsCampaignDataCheck = (adOpsCampaignId) => {
  return useQuery(
    ["adOpsCampaign.dataCheck", adOpsCampaignId],
    () => api.getAdOpsCampaignDataCheck(adOpsCampaignId),
    {
      enabled: Boolean(adOpsCampaignId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import { useGetUser } from "../queries/users";

export const UserBreadcrumb = ({ match }) => {
    const {
      data: user,
      isLoading,
      isError,
      error,
    } = useGetUser(match.params.userId);
  
    if (isLoading) {
      //return <span>Loading...</span>;
      return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
    }
  
    if (isError && error instanceof NotFoundException) {
      return <span>Not found</span>;
    }
  
    if (user) {
      return <span>{user.fullName}</span>;
    }
  
    return null;
  };

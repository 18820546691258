import { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import InitializingScreen from "../general/InitializingScreen";
import { setIsInitialized, setLoggedIn } from "../../slices/auth";
import { useInitAuth } from "../../mutations/auth";

const AuthProvider = ({ children }) => {
  // Mutations

  const { mutate: mutateInitAuth } = useInitAuth();

  // Hooks

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    //console.log("init Auth");
    mutateInitAuth(null, {
      onSettled: () => {
        dispatch(setIsInitialized());
      },
      onSuccess: (result) => {
        dispatch(setLoggedIn(result.user));
      },
    });
  }, [mutateInitAuth, dispatch]);

  // Rendering

  return (
    <Fragment>
      {auth.isInitialized ? children : <InitializingScreen />}
    </Fragment>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthProvider;

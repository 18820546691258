import { useEffect, Fragment } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Box, Button, Container, Link, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import gtm from "../../helpers/gtm";

const NotFoundError = ({ resetErrorBoundary }) => {

  // Hooks

  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  // Google Tag Manager

useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  // Handle Go Home

  const handleGoHome = () => {
    resetErrorBoundary();
    navigate("/app");
  };

  // Rendering

  return (
    <Fragment>
      <Helmet>
        <title>Lean ads | Not found</title>
      </Helmet>
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "background.default",
          display: "flex",
          minHeight: "100%",
          px: 3,
          py: "80px",
        }}
      >
        <Container>
          <Typography
            align="center"
            color="textPrimary"
            variant={mobileDevice ? "h4" : "h1"}
          >
            404: Not found
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 0.5 }}
            variant="subtitle2"
          >
            We looked everywhere... but what you're looking for is from... elsewhere 👽
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 0.5 }}
            variant="subtitle2"
          >
            You can act like all of this never happened and just{" "}
            <Link
              color="textPrimary"
              underline="hover"
              component={RouterLink}
              onClick={handleGoHome}
              to="/app/"
            >
              go back home
            </Link>
            .
          </Typography>
          
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Box
              alt="Not found"
              component="img"
              src={`/static/error/error404_${theme.palette.mode}.svg`}
              sx={{
                height: "auto",
                maxWidth: "100%",
                width: 300,
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Button
              color="primary"
              component={RouterLink}
              onClick={handleGoHome}
              to="/app/"
              variant="outlined"
            >
              Back Home
            </Button>
          </Box>
        </Container>
      </Box>
    </Fragment>
  );
};

export default NotFoundError;

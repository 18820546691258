import Loadable from "../components/general/Loadable";
import { lazy } from "react";

// Pages
const Profile = Loadable(lazy(() => import("../pages/me/Profile")));
const Settings = Loadable(lazy(() => import("../pages/me/Settings")));

const meRoutes = () => ({
  path: "me",
  breadcrumb: null,
  children: [
    {
      path: "profile",
      element: <Profile />,
      breadcrumb: "My profile",
    },
    {
      path: "settings",
      element: <Settings />,
      breadcrumb: "My settings",
    },
  ],
});

export default meRoutes;

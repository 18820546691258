import * as api from "../apis/adFormatsApi";
import { NotFoundException } from "../customExceptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetAdFormats = () =>
  useQuery(["adFormats"], api.getAdFormats, {
    useErrorBoundary: true,
  });

export const useGetAdFormat = (id) => {
  const queryClient = useQueryClient();
  return useQuery(
    ["adFormat", id],
    () => api.getAdFormat(id),
    {
      enabled: Boolean(id),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
      initialData: () =>
        queryClient
          .getQueryData(["adFormats"])
          ?.find((adFormat) => adFormat.id === id),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(["adFormats"])?.dataUpdatedAt,
    }
  );
};

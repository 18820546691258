import { createSlice } from "@reduxjs/toolkit";

const initialFilters = {
  tab: "all",
  text: "",
  sortBy: "startDate-desc",
};

// INITIAL STATE
const initialState = {
  etlTasks: initialFilters,
  etlRuns: [],
};

const etlTaskFiltersSlice = createSlice({
  name: "etlTaskFilters",
  initialState,
  reducers: {
    setTabFilter: (state, action) => {
      switch (action.payload.module) {
        case "etlTasks":
          state.etlTasks.tab = action.payload.tab;
          break;
        case "etlRuns":
          state.etlRuns = state.etlRuns.map((etlRun) => {
            if (etlRun.id === action.payload.id) {
              const updatedFilters = {
                ...etlRun.filters,
                tab: action.payload.tab,
              };
              return { ...etlRun, filters: updatedFilters };
            } else {
              return etlRun;
            }
          });
          break;
        
        default:
          break;
      }
    },
    setTextFilter: (state, action) => {
      switch (action.payload.module) {
        case "etlTasks":
          state.etlTasks.text = action.payload.text;
          break;
        case "etlRuns":
          state.etlRuns = state.etlRuns.map((etlRun) => {
            if (etlRun.id === action.payload.id) {
              const updatedFilters = {
                ...etlRun.filters,
                text: action.payload.text,
              };
              return { ...etlRun, filters: updatedFilters };
            } else {
              return etlRun;
            }
          });
          break;
        
        default:
          break;
      }
    },
    sortByTypeAsc: (state, action) => {
      switch (action.payload.module) {
        case "etlTasks":
          state.etlTasks.sortBy = "type-asc";
          break;
        case "etlRuns":
          state.etlRuns = state.etlRuns.map((etlRun) => {
            if (etlRun.id === action.payload.id) {
              const updatedFilters = {
                ...etlRun.filters,
                sortBy: "type-asc",
              };
              return { ...etlRun, filters: updatedFilters };
            } else {
              return etlRun;
            }
          });
          break;
        
        default:
          break;
      }
    },
    sortByTypeDesc: (state, action) => {
      switch (action.payload.module) {
        case "etlTasks":
          state.etlTasks.sortBy = "type-desc";
          break;
        case "etlRuns":
          state.etlRuns = state.etlRuns.map((etlRun) => {
            if (etlRun.id === action.payload.id) {
              const updatedFilters = {
                ...etlRun.filters,
                sortBy: "type-desc",
              };
              return { ...etlRun, filters: updatedFilters };
            } else {
              return etlRun;
            }
          });
          break;
        default:
          break;
      }
    },
    sortByStartDateAsc: (state, action) => {
      switch (action.payload.module) {
        case "etlTasks":
          state.etlTasks.sortBy = "startDate-asc";
          break;
        case "etlRuns":
          state.etlRuns = state.etlRuns.map((etlRun) => {
            if (etlRun.id === action.payload.id) {
              const updatedFilters = {
                ...etlRun.filters,
                sortBy: "startDate-asc",
              };
              return { ...etlRun, filters: updatedFilters };
            } else {
              return etlRun;
            }
          });
          break;
        
        default:
          break;
      }
    },
    sortByStartDateDesc: (state, action) => {
      switch (action.payload.module) {
        case "etlTasks":
          state.etlTasks.sortBy = "startDate-desc";
          break;
        case "etlRuns":
          state.etlRuns = state.etlRuns.map((etlRun) => {
            if (etlRun.id === action.payload.id) {
              const updatedFilters = {
                ...etlRun.filters,
                sortBy: "startDate-desc",
              };
              return { ...etlRun, filters: updatedFilters };
            } else {
              return etlRun;
            }
          });
          break;
        
        default:
          break;
      }
    },
    initFilterSet: (state, action) => {
      switch (action.payload.module) {
        case "etlTasks":
          state.etlTasks = initialFilters;
          break;
        case "etlRuns":
          if (
            !state.etlRuns.find((etlRun) => etlRun.id === action.payload.id)
          ) {
            state.etlRuns.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;
        default:
          break;
      }
    },
  },
});

export const {
  setTabFilter,
  setTextFilter,
  sortByTypeAsc,
  sortByTypeDesc,
  sortByStartDateAsc,
  sortByStartDateDesc,
  initFilterSet,
} = etlTaskFiltersSlice.actions;
export const etlTaskFiltersReducer = etlTaskFiltersSlice.reducer;

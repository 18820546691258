import * as api from "../apis/dataOriginsApi";
import { NotFoundException } from "../customExceptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetDataOrigins = () => {
  return useQuery(["dataOrigins"], api.getDataOrigins, {
    useErrorBoundary: true,
  });
};

export const useGetDataOriginsByAccount = (accountId) => {
  const queryClient = useQueryClient();

  return useQuery(
    ["account.dataOrigins", accountId],
    () => api.getDataOriginsByAccount(accountId),
    {
      enabled: Boolean(accountId),
      useErrorBoundary: true,
      initialData: () =>
        queryClient
          .getQueryData(["dataOrigins"])
          ?.filter((dataOrigin) => dataOrigin.account?.id === accountId),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(["dataOrigins"])?.dataUpdatedAt,
    }
  );
};

export const useGetDataOrigin = (id) => {
  const queryClient = useQueryClient();
  let queryKey;

  return useQuery(["dataOrigin", id], () => api.getDataOrigin(id), {
    enabled: Boolean(id),
    useErrorBoundary: (error) => !(error instanceof NotFoundException),
    initialData: () => {
      // if "dataOrigins" query exist, we use it to init data
      if (queryClient.getQueryState(["dataOrigins"])) {
        queryKey = ["dataOrigins"];
        return queryClient
          .getQueryData(["dataOrigins"])
          ?.find((dataOrigin) => dataOrigin.id === id);
      }

      // otherwise, we try to find the dataOrigin in one of the "account.dataOrigins" queries
      let found;

      queryClient.getQueriesData(["account.dataOrigins"]).forEach((query) => {
        const data = query[1];
        data.forEach((dataOrigin) => {
          if (dataOrigin.id === id) {
            found = dataOrigin;
            queryKey = query[0];
          }
        });
      });

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Grid,
  Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Logo from "../../general/Logo";
import NavSection from "../../general/NavSection";
import Scrollbar from "../../general/Scrollbar";
import "./Sidebar.css";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { FEATURES, MODULES_LIST } from "../../../constants";


const Sidebar = ({
  onMobileClose,
  openMobile,
  isCollapsed,
  setIsCollapsed,
}) => {
  // Hooks

  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const { currentUser } = useSelector((state) => state.auth);
  const userPermissions = currentUser.permissions;

  // Permissions is an array that looks like below :

  /*

  const permissions = [
    {
      feature: "account",
      canRead: true,
      canCreate: true,
      canUpdate: true,
      canDelete: true,
    },
    {
      feature: "post",
      canRead: false,
      canCreate: false,
      canUpdate: false,
      canDelete: false,
    },
  ];

  */

  // get the features/modules that the user can access
  const userFeatureIds = userPermissions.map(
    (permission) => permission.feature
  );
  const userFeatures = FEATURES.filter((feature) =>
    userFeatureIds.includes(feature.id)
  );

  const userModuleIdsWithDuplicates = userFeatures.map(
    (userFeature) => userFeature.module
  );
  const userModuleIds = [...new Set(userModuleIdsWithDuplicates)];
  const userModules = MODULES_LIST.filter((module) =>
    userModuleIds.includes(module.id)
  );

  // We build nav sections

  const navSections = [];

  for (const userModule of userModules) {

    const items = userFeatures
      .filter((userFeature) => userFeature.module === userModule.id)
      .map((userFeature) => ({
        title: userFeature.name,
        path: userFeature.path,
        icon: userFeature.icon,
      }));

    navSections.push({
      module: userModule.id,
      title: userModule.name,
      shortTitle: userModule.shortName,
      items,
    });
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [openMobile, onMobileClose, location.pathname]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Rendering

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box sx={{ p: 2 }}>
        {isCollapsed ? (
          <Box
            sx={{
              backgroundColor: "background.default",
              borderRadius: 1,
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
              minHeight: 80,
            }}
          >
            <ChevronRightIcon
              onClick={toggleCollapse}
              sx={{
                cursor: "pointer",
                textAlign: "center",
                margin: "auto",
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: "background.default",
              borderRadius: 1,
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              ml: "auto",
              cursor: "pointer",
              minHeight: 80,
            }}
          >
            <Grid container>
              <Grid item>
                <RouterLink to="/app/me/profile" className="text-link">
                  <Avatar
                    src={currentUser.avatar}
                    sx={{
                      height: 48,
                      width: 48,
                    }}
                  ></Avatar>
                </RouterLink>
              </Grid>
              <Box display={"flex"} alignItems="center" justifyContent="center">
                <Grid item sx={{ ml: 2 }}>
                  <Typography color="textPrimary" variant="subtitle2">
                    {currentUser.firstName}
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    #NYAC
                  </Typography>
                </Grid>
              </Box>
            </Grid>
            <Box display={"flex"} alignItems="center" justifyContent="center">
              <ChevronLeftIcon
                onClick={toggleCollapse}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: "none",
              xs: "flex",
            },
            justifyContent: "center",
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {navSections.map((navSection, index) => (
            <NavSection
              key={index}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...navSection}
              isCollapsed={isCollapsed}
            />
          ))}
        </Box>

      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !Important",
            width: isCollapsed ? 80 : 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: isCollapsed ? 80 : 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

Sidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default Sidebar;

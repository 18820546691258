import { OfferBreadcrumb } from "../breadcrumbs/offer";
import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import { DATA } from "../constants";
import lineItemRoutes from "./lineItem";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";
import PermissionsGuard from "../components/routes/PermissionsGuard";

// Pages
const OfferList = Loadable(lazy(() => import("../pages/offers/OfferList")));
const OfferView = Loadable(lazy(() => import("../pages/offers/OfferView")));
const OfferEdit = Loadable(lazy(() => import("../pages/offers/OfferEdit")));
const OfferCreate = Loadable(lazy(() => import("../pages/offers/OfferCreate")));

// Direct parent pages
const PlatformView = Loadable(
  lazy(() => import("../pages/platforms/PlatformView"))
);
const PlatformOfferCreate = Loadable(
  lazy(() => import("../pages/platforms/PlatformOfferCreate"))
);
const CountryView = Loadable(
  lazy(() => import("../pages/countries/CountryView"))
);
const CountryOfferCreate = Loadable(
  lazy(() => import("../pages/countries/CountryOfferCreate"))
);
const AdFormatView = Loadable(
  lazy(() => import("../pages/adFormats/AdFormatView"))
);
const AdFormatOfferCreate = Loadable(
  lazy(() => import("../pages/adFormats/AdFormatOfferCreate"))
);
const SpecTemplateView = Loadable(
  lazy(() => import("../pages/specTemplates/SpecTemplateView"))
);
const SpecTemplateOfferCreate = Loadable(
  lazy(() => import("../pages/specTemplates/SpecTemplateOfferCreate"))
);
const AdOpsObjectiveView = Loadable(
  lazy(() => import("../pages/adOpsObjectives/AdOpsObjectiveView"))
);
const AdOpsObjectiveOfferCreate = Loadable(
  lazy(() => import("../pages/adOpsObjectives/AdOpsObjectiveOfferCreate"))
);

const offerRoutes = (directParentType = "", grandParentTypes = []) => {
  const listComponent =
    directParentType === DATA.PLATFORM.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.PLATFORM.type, action: "READ" },
          { feature: DATA.OFFER.type, action: "READ" },
        ]}
      >
        <PlatformView tab="offers" />
      </PermissionsGuard>
    ) : directParentType === DATA.COUNTRY.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.COUNTRY.type, action: "READ" },
          { feature: DATA.OFFER.type, action: "READ" },
        ]}
      >
        <CountryView tab="offers" />
      </PermissionsGuard>
    ) : directParentType === DATA.ADFORMAT.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.ADFORMAT.type, action: "READ" },
          { feature: DATA.OFFER.type, action: "READ" },
        ]}
      >
        <AdFormatView tab="offers" />
      </PermissionsGuard>
    ) : directParentType === DATA.SPECTEMPLATE.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.SPECTEMPLATE.type, action: "READ" },
          { feature: DATA.OFFER.type, action: "READ" },
        ]}
      >
        <SpecTemplateView tab="offers" />
      </PermissionsGuard>
    ) : directParentType === DATA.ADOPSOBJECTIVE.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.ADOPSOBJECTIVE.type, action: "READ" },
          { feature: DATA.OFFER.type, action: "READ" },
        ]}
      >
        <AdOpsObjectiveView tab="offers" />
      </PermissionsGuard>
    ) : (
      <PermissionsGuard
        neededPermissions={[{ feature: DATA.OFFER.type, action: "READ" }]}
      >
        <OfferList />
      </PermissionsGuard>
    );

  const createComponent =
    directParentType === DATA.PLATFORM.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.PLATFORM.type, action: "READ" },
          { feature: DATA.OFFER.type, action: "CREATE" },
        ]}
      >
        <PlatformOfferCreate
          parentTypes={
            directParentType ? [...grandParentTypes, directParentType] : []
          }
        />
      </PermissionsGuard>
    ) : directParentType === DATA.COUNTRY.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.COUNTRY.type, action: "READ" },
          { feature: DATA.OFFER.type, action: "CREATE" },
        ]}
      >
        <CountryOfferCreate
          parentTypes={
            directParentType ? [...grandParentTypes, directParentType] : []
          }
        />
      </PermissionsGuard>
    ) : directParentType === DATA.ADFORMAT.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.ADFORMAT.type, action: "READ" },
          { feature: DATA.OFFER.type, action: "CREATE" },
        ]}
      >
        <AdFormatOfferCreate
          parentTypes={
            directParentType ? [...grandParentTypes, directParentType] : []
          }
        />
      </PermissionsGuard>
    ) : directParentType === DATA.SPECTEMPLATE.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.SPECTEMPLATE.type, action: "READ" },
          { feature: DATA.OFFER.type, action: "CREATE" },
        ]}
      >
        <SpecTemplateOfferCreate
          parentTypes={
            directParentType ? [...grandParentTypes, directParentType] : []
          }
        />
      </PermissionsGuard>
    ) : directParentType === DATA.ADOPSOBJECTIVE.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.ADOPSOBJECTIVE.type, action: "READ" },
          { feature: DATA.OFFER.type, action: "CREATE" },
        ]}
      >
        <AdOpsObjectiveOfferCreate
          parentTypes={
            directParentType ? [...grandParentTypes, directParentType] : []
          }
        />
      </PermissionsGuard>
    ) : (
      <PermissionsGuard
        neededPermissions={[{ feature: DATA.OFFER.type, action: "CREATE" }]}
      >
        <OfferCreate />
      </PermissionsGuard>
    );

  const route = {
    path: "offers",
    children: [
      {
        path: "",
        element: listComponent,
        breadcrumb: capitalizeFirstLetter(DATA.OFFER.readablePlural),
      },
      {
        path: "create",
        element: createComponent,
        breadcrumb: "Create new",
      },
      {
        path: ":offerId",
        children: [
          {
            path: "",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.OFFER.type, action: "READ" },
                ]}
              >
                <OfferView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: OfferBreadcrumb,
          },
          {
            path: "edit",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.OFFER.type, action: "UPDATE" },
                ]}
              >
                <OfferEdit
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Edit",
          },
          {
            path: "about",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.OFFER.type, action: "READ" },
                ]}
              >
                <OfferView
                  tab="about"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "About",
          },
          {
            path: "goals",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.OFFER.type, action: "READ" },
                ]}
              >
                <OfferView
                  tab="goals"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Goals",
          },
          {
            ...lineItemRoutes(
              DATA.OFFER.type,
              directParentType ? [...grandParentTypes, directParentType] : []
            ),
          },
        ],
      },
    ],
  };

  return route;
};

export default offerRoutes;

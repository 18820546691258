import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import { useGetOffer } from "../queries/offers";

export const OfferBreadcrumb = ({ match }) => {
    const {
      data: offer,
      isLoading,
      isError,
      error,
    } = useGetOffer(match.params.offerId);
  
    if (isLoading) {
      //return <span>Loading...</span>;
      return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
    }
  
    if (isError && error instanceof NotFoundException) {
      return <span>Not found</span>;
    }
  
    if (offer) {
      return <span>{offer.name}</span>;
    }
  
    return null;
  };
import * as api from "../apis/platformsApi";
import { NotFoundException } from "../customExceptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetPlatforms = () =>
  useQuery(["platforms"], api.getPlatforms, {
    useErrorBoundary: true,
  });

export const useGetPlatform = (id) => {
  const queryClient = useQueryClient();
  return useQuery(
    ["platform", id],
    () => api.getPlatform(id),
    {
      enabled: Boolean(id),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
      initialData: () =>
        queryClient
          .getQueryData(["platforms"])
          ?.find((platform) => platform.id === id),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(["platforms"])?.dataUpdatedAt,
    }
  );
};

import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import PermissionsGuard from "../components/routes/PermissionsGuard";

// Pages
const CampaignDoctor = Loadable(
  lazy(() => import("../pages/campaignDoctor/CampaignDoctor"))
);

const campaignDoctorRoute = () => ({
  path: "campaignDoctor",
  element: (
    <PermissionsGuard
      neededPermissions={[{ feature: "campaignDoctor", action: "READ" }]}
    >
      <CampaignDoctor />
    </PermissionsGuard>
  ),
  breadcrumb: "Doctor (Campaigns)",
});

export default campaignDoctorRoute;

import * as api from "../apis/etlRunsApi";
import { NotFoundException } from "../customExceptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetETLRuns = ({
  filterText,
  filterStartDate,
  filterEndDate,
  filterWithErrors,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  return useQuery(
    [
      "etlRuns",
      filterText,
      filterStartDate,
      filterEndDate,
      filterWithErrors,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    () =>
      api.getETLRuns({
        filterText,
        filterStartDate,
        filterEndDate,
        filterWithErrors,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    {
      useErrorBoundary: true,
      keepPreviousData: true,
    }
  );
};

export const useGetETLRun = (id) => {
  const queryClient = useQueryClient();
  let queryKey;

  return useQuery(["etlRun", id], () => api.getETLRun(id), {
    enabled: Boolean(id),
    useErrorBoundary: (error) => !(error instanceof NotFoundException),
    initialData: () => {
      // to init data, we try to find the etlRun in one of the "etlRuns" queries
      let found;

      queryClient.getQueriesData(["etlRuns"]).forEach((query) => {
        const data = query[1]?.data;
        if (data) {
          data.forEach((etlRun) => {
            if (etlRun.id === id) {
              found = etlRun;
              queryKey = query[0];
            }
          });
        }
      });

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

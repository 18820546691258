import { useEffect, Fragment, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
} from "@material-ui/core";
import VerifyEmailForm from "../../components/authentication/VerifyEmailForm";
import Logo from "../../components/general/Logo";
import gtm from "../../helpers/gtm";
import toast from "react-hot-toast";
import Loader from "@material-ui/core/CircularProgress";
import useURLQuery from "../../hooks/useURLQuery";
import { setCurrentUser } from "../../slices/auth";
import { useVerifyEmail } from "../../mutations/auth";

const VerifyEmail = () => {
  
  // Mutations

  const { isLoading: isDoingAutoVerifyEmail, mutate: mutateAutoVerifyEmail } =
    useVerifyEmail();

  // Hooks

  const dispatch = useDispatch();
  const urlQuery = useURLQuery();
  const email = urlQuery.get("email");
  const token = urlQuery.get("token");
  const navigate = useNavigate();
  const [errors, setErrors] = useState(null);

  // Google Tag Manager

useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  useEffect(() => {
    const autoVerifyEmail = async () => {
      // if an email and token is provided in the url's query
      // then we auto-verify the email
      // otherwise we display the form
      if (email && token) {
        mutateAutoVerifyEmail(
          { email, token },
          {
            onError: () => {
              toast.error("Email verification failed");
              setErrors("Email verification failed");
            },
            onSuccess: (result) => {
              dispatch(setCurrentUser(result.user));
              toast.success('Email verified');
              navigate("/app");
            },
          }
        );
      }
    };

    autoVerifyEmail();
  }, [mutateAutoVerifyEmail, dispatch, email, navigate, token]);

  // Rendering

  // Display a loader when verifying the email

  if (isDoingAutoVerifyEmail) {
    return (
      <Fragment>
        <Box
          sx={{
            alignItems: "center",
            backgroundColor: "background.paper",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            left: 0,
            p: 3,
            position: "fixed",
            top: 0,
            width: "100%",
            zIndex: 2000,
          }}
        >
          <Logo />
          <Box sx={{ mt: 3 }}>
            <Typography color="textPrimary" variant="h5">
              Verifying email...
            </Typography>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Loader color="primary" />
          </Box>
        </Box>
      </Fragment>
    );
  }

  // if email is set (link from email)
  if (email && token && !isDoingAutoVerifyEmail && !errors) {
    return null;
  }

  // if no email is set (not coming from email link), we display the page with the form

  return (
    <Fragment>
      <Helmet>
        <title>Lean Ads | Verify Email</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="sm" sx={{ py: 10 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 40,
                  width: 40,
                }}
              />
            </RouterLink>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 8,
            }}
          />
          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Verify Email
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    Confirm sign up using the code we sent you by email {email}
                  </Typography>
                </div>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                <VerifyEmailForm />
              </Box>
              <Divider sx={{ my: 3 }} />
              <Link
                color="textSecondary"
                component={RouterLink}
                to="/authentication/forgot-password"
                variant="body2"
              >
                I did not receive the code
              </Link>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </Fragment>
  );
};

export default VerifyEmail;

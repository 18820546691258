import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "../slices/auth";
import { appLayoutReducer } from "../slices/app-layout";
import { campaignFiltersReducer } from "../slices/campaign-filters";
import { advertiserFiltersReducer } from "../slices/advertiser-filters";
import { brandFiltersReducer } from "../slices/brand-filters";
import { platformFiltersReducer } from "../slices/platform-filters";
import { accountFiltersReducer } from "../slices/account-filters";
import { accountReportingSettingsReducer } from "../slices/account-reporting-settings";
import { accountPresentationSettingsReducer } from "../slices/account-presentation-settings";
import { postFiltersReducer } from "../slices/post-filters";
import { postPaginationReducer } from "../slices/post-pagination";
import { dataOriginFiltersReducer } from "../slices/dataOrigin-filters";
import { userFiltersReducer } from "../slices/user-filters";
import { postBoostCampaignFiltersReducer } from "../slices/postBoostCampaign-filters";
import { postBoostCampaignPaginationReducer } from "../slices/postBoostCampaign-pagination";
import { problemTypeFiltersReducer } from "../slices/problemType-filters";
import { etlRunFiltersReducer } from "../slices/etlRun-filters";
import { etlRunPaginationReducer } from "../slices/etlRun-pagination";
import { etlTaskFiltersReducer } from "../slices/etlTask-filters";
import { etlTaskPaginationReducer } from "../slices/etlTask-pagination";
import { adAccountFiltersReducer } from "../slices/adAccount-filters";
import { countryFiltersReducer } from "../slices/country-filters";
import { metricFiltersReducer } from "../slices/metric-filters";
import { offerFiltersReducer } from "../slices/offer-filters";
import { lineItemFiltersReducer } from "../slices/lineItem-filters";
import { lineItemPaginationReducer } from "../slices/lineItem-pagination";
import { lineItemPresentationSettingsReducer } from "../slices/lineItem-presentation-settings";
import { lineItemPerformanceSettingsReducer } from "../slices/lineItem-performance-settings";
import { adOpsCampaignFiltersReducer } from "../slices/adOpsCampaign-filters";
import { adOpsCampaignPaginationReducer } from "../slices/adOpsCampaign-pagination";
import { adOpsCampaignPerformanceSettingsReducer } from "../slices/adOpsCampaign-performance-settings";
import { adPlatformFiltersReducer } from "../slices/adPlatform-filters";
import { adFormatFiltersReducer } from "../slices/adFormat-filters";
import { specTemplateFiltersReducer } from "../slices/specTemplate-filters";
import { adOpsObjectiveFiltersReducer } from "../slices/adOpsObjective-filters";
import { chatReducer } from "../slices/chat";

const store = configureStore({
  reducer: {
    auth: authReducer,
    appLayout: appLayoutReducer,
    userFilters: userFiltersReducer,
    advertiserFilters: advertiserFiltersReducer,
    platformFilters: platformFiltersReducer,
    brandFilters: brandFiltersReducer,
    campaignFilters: campaignFiltersReducer,
    accountFilters: accountFiltersReducer,
    accountReportingSettings: accountReportingSettingsReducer,
    accountPresentationSettings: accountPresentationSettingsReducer,
    dataOriginFilters: dataOriginFiltersReducer,
    postFilters: postFiltersReducer,
    postPagination: postPaginationReducer,
    postBoostCampaignFilters: postBoostCampaignFiltersReducer,
    postBoostCampaignPagination: postBoostCampaignPaginationReducer,
    problemTypeFilters: problemTypeFiltersReducer,
    etlRunFilters: etlRunFiltersReducer,
    etlRunPagination: etlRunPaginationReducer,
    etlTaskFilters: etlTaskFiltersReducer,
    etlTaskPagination: etlTaskPaginationReducer,
    adAccountFilters: adAccountFiltersReducer,
    countryFilters: countryFiltersReducer,
    metricFilters: metricFiltersReducer,
    offerFilters: offerFiltersReducer,
    lineItemFilters: lineItemFiltersReducer,
    lineItemPagination: lineItemPaginationReducer,
    lineItemPresentationSettings: lineItemPresentationSettingsReducer,
    lineItemPerformanceSettings: lineItemPerformanceSettingsReducer,
    adOpsCampaignFilters: adOpsCampaignFiltersReducer,
    adOpsCampaignPagination: adOpsCampaignPaginationReducer,
    adOpsCampaignPerformanceSettings: adOpsCampaignPerformanceSettingsReducer,
    adPlatformFilters: adPlatformFiltersReducer,
    adFormatFilters: adFormatFiltersReducer,
    specTemplateFilters: specTemplateFiltersReducer,
    adOpsObjectiveFilters: adOpsObjectiveFiltersReducer,
    chat: chatReducer
  }
});

export default store;

export class UnauthorizedException extends Error {}

export class ServerException extends Error {}

export class UnknownException extends Error {}

export class ValidationException extends Error {
  constructor(message, validationErrors) {
    super(message);
    this.validationErrors = validationErrors;
  }
}

export class NotFoundException extends Error {
  constructor(message, validationErrors) {
    super(message);
    this.validationErrors = validationErrors;
  }
}

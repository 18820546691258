import * as api from "../apis/metricsApi";
import { NotFoundException } from "../customExceptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetMetrics = () => {
  return useQuery(["metrics"], api.getMetrics, {
    useErrorBoundary: true,
  });
};

export const useGetMetricsByPlatform = (platformId) => {
  const queryClient = useQueryClient();

  return useQuery(
    ["platform.metrics", platformId],
    () => api.getMetricsByPlatform(platformId),
    {
      enabled: Boolean(platformId),
      useErrorBoundary: true,
      initialData: () =>
        queryClient
          .getQueryData(["metrics"])
          ?.filter((metric) => metric.platform.id === platformId),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(["metrics"])?.dataUpdatedAt,
    }
  );
};

export const useGetMetric = (id) => {
  const queryClient = useQueryClient();
  let queryKey;

  return useQuery(["metric", id], () => api.getMetric(id), {
    enabled: Boolean(id),
    useErrorBoundary: (error) => !(error instanceof NotFoundException),
    initialData: () => {
      // if "metrics" query exist, we use it to init data
      if (queryClient.getQueryState(["metrics"])) {
        queryKey = ["metrics"];
        return queryClient
          .getQueryData(["metrics"])
          ?.find((metric) => metric.id === id);
      }

      // otherwise, we try to find the metric in one of the "platform.metrics" queries
      let found;

      queryClient.getQueriesData(["platform.metrics"]).forEach((query) => {
        const data = query[1];
        if (data) {
          data.forEach((metric) => {
            if (metric.id === id) {
              found = metric;
              queryKey = query[0];
            }
          });
        }
      });

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

import * as api from "../apis/specTemplatesApi";
import { NotFoundException } from "../customExceptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetSpecTemplates = () =>
  useQuery(["specTemplates"], api.getSpecTemplates, {
    useErrorBoundary: true,
  });

export const useGetSpecTemplate = (id) => {
  const queryClient = useQueryClient();
  return useQuery(
    ["specTemplate", id],
    () => api.getSpecTemplate(id),
    {
      enabled: Boolean(id),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
      initialData: () =>
        queryClient
          .getQueryData(["specTemplates"])
          ?.find((specTemplate) => specTemplate.id === id),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(["specTemplates"])?.dataUpdatedAt,
    }
  );
};

import Loadable from "../components/general/Loadable";
import { lazy } from "react";

// Pages
const AppHome = Loadable(lazy(() => import("../pages/AppHome")));

const appHomeRoute = () => ({
  path: "",
  element: <AppHome />,
  breadcrumb: "Home",
});
export default appHomeRoute;

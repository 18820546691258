import { Fragment } from "react";
import PropTypes from "prop-types";
import NotFound from "../../pages/NotFound";
import { useSelector } from "react-redux";

// Check if the user has the permission to access the feature
const PermissionsGuard = ({
  children,
  neededPermissions,
}) => {
  // Hooks
  const { currentUser } = useSelector((state) => state.auth);

  // Check authorization
  let hasNeededPermissions = true;
  for (const neededPermission of neededPermissions) {
    let hasNeededPermission;
    const { feature, action } = neededPermission;

    const userPermissions = currentUser.permissions.find(
      (p) => p.feature === feature
    );

    // Whatever the action, if no permissions exist for the requested feature, we refuse access
    if (!userPermissions) {
      hasNeededPermission = false;
    }else if (action === "READ") {
      // If requested action is READ we dont need to check action permission
      hasNeededPermission = true
    }else {
      let property;
      switch (action) {
        case "CREATE":
          property = "canCreate";
          break;
        case "UPDATE":
          property = "canUpdate";
          break;
        case "DELETE":
          property = "canDelete";
          break;
        default:
          break;
      }
    
      hasNeededPermission = userPermissions[property]
    }

    hasNeededPermissions = hasNeededPermissions && hasNeededPermission

  }


  // Render

  // If the user does not have all needed permissions, we refuse access
  if (!hasNeededPermissions) {
    return <NotFound />;
  }

  return <Fragment>{children}</Fragment>;
};

PermissionsGuard.propTypes = {
  children: PropTypes.node,
  requestedFeature: PropTypes.string,
  requestedAction: PropTypes.string,
};

export default PermissionsGuard;

import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import { useGetProblemType } from "../queries/problemTypes";

export const ProblemTypeBreadcrumb = ({ match }) => {
    const {
      data: problemType,
      isLoading,
      isError,
      error,
    } = useGetProblemType(match.params.problemTypeId);
  
    if (isLoading) {
      //return <span>Loading...</span>;
      return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
    }
  
    if (isError && error instanceof NotFoundException) {
      return <span>Not found</span>;
    }
  
    if (problemType) {
      return <span>{problemType.name}</span>;
    }
  
    return null;
  };
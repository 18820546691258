import { UserBreadcrumb } from "../breadcrumbs/user";
import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import { DATA } from "../constants";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";
import PermissionsGuard from "../components/routes/PermissionsGuard";

// Pages
const UserList = Loadable(lazy(() => import("../pages/users/UserList")));
const UserCreate = Loadable(lazy(() => import("../pages/users/UserCreate")));
const UserView = Loadable(lazy(() => import("../pages/users/UserView")));
const UserEdit = Loadable(lazy(() => import("../pages/users/UserEdit")));

const userRoutes = (directParentType = "", grandParentTypes = []) => {
  // there is no parent for user
  const listComponent = (
    <PermissionsGuard
      neededPermissions={[{ feature: DATA.USER.type, action: "READ" }]}
    >
      <UserList />
    </PermissionsGuard>
  );
  const createComponent = (
    <PermissionsGuard
      neededPermissions={[{ feature: DATA.USER.type, action: "CREATE" }]}
    >
      <UserCreate />
    </PermissionsGuard>
  );

  const route = {
    path: "users",
    children: [
      {
        path: "",
        element: listComponent,
        breadcrumb: capitalizeFirstLetter(DATA.USER.readablePlural),
      },
      {
        path: "create",
        element: createComponent,
        breadcrumb: "Create new",
      },
      {
        path: ":userId",
        children: [
          {
            path: "",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.USER.type, action: "READ" },
                ]}
              >
                <UserView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: UserBreadcrumb,
          },
          {
            path: "edit",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.USER.type, action: "UPDATE" },
                ]}
              >
                <UserEdit
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Edit",
          },
          {
            path: "about",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.USER.type, action: "READ" },
                ]}
              >
                <UserView
                  tab="about"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "About",
          },
          {
            path: "permissions",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.USER.type, action: "READ" },
                ]}
              >
                <UserView
                  tab="permissions"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Permissions",
          },
        ],
      },
    ],
  };

  return route;
};

export default userRoutes;

import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import { useGetLineItem } from "../queries/lineItems";

export const LineItemBreadcrumb = ({ match }) => {
    const {
      data: lineItem,
      isLoading,
      isError,
      error,
    } = useGetLineItem(match.params.lineItemId);
  
    if (isLoading) {
      //return <span>Loading...</span>;
      return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
    }
  
    if (isError && error instanceof NotFoundException) {
      return <span>Not found</span>;
    }
  
    if (lineItem) {
      return <span>{lineItem.name}</span>;
    }
  
    return null;
  };
import { AdvertiserBreadcrumb } from "../breadcrumbs/advertiser";
import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import { DATA } from "../constants";
import campaignRoutes from "./campaign";
import brandRoutes from "./brand";
import accountRoutes from "./account";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";
import PermissionsGuard from "../components/routes/PermissionsGuard";

// Pages
const AdvertiserList = Loadable(
  lazy(() => import("../pages/advertisers/AdvertiserList"))
);
const AdvertiserCreate = Loadable(
  lazy(() => import("../pages/advertisers/AdvertiserCreate"))
);
const AdvertiserView = Loadable(
  lazy(() => import("../pages/advertisers/AdvertiserView"))
);
const AdvertiserEdit = Loadable(
  lazy(() => import("../pages/advertisers/AdvertiserEdit"))
);

const advertiserRoutes = (directParentType = "", grandParentTypes = []) => {
  // there is no parent for advertiser
  const listComponent = (
    <PermissionsGuard
      neededPermissions={[{ feature: DATA.ADVERTISER.type, action: "READ" }]}
    >
      <AdvertiserList />
    </PermissionsGuard>
  );
  const createComponent = (
    <PermissionsGuard
      neededPermissions={[{ feature: DATA.ADVERTISER.type, action: "CREATE" }]}
    >
      <AdvertiserCreate />
    </PermissionsGuard>
  );

  const route = {
    path: "advertisers",
    children: [
      {
        path: "",
        element: listComponent,
        breadcrumb: capitalizeFirstLetter(DATA.ADVERTISER.readablePlural),
      },
      {
        path: "create",
        element: createComponent,
        breadcrumb: "Create new",
      },
      {
        path: ":advertiserId",
        children: [
          {
            path: "",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ADVERTISER.type, action: "READ" },
                ]}
              >
                <AdvertiserView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: AdvertiserBreadcrumb,
          },
          {
            path: "edit",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ADVERTISER.type, action: "UPDATE" },
                ]}
              >
                <AdvertiserEdit
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Edit",
          },
          {
            path: "about",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ADVERTISER.type, action: "READ" },
                ]}
              >
                <AdvertiserView
                  tab="about"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "About",
          },
          {
            ...campaignRoutes(
              DATA.ADVERTISER.type,
              directParentType ? [...grandParentTypes, directParentType] : []
            ),
          },
          {
            ...brandRoutes(
              DATA.ADVERTISER.type,
              directParentType ? [...grandParentTypes, directParentType] : []
            ),
          },
          {
            ...accountRoutes(
              DATA.ADVERTISER.type,
              directParentType ? [...grandParentTypes, directParentType] : []
            ),
          },
        ],
      },
    ],
  };

  return route;
};

export default advertiserRoutes;

import * as api from "../apis/adPlatformsApi";
import { NotFoundException } from "../customExceptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetAdPlatforms = () =>
  useQuery(["adPlatforms"], api.getAdPlatforms, {
    useErrorBoundary: true,
  });

export const useGetAdPlatform = (id) => {
  const queryClient = useQueryClient();
  return useQuery(
    ["adPlatform", id],
    () => api.getAdPlatform(id),
    {
      enabled: Boolean(id),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
      initialData: () =>
        queryClient
          .getQueryData(["adPlatforms"])
          ?.find((adPlatform) => adPlatform.id === id),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(["adPlatforms"])?.dataUpdatedAt,
    }
  );
};

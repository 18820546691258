import { AdAccountBreadcrumb } from "../breadcrumbs/adAccount";
import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import { DATA } from "../constants";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";
import PermissionsGuard from "../components/routes/PermissionsGuard";

// Pages
const AdAccountList = Loadable(
  lazy(() => import("../pages/adAccounts/AdAccountList"))
);
const AdAccountView = Loadable(
  lazy(() => import("../pages/adAccounts/AdAccountView"))
);
const AdAccountEdit = Loadable(
  lazy(() => import("../pages/adAccounts/AdAccountEdit"))
);

// Direct parent pages
const AdPlatformView = Loadable(
  lazy(() => import("../pages/adPlatforms/AdPlatformView"))
);

const adAccountRoutes = (directParentType = "", grandParentTypes = []) => {
  const listComponent =
    directParentType === DATA.ADPLATFORM.type ? (
      // Here we call the parent component. So we pass grand-parents as parents
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.ADPLATFORM.type, action: "READ" },
          { feature: DATA.ADACCOUNT.type, action: "READ" },
        ]}
      >
        <AdPlatformView tab="adAccounts" parentTypes={grandParentTypes} />
      </PermissionsGuard>
    ) : (
      <PermissionsGuard
        neededPermissions={[{ feature: DATA.ADACCOUNT.type, action: "READ" }]}
      >
        <AdAccountList />
      </PermissionsGuard>
    );

  const route = {
    path: "adAccounts",
    children: [
      {
        path: "",
        element: listComponent,
        breadcrumb: capitalizeFirstLetter(DATA.ADACCOUNT.readablePlural),
      },
      {
        path: ":adAccountId",
        children: [
          {
            path: "",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ADACCOUNT.type, action: "READ" },
                ]}
              >
                <AdAccountView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: AdAccountBreadcrumb,
          },
          {
            path: "edit",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ADACCOUNT.type, action: "UPDATE" },
                ]}
              >
                <AdAccountEdit
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Edit",
          },
        ],
      },
    ],
  };

  return route;
};

export default adAccountRoutes;

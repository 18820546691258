import { createSlice } from "@reduxjs/toolkit";

const initialFilters = {
  tab: "all",
  text: "",
  sortBy: "name-desc",
};

// INITIAL STATE
const initialState = {
  postBoostCampaigns: initialFilters,
  posts: [],
};

const postBoostCampaignFiltersSlice = createSlice({
  name: "postBoostCampaignFilters",
  initialState,
  reducers: {
    setTabFilter: (state, action) => {
      switch (action.payload.module) {
        case "postBoostCampaigns":
          state.postBoostCampaigns.tab = action.payload.tab;
          break;
        case "posts":
          state.posts = state.posts.map((post) => {
            if (post.id === action.payload.id) {
              const updatedFilters = {
                ...post.filters,
                tab: action.payload.tab,
              };
              return { ...post, filters: updatedFilters };
            } else {
              return post;
            }
          });
          break;
        default:
          break;
      }
    },
    setTextFilter: (state, action) => {
      switch (action.payload.module) {
        case "postBoostCampaigns":
          state.postBoostCampaigns.text = action.payload.text;
          break;
        case "posts":
          state.posts = state.posts.map((post) => {
            if (post.id === action.payload.id) {
              const updatedFilters = {
                ...post.filters,
                text: action.payload.text,
              };
              return { ...post, filters: updatedFilters };
            } else {
              return post;
            }
          });
          break;
        default:
          break;
      }
    },
    sortByNameAsc: (state, action) => {
      switch (action.payload.module) {
        case "postBoostCampaigns":
          state.postBoostCampaigns.sortBy = "name-asc";
          break;
        case "posts":
          state.posts = state.posts.map((post) => {
            if (post.id === action.payload.id) {
              const updatedFilters = {
                ...post.filters,
                sortBy: "name-asc",
              };
              return { ...post, filters: updatedFilters };
            } else {
              return post;
            }
          });
          break;

        default:
          break;
      }
    },
    sortByNameDesc: (state, action) => {
      switch (action.payload.module) {
        case "postBoostCampaigns":
          state.postBoostCampaigns.sortBy = "name-desc";
          break;
        case "posts":
          state.posts = state.posts.map((post) => {
            if (post.id === action.payload.id) {
              const updatedFilters = {
                ...post.filters,
                sortBy: "name-desc",
              };
              return { ...post, filters: updatedFilters };
            } else {
              return post;
            }
          });
          break;

        default:
          break;
      }
    },

    initFilterSet: (state, action) => {
      switch (action.payload.module) {
        case "postBoostCampaigns":
          state.postBoostCampaigns = initialFilters;
          break;
        case "posts":
          if (
            !state.posts.find((post) => post.id === action.payload.id)
          ) {
            state.posts.push({
              id: action.payload.id,
              filters: initialFilters,
            });
          }
          break;
        
        default:
          break;
      }
    },
  },
});

export const {
  setTabFilter,
  setTextFilter,
  sortByNameAsc,
  sortByNameDesc,
  initFilterSet,
} = postBoostCampaignFiltersSlice.actions;
export const postBoostCampaignFiltersReducer = postBoostCampaignFiltersSlice.reducer;

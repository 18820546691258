import { PostBreadcrumb } from "../breadcrumbs/post";
import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import { DATA } from "../constants";
import postBoostCampaignRoutes from "./postBoostCampaign";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";
import PermissionsGuard from "../components/routes/PermissionsGuard";

// Pages
const PostList = Loadable(lazy(() => import("../pages/posts/PostList")));
const PostView = Loadable(lazy(() => import("../pages/posts/PostView")));
const PostEdit = Loadable(lazy(() => import("../pages/posts/PostEdit")));

// Direct parent pages
const AccountView = Loadable(
  lazy(() => import("../pages/accounts/AccountView"))
);
const BrandView = Loadable(lazy(() => import("../pages/brands/BrandView")));
const ProblemTypeView = Loadable(
  lazy(() => import("../pages/problemTypes/ProblemTypeView"))
);

const postRoutes = (directParentType = "", grandParentTypes = []) => {
  const listComponent =
    directParentType === DATA.ACCOUNT.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.ACCOUNT.type, action: "READ" },
          { feature: DATA.POST.type, action: "READ" },
        ]}
      >
        <AccountView tab="posts" parentTypes={grandParentTypes} />
      </PermissionsGuard>
    ) : directParentType === DATA.BRAND.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.BRAND.type, action: "READ" },
          { feature: DATA.POST.type, action: "READ" },
        ]}
      >
        <BrandView tab="posts" parentTypes={grandParentTypes} />
      </PermissionsGuard>
    ) : directParentType === DATA.PROBLEMTYPE.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.PROBLEMTYPE.type, action: "READ" },
          { feature: DATA.POST.type, action: "READ" },
        ]}
      >
        <ProblemTypeView tab="posts" parentTypes={grandParentTypes} />
      </PermissionsGuard>
    ) : (
      <PermissionsGuard
        neededPermissions={[{ feature: DATA.POST.type, action: "READ" }]}
      >
        <PostList />
      </PermissionsGuard>
    );

  const route = {
    path: "posts",
    children: [
      {
        path: "",
        element: listComponent,
        breadcrumb: capitalizeFirstLetter(DATA.POST.readablePlural),
      },
      {
        path: ":postId",
        children: [
          {
            path: "",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.POST.type, action: "READ" },
                ]}
              >
                <PostView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: PostBreadcrumb,
          },
          {
            path: "edit",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.POST.type, action: "UPDATE" },
                ]}
              >
                <PostEdit
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Edit",
          },
          {
            path: "performance",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.POST.type, action: "READ" },
                ]}
              >
                <PostView
                  tab="performance"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Performance",
          },
          {
            ...postBoostCampaignRoutes(
              DATA.POST.type,
              directParentType ? [...grandParentTypes, directParentType] : []
            ),
          },
        ],
      },
    ],
  };

  return route;
};

export default postRoutes;

import {
  NotFoundException,
  ServerException,
  UnauthorizedException,
  UnknownException,
  ValidationException,
} from "../../customExceptions";

const handleServerResponse = async (response) => {
  const validResponse = response.ok;
  const result = await response.json();
  if (!validResponse) {
    if ([401, 403].includes(response.status)) {
      throw new UnauthorizedException(result.message);
    }
    if (response.status === 404) {
      throw new NotFoundException(result.message, result.errors);
    }
    if ([400, 422].includes(response.status)) {
      throw new ValidationException(result.message, result.errors);
    }
    if (response.status === 500) {
      throw new ServerException(result.message);
    }
    throw new UnknownException(result.message);
  }
  // we check if the results from the server are paginated or not
  if(!isNaN(result.nbDocs)){
    // paginated results
    return {
      data: result.data,
      nbDocs: result.nbDocs
    }
  }
  // not paginated results
  return result.data;
};

export default handleServerResponse;

import AuthProvider from "../components/routes/AuthProvider";
import AuthGuard from "../components/routes/AuthGuard";
import AppLayout from "../components/layouts/app/AppLayout";
import appHomeRoute from "./appHome";
import authRoute from "./authRoute";
import webRoute from "./webRoute";
import defaultRoute from "./defaultRoute";
import campaignRoute from "./campaign";
import campaignAnalytics from "./campaignAnalytics";
import campaignDoctor from "./campaignDoctor";
import campaignAgenda from "./campaignAgenda";
import advertiserRoute from "./advertiser";
import brandRoute from "./brand";
import accountRoute from "./account";
import lineItemRoute from "./lineItem";
import countryRoute from "./country";
import metricRoute from "./metric";
import offerRoute from "./offer";
import problemTypeRoute from "./problemType";
import postRoute from "./post";
import postBoostCampaignRoute from "./postBoostCampaign";
import postBoostDoctorRoute from "./postBoostDoctor";
import platformRoute from "./platform";
import dataOriginRoute from "./dataOrigin";
import adOpsCampaignRoute from "./adOpsCampaign";
import adAccountRoute from "./adAccount";
import adPlatformRoute from "./adPlatform";
import etlTaskRoute from "./etlTask";
import etlRunRoute from "./etlRun";
import userRoute from "./user";
import adFormatRoutes from "./adFormat";
import specTemplateRoutes from "./specTemplate";
import adOpsObjective from "./adOpsObjective"
import dataImportsRoute from "./dataImports";
import meRoute from "./me";

export const routes = [
  {
    path: "app",
    element: (
      <AuthProvider>
        <AuthGuard>
          <AppLayout />
        </AuthGuard>
      </AuthProvider>
    ),
    children: [
      appHomeRoute(),
      campaignRoute(),
      campaignAnalytics(),
      campaignDoctor(),
      campaignAgenda(),
      advertiserRoute(),
      brandRoute(),
      platformRoute(),
      accountRoute(),
      postRoute(),
      dataOriginRoute(),
      postBoostCampaignRoute(),
      postBoostDoctorRoute(),
      userRoute(),
      problemTypeRoute(),
      etlRunRoute(),
      etlTaskRoute(),
      adAccountRoute(),
      countryRoute(),
      metricRoute(),
      offerRoute(),
      lineItemRoute(),
      adOpsCampaignRoute(),
      adPlatformRoute(),
      adFormatRoutes(),
      specTemplateRoutes(),
      adOpsObjective(),
      dataImportsRoute(),
      meRoute(),
      defaultRoute(),
    ],
  },
  authRoute(),
  webRoute(),
];

import fetchServer from "../helpers/api/fetchServer";

export const getETLTasks = async ({
  filterText,
  filterWithErrors,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  const method = "GET";
  let urlAPI = "/api/etlTasks?";
  // Filters
  if (filterText) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `text=${filterText}`;
  }
  if (filterWithErrors) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `withErrors=${filterWithErrors}`;
  }
  // Sort by
  if (urlAPI.slice(-1) !== "?") {
    urlAPI += "&";
  }

  urlAPI += `sortBy=${sortBy}`;

  // Pagination
  urlAPI += `&page=${page}&nbDocsPerPage=${nbDocsPerPage}`;

  return await fetchServer({ method, urlAPI });
};

export const getETLTasksByETLRun = async ({
  etlRunId,
  filterText,
  filterWithErrors,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  const method = "GET";
  let urlAPI = `/api/etlTasks?etlRun=${etlRunId}`;
  // Filters
  if (filterText) {
    urlAPI += `&text=${filterText}`;
  }
  if (filterWithErrors) {
    urlAPI += `&withErrors=${filterWithErrors}`;
  }
  // Sort by
  urlAPI += `&sortBy=${sortBy}`;

  // Pagination
  urlAPI += `&page=${page}&nbDocsPerPage=${nbDocsPerPage}`;

  return await fetchServer({ method, urlAPI });
};

export const getETLTask = async (id) => {
  const method = "GET";
  const urlAPI = `/api/etlTasks/${id}`;
  return await fetchServer({ method, urlAPI });
};
import * as api from "../apis/problemTypesApi";
import { NotFoundException } from "../customExceptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetProblemTypes = () => {
  return useQuery(["problemTypes"], api.getProblemTypes, {
    useErrorBoundary: true,
  });
};

export const useGetProblemTypesByPlatform = (platformId) => {
  const queryClient = useQueryClient();

  return useQuery(
    ["platform.problemTypes", platformId],
    () => api.getProblemTypesByPlatform(platformId),
    {
      enabled: Boolean(platformId),
      useErrorBoundary: true,
      initialData: () =>
        queryClient
          .getQueryData(["problemTypes"])
          ?.filter((problemType) => problemType.platform.id === platformId),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(["problemTypes"])?.dataUpdatedAt,
    }
  );
};

export const useGetProblemType = (id) => {
  const queryClient = useQueryClient();
  let queryKey;

  return useQuery(["problemType", id], () => api.getProblemType(id), {
    enabled: Boolean(id),
    useErrorBoundary: (error) => !(error instanceof NotFoundException),
    initialData: () => {
      // if "problemTypes" query exist, we use it to init data
      if (queryClient.getQueryState(["problemTypes"])) {
        queryKey = ["problemTypes"];
        return queryClient
          .getQueryData(["problemTypes"])
          ?.find((problemType) => problemType.id === id);
      }

      // otherwise, we try to find the problemType in one of the "platform.problemTypes" queries
      let found;

      queryClient.getQueriesData(["platform.problemTypes"]).forEach((query) => {
        const data = query[1];
        if (data) {
          data.forEach((problemType) => {
            if (problemType.id === id) {
              found = problemType;
              queryKey = query[0];
            }
          });
        }
      });

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

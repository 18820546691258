import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import { useGetAdOpsObjective } from "../queries/adOpsObjectives";

export const AdOpsObjectiveBreadcrumb = ({ match }) => {
    const {
      data: adOpsObjective,
      isLoading,
      isError,
      error,
    } = useGetAdOpsObjective(match.params.adOpsObjectiveId);
  
    if (isLoading) {
      //return <span>Loading...</span>;
      return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
    }
  
    if (isError && error instanceof NotFoundException) {
      return <span>Not found</span>;
    }
  
    if (adOpsObjective) {
      return <span>{adOpsObjective.name}</span>;
    }
  
    return null;
  };
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Box, FormHelperText, TextField, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import LoadingButton from "../general/LoadingButton";
import { ValidationException } from "../../customExceptions";
import { setCurrentUser } from "../../slices/auth";
import { useVerifyEmail } from "../../mutations/auth";

const VerifyEmailForm = () => {
  // Hooks

  const itemsRef = useRef([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, 6);
  }, []);

  // Mutations

  const { isLoading: isDoingVerifyEmail, mutate: mutateVerifyEmail } =
    useVerifyEmail();

  // Formik configuration to handle Verify Email

  const initialValues = {
    email: currentUser ? currentUser.email : "",
    code: ["", "", "", "", "", ""],
    submit: null,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    code: Yup.array().of(Yup.string().required("Code is required")),
  });

  const onSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    mutateVerifyEmail(
      {
        email: values.email,
        token: values.code.join(""),
      },
      {
        onError: (error) => {
          toast.error("Email verification failed");
          if (error instanceof ValidationException) {
            setStatus({ success: false });
            setErrors(error.validationErrors);
            setSubmitting(false);
          }
        },
        onSuccess: (result) => {
          dispatch(setCurrentUser(result.user));
          toast.success('Email verified');
          navigate("/app");
        },
      }
    );
  };

  // Rendering

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
        dirty,
        isValid,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <Typography
            color="textSecondary"
            sx={{
              mb: 2,
              mt: 3,
            }}
            variant="subtitle2"
          >
            Verification code
          </Typography>
          <Box
            sx={{
              display: "grid",
              columnGap: "16px",
              gridTemplateColumns: "repeat(7, 1fr)",
              pt: 1,
            }}
          >
            {[1, 2, 3, 4, 5, 6].map((ref, i) => (
              <TextField
                error={Boolean(
                  Array.isArray(touched.code) &&
                    touched.code.length === 6 &&
                    errors.code
                )}
                fullWidth
                autoFocus={Boolean(ref === 1)}
                inputRef={(el) => (itemsRef.current[i] = el)}
                // eslint-disable-next-line react/no-array-index-key
                key={`code-${i}`}
                name={`code[${i}]`}
                onBlur={handleBlur}
                onKeyDown={(event) => {
                  if (event.code === "ENTER") {
                    if (values.code[i]) {
                      setFieldValue(`code[${i}]`, "");
                      return;
                    }

                    if (i > 0) {
                      setFieldValue(`code[${i}]`, "");
                      itemsRef.current[i - 1].focus();
                      return;
                    }
                  }

                  if (Number.isInteger(parseInt(event.key, 10))) {
                    setFieldValue(`code[${i}]`, event.key);

                    if (i < 5) {
                      itemsRef.current[i + 1].focus();
                    }
                  }
                }}
                onPaste={(event) => {
                  const paste = event.clipboardData.getData("text");
                  const pasteArray = paste.split("");

                  if (pasteArray.length !== 6) {
                    return;
                  }

                  let valid = true;

                  pasteArray.forEach((x) => {
                    if (!Number.isInteger(parseInt(x, 10))) {
                      valid = false;
                    }
                  });

                  if (valid) {
                    // we select the 6th (last) textfield
                    // BEFORE setting the values (otherwise, the form is not valid and the submit button not enabled)
                    itemsRef.current[5].focus();
                    setFieldValue("code", pasteArray);
                  }
                }}
                value={values.code[i]}
                sx={{
                  display: "inline-block",
                  textAlign: "center",
                  "& .MuiInputBase-input": {
                    textAlign: "center",
                  },
                }}
                variant="outlined"
              />
            ))}
          </Box>
          {Boolean(
            Array.isArray(touched.code) &&
              touched.code.length === 6 &&
              errors.code
          ) && (
            <FormHelperText error sx={{ mx: "14px" }}>
              {Array.isArray(errors.code) &&
                errors.code.find((x) => x !== undefined)}
            </FormHelperText>
          )}
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 3 }}>
            <LoadingButton
              type="submit"
              color="primary"
              disabled={isSubmitting || !(dirty && isValid)}
              variant="contained"
              isLoading={isDoingVerifyEmail}
              loadingText="Verifying"
              fullWidth
              size="large"
              >Verify</LoadingButton>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default VerifyEmailForm;

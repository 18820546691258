import { createSlice } from "@reduxjs/toolkit";

// INITIAL STATE
const initialState = {
  page: 1,
  nbDocsPerPage: 25,
};

const etlRunPaginationSlice = createSlice({
  name: "etlRunPagination",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload.page;
    },
    setNbDocsPerPage: (state, action) => {
      state.nbDocsPerPage = action.payload.nbDocsPerPage;
    },
  },
});

export const { setPage, setNbDocsPerPage } = etlRunPaginationSlice.actions;
export const etlRunPaginationReducer = etlRunPaginationSlice.reducer;

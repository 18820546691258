import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import { useGetAccount } from "../queries/accounts";

export const AccountBreadcrumb = ({ match }) => {
    const {
      data: account,
      isLoading,
      isError,
      error,
    } = useGetAccount(match.params.accountId);
  
    if (isLoading) {
      //return <span>Loading...</span>;
      return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
    }
  
    if (isError && error instanceof NotFoundException) {
      return <span>Not found</span>;
    }
  
    if (account) {
      account.url = `${account.platform.url}/${account.username}`;
      return <span>{account.url}</span>;
    }
  
    return null;
  };
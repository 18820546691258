import * as api from "../apis/accountsApi";
import { NotFoundException } from "../customExceptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetAccounts = () => {
  return useQuery(["accounts"], api.getAccounts, {
    useErrorBoundary: true,
  });
};

export const useGetAccountsByAdvertiser = (advertiserId) => {
  const queryClient = useQueryClient();

  return useQuery(
    ["advertiser.accounts", advertiserId],
    () => api.getAccountsByAdvertiser(advertiserId),
    {
      enabled: Boolean(advertiserId),
      useErrorBoundary: true,
      initialData: () =>
        queryClient
          .getQueryData(["accounts"])
          ?.filter((account) => account.advertiser.id === advertiserId),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(["accounts"])?.dataUpdatedAt,
    }
  );
};

export const useGetAccountsByPlatform = (platformId) => {
  const queryClient = useQueryClient();

  return useQuery(
    ["platform.accounts", platformId],
    () => api.getAccountsByPlatform(platformId),
    {
      enabled: Boolean(platformId),
      useErrorBoundary: true,
      initialData: () =>
        queryClient
          .getQueryData(["accounts"])
          ?.filter((account) => account.platform.id === platformId),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(["accounts"])?.dataUpdatedAt,
    }
  );
};

export const useGetAccount = (id) => {
  const queryClient = useQueryClient();
  let queryKey;

  return useQuery(["account", id], () => api.getAccount(id), {
    enabled: Boolean(id),
    useErrorBoundary: (error) => !(error instanceof NotFoundException),
    initialData: () => {
      // if "accounts" query exist, we use it to init data
      if (queryClient.getQueryState(["accounts"])) {
        queryKey = ["accounts"];
        return queryClient
          .getQueryData(["accounts"])
          ?.find((account) => account.id === id);
      }

      // otherwise, we try to find the account in one of the "advertiser.accounts" queries
      let found;

      queryClient.getQueriesData(["advertiser.accounts"]).forEach((query) => {
        const data = query[1];
        data.forEach((account) => {
          if (account.id === id) {
            found = account;
            queryKey = query[0];
          }
        });
      });

      // otherwise, we try to find the account in one of the "platform.accounts" queries
      if (!found) {
        queryClient.getQueriesData(["platform.accounts"]).forEach((query) => {
          const data = query[1];
          data.forEach((account) => {
            if (account.id === id) {
              found = account;
              queryKey = query[0];
            }
          });
        });
      }

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

export const useGetAccountFollowersDailyGrowth = (accountId) => {
  return useQuery(
    ["account.followersDailyGrowth", accountId],
    () => api.getAccountFollowersDailyGrowth(accountId),
    {
      enabled: Boolean(accountId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

export const useGetAccountFollowersGrowth = ({id, startDate, endDate }) => {
  return useQuery(
    ["account.followersDailyGrowth", id, startDate, endDate],
    () => api.getAccountFollowersGrowth({ id, startDate, endDate }),
    {
      enabled: Boolean(id),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
      keepPreviousData: true,
    }
  );
};

export const useGetAccountReporting = ({ id, startDate, endDate }) => {
  return useQuery(
    ["account.reporting", id, startDate, endDate],
    () => api.getAccountReporting({ id, startDate, endDate }),
    {
      enabled: Boolean(id),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
      keepPreviousData: true,
    }
  );
};

export const useGetAccountPresentationCountPostsByFormat = ({ id, startDate, endDate }) => {
  return useQuery(
    ["account.presentation.engagement.countPostsByFormat", id, startDate, endDate],
    () => api.getAccountPresentationCountPostsByFormat({ id, startDate, endDate }),
    {
      enabled: Boolean(id),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
      keepPreviousData: true,
    }
  );
};

export const useGetAccountPresentationEngagementTotal = ({ id, startDate, endDate }) => {
  return useQuery(
    ["account.presentation.engagement.total", id, startDate, endDate],
    () => api.getAccountPresentationEngagementTotal({ id, startDate, endDate }),
    {
      enabled: Boolean(id),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
      keepPreviousData: true,
    }
  );
};

export const useGetAccountPresentationSpentTotal = ({ id, startDate, endDate }) => {
  return useQuery(
    ["account.presentation.spent.total", id, startDate, endDate],
    () => api.getAccountPresentationSpentTotal({ id, startDate, endDate }),
    {
      enabled: Boolean(id),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
      keepPreviousData: true,
    }
  );
};

export const useGetAccountPresentationEngagementByFormat = ({ id, startDate, endDate }) => {
  return useQuery(
    ["account.presentation.engagement.byFormat", id, startDate, endDate],
    () => api.getAccountPresentationEngagementByFormat({ id, startDate, endDate }),
    {
      enabled: Boolean(id),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
      keepPreviousData: true,
    }
  );
};

export const useGetAccountPresentationEngagementTopPosts = ({ id, startDate, endDate }) => {
  return useQuery(
    ["account.presentation.engagement.TopPosts", id, startDate, endDate],
    () => api.getAccountPresentationEngagementTopPosts({ id, startDate, endDate }),
    {
      enabled: Boolean(id),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
      keepPreviousData: true,
    }
  );
};

export const useGetAccountPresentationVideoTotal = ({ id, startDate, endDate }) => {
  return useQuery(
    ["account.presentation.video.total", id, startDate, endDate],
    () => api.getAccountPresentationVideoTotal({ id, startDate, endDate }),
    {
      enabled: Boolean(id),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
      keepPreviousData: true,
    }
  );
};

export const useGetAccountPresentationVideoTopPosts = ({ id, startDate, endDate }) => {
  return useQuery(
    ["account.presentation.video.TopPosts", id, startDate, endDate],
    () => api.getAccountPresentationVideoTopPosts({ id, startDate, endDate }),
    {
      enabled: Boolean(id),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
      keepPreviousData: true,
    }
  );
};

export const useGetAccountPresentationCountPostsByBrand = ({ id, startDate, endDate }) => {
  return useQuery(
    ["account.presentation.brand.countPostsbyBrand", id, startDate, endDate],
    () => api.getAccountPresentationEngagementByBrand({ id, startDate, endDate }),
    {
      enabled: Boolean(id),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
      keepPreviousData: true,
    }
  );
};

export const useGetAccountPresentationCountPostsByProblemType = ({ id, startDate, endDate }) => {
  return useQuery(
    ["account.presentation.problem.countPostsbyProblemType", id, startDate, endDate],
    () => api.getAccountPresentationCountPostsByProblemType({ id, startDate, endDate }),
    {
      enabled: Boolean(id),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
      keepPreviousData: true,
    }
  );
};


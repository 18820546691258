import { AdOpsObjectiveBreadcrumb } from "../breadcrumbs/adOpsObjective";
import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import { DATA } from "../constants";
import offerRoutes from "./offer";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";
import PermissionsGuard from "../components/routes/PermissionsGuard";

const AdOpsObjectiveList = Loadable(
  lazy(() => import("../pages/adOpsObjectives/AdOpsObjectiveList"))
);
const AdOpsObjectiveCreate = Loadable(
  lazy(() => import("../pages/adOpsObjectives/AdOpsObjectiveCreate"))
);
const AdOpsObjectiveView = Loadable(
  lazy(() => import("../pages/adOpsObjectives/AdOpsObjectiveView"))
);
const AdOpsObjectiveEdit = Loadable(
  lazy(() => import("../pages/adOpsObjectives/AdOpsObjectiveEdit"))
);

const adOpsObjectiveRoutes = (directParentType = "", grandParentTypes = []) => {
  // there is no parent for adOpsObjective
  const listComponent = (
    <PermissionsGuard
      neededPermissions={[
        { feature: DATA.ADOPSOBJECTIVE.type, action: "READ" },
      ]}
    >
      <AdOpsObjectiveList />
    </PermissionsGuard>
  );
  const createComponent = (
    <PermissionsGuard
      neededPermissions={[
        { feature: DATA.ADOPSOBJECTIVE.type, action: "CREATE" },
      ]}
    >
      <AdOpsObjectiveCreate />
    </PermissionsGuard>
  );

  const route = {
    path: "adOpsObjectives",
    children: [
      {
        path: "",
        element: listComponent,
        breadcrumb: capitalizeFirstLetter(DATA.ADOPSOBJECTIVE.readablePlural),
      },
      {
        path: "create",
        element: createComponent,
        breadcrumb: "Create new",
      },
      {
        path: ":adOpsObjectiveId",
        children: [
          {
            path: "",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ADOPSOBJECTIVE.type, action: "READ" },
                ]}
              >
                <AdOpsObjectiveView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: AdOpsObjectiveBreadcrumb,
          },
          {
            path: "edit",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ADOPSOBJECTIVE.type, action: "UPDATE" },
                ]}
              >
                <AdOpsObjectiveEdit
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Edit",
          },
          {
            path: "about",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ADOPSOBJECTIVE.type, action: "READ" },
                ]}
              >
                <AdOpsObjectiveView
                  tab="about"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "About",
          },
          {
            ...offerRoutes(
              DATA.ADOPSOBJECTIVE.type,
              directParentType ? [...grandParentTypes, directParentType] : []
            ),
          },
        ],
      },
    ],
  };

  return route;
};

export default adOpsObjectiveRoutes;

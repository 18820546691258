import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import trimString from "../helpers/trimString";
import { useGetAdOpsCampaign } from "../queries/adOpsCampaigns";

export const AdOpsCampaignBreadcrumb = ({ match }) => {
    const {
      data: adOpsCampaign,
      isLoading,
      isError,
      error,
    } = useGetAdOpsCampaign(match.params.adOpsCampaignId);
  
    if (isLoading) {
      //return <span>Loading...</span>;
      return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
    }
  
    if (isError && error instanceof NotFoundException) {
      return <span>Not found</span>;
    }
  
    if (adOpsCampaign) {
      return <span>{trimString(adOpsCampaign.name, 20)}</span>;
    }
  
    return null;
  };
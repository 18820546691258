import React from "react";
import { Link as RouterLink } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { routes } from "../../../routes";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import ChevronRightIcon from "../../../icons/ChevronRight";

const AppBreadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<ChevronRightIcon fontSize="small" sx={{ mt: 0.4 }} />}
      sx={{ mt: 1 }}
    >
      {breadcrumbs.map(({ breadcrumb, match }, index) => {
        if (index < breadcrumbs.length - 1) {
          return (
            <Link
              key={index}
              color="textPrimary"
              component={RouterLink}
              to={match.pathname}
              variant="subtitle2"
              underline="hover"
            >
              {breadcrumb}
            </Link>
          );
        }
        return (
          <Typography
            key={index}
            color="textSecondary"
            variant="subtitle2"
            sx={{ pt: 0.4 }}
          >
            {breadcrumb}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
};

export default AppBreadcrumbs;

import { createSlice } from "@reduxjs/toolkit";

// INITIAL STATE
const initialState = {
  pageTitle: "",
  avatar: undefined,
  cancelButton: undefined,
  backButton: undefined,
  addButton: undefined,
  editButton: undefined,
};

const appLayoutSlice = createSlice({
  name: "appLayout",
  initialState,
  reducers: {
    clearState: (state) => {
      state.pageTitle = initialState.pageTitle;
      state.avatar = initialState.avatar;
      state.cancelButton = initialState.cancelButton;
      state.backButton = initialState.backButton;
      state.addButton = initialState.addButton;
      state.editButton = initialState.editButton;
    },
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload;
    },
    setAvatar: (state, action) => {
      state.avatar = action.payload;
    },
    setCancelButton: (state, action) => {
      state.cancelButton = action.payload;
    },
    setBackButton: (state, action) => {
      state.backButton = action.payload;
    },
    setAddButton: (state, action) => {
      state.addButton = action.payload;
    },
    setEditButton: (state, action) => {
      state.editButton = action.payload;
    },
  },
});

export const {
  clearState,
  setPageTitle,
  setAvatar,
  setCancelButton,
  setBackButton,
  setAddButton,
  setEditButton,
} = appLayoutSlice.actions;
export const appLayoutReducer = appLayoutSlice.reducer;

import * as api from "../apis/lineItemsApi";
import { NotFoundException } from "../customExceptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetLineItems = ({
  filterText,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  return useQuery(
    [
      "lineItems",
      filterText,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    () =>
      api.getLineItems({
        filterText,
        filterStartDate,
        filterEndDate,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    {
      useErrorBoundary: true,
      keepPreviousData: true,
    }
  );
};

export const useGetLineItemsByCampaign = ({
  campaignId,
  filterText,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  return useQuery(
    [
      "campaign.lineItems",
      campaignId,
      filterText,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    () =>
      api.getLineItemsByCampaign({
        campaignId,
        filterText,
        filterStartDate,
        filterEndDate,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    {
      enabled: Boolean(campaignId),
      useErrorBoundary: true,
      keepPreviousData: true,
    }
  );
};

export const useGetLineItemsByOffer = ({
  offerId,
  filterText,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  return useQuery(
    [
      "offer.lineItems",
      offerId,
      filterText,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    () =>
      api.getLineItemsByOffer({
        offerId,
        filterText,
        filterStartDate,
        filterEndDate,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    {
      enabled: Boolean(offerId),
      useErrorBoundary: true,
      keepPreviousData: true,
    }
  );
};

export const useGetLineItem = (id) => {
  const queryClient = useQueryClient();
  let queryKey;

  return useQuery(["lineItem", id], () => api.getLineItem(id), {
    enabled: Boolean(id),
    useErrorBoundary: (error) => !(error instanceof NotFoundException),
    initialData: () => {
      // to init data, we try to find the lineItem in one of the "lineItems" queries
      let found;

      queryClient.getQueriesData(["lineItems"]).forEach((query) => {
        const data = query[1]?.data;
        if (data) {
          data.forEach((lineItem) => {
            if (lineItem.id === id) {
              found = lineItem;
              queryKey = query[0];
            }
          });
        }
      });

      // otherwise, we try to find the lineItem in one of the "campaign.lineItems" queries

      if (!found) {
        queryClient.getQueriesData(["campaign.lineItems"]).forEach((query) => {
          const data = query[1]?.data;
          if (data) {
            data.forEach((lineItem) => {
              if (lineItem.id === id) {
                found = lineItem;
                queryKey = query[0];
              }
            });
          }
        });
      }

      if (!found) {
        // otherwise, we try to find the lineItem in one of the "offer.lineItems" queries

        queryClient.getQueriesData(["offer.lineItems"]).forEach((query) => {
          const data = query[1]?.data;
          if (data) {
            data.forEach((lineItem) => {
              if (lineItem.id === id) {
                found = lineItem;
                queryKey = query[0];
              }
            });
          }
        });
      }

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

export const useGetLineItemStatsByDay = (lineItemId) => {
  return useQuery(
    ["lineItem.statsByDay", lineItemId],
    () => api.getLineItemStatsByDay(lineItemId),
    {
      enabled: Boolean(lineItemId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

export const useGetLineItemStatsByAdSet = (lineItemId) => {
  return useQuery(
    ["lineItem.statsByAdSet", lineItemId],
    () => api.getLineItemStatsByAdSet(lineItemId),
    {
      enabled: Boolean(lineItemId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

export const useGetLineItemStatsByAd = (lineItemId) => {
  return useQuery(
    ["lineItem.statsByAd", lineItemId],
    () => api.getLineItemStatsByAd(lineItemId),
    {
      enabled: Boolean(lineItemId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

export const useGetLineItemStatsByDevice = (lineItemId) => {
  return useQuery(
    ["lineItem.statsByDevice", lineItemId],
    () => api.getLineItemStatsByDevice(lineItemId),
    {
      enabled: Boolean(lineItemId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

export const useGetLineItemStatsByDevicePlatform = (lineItemId) => {
  return useQuery(
    ["lineItem.statsByDevicePlatform", lineItemId],
    () => api.getLineItemStatsByDevicePlatform(lineItemId),
    {
      enabled: Boolean(lineItemId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

export const useGetLineItemStatsByPlatform = (lineItemId) => {
  return useQuery(
    ["lineItem.statsByPlatform", lineItemId],
    () => api.getLineItemStatsByPlatform(lineItemId),
    {
      enabled: Boolean(lineItemId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

export const useGetLineItemStatsByPlacement = (lineItemId) => {
  return useQuery(
    ["lineItem.statsByPlacement", lineItemId],
    () => api.getLineItemStatsByPlacement(lineItemId),
    {
      enabled: Boolean(lineItemId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

export const useGetLineItemStatsByAge = (lineItemId) => {
  return useQuery(
    ["lineItem.statsByAge", lineItemId],
    () => api.getLineItemStatsByAge(lineItemId),
    {
      enabled: Boolean(lineItemId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

export const useGetLineItemStatsByGender = (lineItemId) => {
  return useQuery(
    ["lineItem.statsByGender", lineItemId],
    () => api.getLineItemStatsByGender(lineItemId),
    {
      enabled: Boolean(lineItemId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

export const useGetLineItemDataCheck = (lineItemId) => {
  return useQuery(
    ["lineItem.dataCheck", lineItemId],
    () => api.getLineItemDataCheck(lineItemId),
    {
      enabled: Boolean(lineItemId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

import { ETLTaskBreadcrumb } from "../breadcrumbs/etlTask";
import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import { DATA } from "../constants";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";
import PermissionsGuard from "../components/routes/PermissionsGuard";

// Pages
const ETLTaskList = Loadable(
  lazy(() => import("../pages/etlTasks/ETLTaskList"))
);
const ETLTaskView = Loadable(
  lazy(() => import("../pages/etlTasks/ETLTaskView"))
);

// Direct parent pages
const ETLRunView = Loadable(lazy(() => import("../pages/etlRuns/ETLRunView")));

const etlTaskRoutes = (directParentType = "", grandParentTypes = []) => {
  const listComponent =
    directParentType === DATA.ETLRUN.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.ETLRUN.type, action: "READ" },
          { feature: DATA.ETLTASK.type, action: "READ" },
        ]}
      >
        <ETLRunView tab="etlTasks" />
      </PermissionsGuard>
    ) : (
      <PermissionsGuard
        neededPermissions={[{ feature: DATA.ETLTASK.type, action: "READ" }]}
      >
        <ETLTaskList />
      </PermissionsGuard>
    );

  const route = {
    path: "etlTasks",
    children: [
      {
        path: "",
        element: listComponent,
        breadcrumb: capitalizeFirstLetter(DATA.ETLTASK.readablePlural),
      },
      {
        path: ":etlTaskId",
        children: [
          {
            path: "",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ETLTASK.type, action: "READ" },
                ]}
              >
                <ETLTaskView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: ETLTaskBreadcrumb,
          },
          {
            path: "about",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ETLTASK.type, action: "READ" },
                ]}
              >
                <ETLTaskView
                  tab="about"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "About",
          },
        ],
      },
    ],
  };

  return route;
};

export default etlTaskRoutes;

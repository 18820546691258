import { PostBoostCampaignBreadcrumb } from "../breadcrumbs/postBoostCampaign";
import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import { DATA } from "../constants";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";
import PermissionsGuard from "../components/routes/PermissionsGuard";

// Pages
const PostBoostCampaignList = Loadable(
  lazy(() => import("../pages/postBoostCampaigns/PostBoostCampaignList"))
);
const PostBoostCampaignView = Loadable(
  lazy(() => import("../pages/postBoostCampaigns/PostBoostCampaignView"))
);

// Direct parent pages
const PostView = Loadable(lazy(() => import("../pages/posts/PostView")));
const AdAccountView = Loadable(
  lazy(() => import("../pages/adAccounts/AdAccountView"))
);

const postBoostCampaignRoutes = (
  directParentType = "",
  grandParentTypes = []
) => {
  const listComponent =
    directParentType === DATA.POST.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.POST.type, action: "READ" },
          { feature: DATA.POSTBOOSTCAMPAIGN.type, action: "READ" },
        ]}
      >
        <PostView tab="postBoostCampaigns" parentTypes={grandParentTypes} />
      </PermissionsGuard>
    ) : directParentType === DATA.ADACCOUNT.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.ADACCOUNT.type, action: "READ" },
          { feature: DATA.POSTBOOSTCAMPAIGN.type, action: "READ" },
        ]}
      >
        <AdAccountView
          tab="postBoostCampaigns"
          parentTypes={grandParentTypes}
        />
      </PermissionsGuard>
    ) : (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.POSTBOOSTCAMPAIGN.type, action: "READ" },
        ]}
      >
        <PostBoostCampaignList />
      </PermissionsGuard>
    );

  const route = {
    path: "postBoostCampaigns",
    children: [
      {
        path: "",
        element: listComponent,
        breadcrumb: capitalizeFirstLetter(
          DATA.POSTBOOSTCAMPAIGN.readablePlural
        ),
      },
      {
        path: ":postBoostCampaignId",
        children: [
          {
            path: "",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.POSTBOOSTCAMPAIGN.type, action: "READ" },
                ]}
              >
                <PostBoostCampaignView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: PostBoostCampaignBreadcrumb,
          },
          {
            path: "performance",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.POSTBOOSTCAMPAIGN.type, action: "READ" },
                ]}
              >
                <PostBoostCampaignView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                  tab="performance"
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Performance",
          },
        ],
      },
    ],
  };

  return route;
};

export default postBoostCampaignRoutes;

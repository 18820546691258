import { useState, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Login from '../../pages/authentication/Login';
import VerifyCode from '../../pages/authentication/VerifyEmail';
import { useSelector } from "react-redux";

const AuthGuard = ({ children }) => {

  // Hooks

  const { isAuthenticated, currentUser } = useSelector((state) => state.auth);
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  // Rendering

  if (!isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return <Login />;
  }

  if (!currentUser.hasVerifiedEmail) {
    return <VerifyCode />;
  }

  // The code below has been commented because it was creating 
  // errors in the navigation just after login

  // This is done so that in case the route changes by any chance through other 
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  // if (requestedLocation && location.pathname !== requestedLocation) {
  //   console.log("I think an error is happening here. Not sure if after login, of after client build. Should investigate")
  //   setRequestedLocation(null);
  //   navigate(requestedLocation);
  // }

  return <Fragment>{children}</Fragment>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;

import { useEffect, Fragment } from "react";
import { useRoutes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import "./translations/i18n";
import RTL from "./components/general/RTL";
import { gtmConfig } from "./gtmConfig";
import useScrollReset from "./hooks/useScrollReset";
import useSettings from "./hooks/useSettings";
import gtm from "./helpers/gtm";
import { routes } from "./routes";
import { createCustomTheme } from "./theme";

const App = () => {
  // Hooks

  const content = useRoutes(routes);

  const { settings } = useSettings();
  useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  // Handle Theme

  const theme = createCustomTheme({
    theme: settings.theme,
    direction: settings.direction,
    roundedCorners: settings.roundedCorners,
    responsiveFontSizes: settings.responsiveFontSizes,
  });

  // Rendering

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <RTL direction={settings.direction}>
          <CssBaseline />
          <Toaster position="top-center" />
          {content}
        </RTL>
      </ThemeProvider>
    </Fragment>
  );
};

export default App;

import * as Yup from "yup";
import { Formik } from "formik";
import { Box, FormHelperText, TextField, IconButton } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setLoggedIn } from "../../slices/auth";
import toast from "react-hot-toast";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LoadingButton from "../general/LoadingButton";
import { ValidationException } from "../../customExceptions";
import { useLogin } from "../../mutations/auth";

const LoginForm = (props) => {
  // Hooks

  const dispatch = useDispatch();

  // Mutations

  const { isLoading: isDoingLogin, mutate: mutateLogin } = useLogin();

  // Formik configuration to handle Login

  const initialValues = {
    email: "",
    password: "",
    showPassword: false,
    submit: null,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    password: Yup.string().max(255).required("Password is required"),
  });

  const onSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    mutateLogin(
      { email: values.email, password: values.password },
      {
        onError: (error) => {
          toast.error("Login failed");
          if (error instanceof ValidationException) {
            setStatus({ success: false });
            setErrors(error.validationErrors);
            setSubmitting(false);
          }
        },
        onSuccess: (result) => {
          toast.success(`Welcome back, ${result.user.firstName} !`);
          dispatch(setLoggedIn(result.user));
        },
      }
    );
  };

  // Rendering

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        dirty,
        isValid,
        setFieldValue,
      }) => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(value) => setFieldValue("showPassword", true)}
                    onMouseDown={(value) =>
                      setFieldValue("showPassword", false)
                    }
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <LoadingButton
              type="submit"
              color="primary"
              disabled={isSubmitting || !(dirty && isValid)}
              variant="contained"
              isLoading={isDoingLogin}
              loadingText="Login"
              fullWidth
              size="large"
              >Log in</LoadingButton>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;

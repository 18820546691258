import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import WebsiteLayout from "../components/layouts/website/WebsiteLayout";

// Pages
const Home = Loadable(lazy(() => import("../pages/website/Home")));
const NotFound = Loadable(lazy(() => import("../pages/NotFound")));

const webRoute = () => ({
  path: "*",
  element: <WebsiteLayout />,
  breadcrumb: null,
  children: [
    {
      path: "",
      element: <Home />,
      breadcrumb: null,
    },
    {
      path: "*",
      element: <NotFound origin="website" />,
      breadcrumb: null,
    },
  ],
});

export default webRoute;

import AppBreadcrumbs from "./AppBreadcrumbs";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import PlusIcon from "../../../icons/Plus";
import PencilAltIcon from "../../../icons/PencilAlt";
import ArrowLeftIcon from "../../../icons/ArrowLeft";
import useSettings from "../../../hooks/useSettings";
import Loader from "@material-ui/core/CircularProgress";

const ContentLayout = ({ children }) => {
  // Hooks

  const { settings } = useSettings();
  const { pageTitle, avatar, cancelButton, backButton, addButton, editButton } =
    useSelector((state) => state.appLayout);

  const hasButton = cancelButton || backButton || addButton || editButton;

  // Rendering

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        pt: 6,
      }}
    >
      <Container maxWidth={settings.compact ? "xl" : false}>
        {/* Page title */}
        <Grid container justifyContent="space-between" spacing={3} >
          <Grid item>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              {avatar && (
                <Avatar
                  src={avatar.url}
                  sx={{
                    height: 28,
                    width: 28,
                    mr: 1,
                  }}
                >
                  <Typography variant="h6">{avatar.name[0]}</Typography>
                </Avatar>
              )}
              <Typography color="textPrimary" variant="h5">
                {pageTitle === "Loading" ? <Loader color="primary" size={26} sx={{ mr: 1 }} /> : pageTitle}
              </Typography>
            </Box>
            {/* Breadcrumbs */}
            <AppBreadcrumbs />
          </Grid>
          {/* Top right buttons */}
          {hasButton && (
            <Grid item>
            <Box sx={{ m: -1 }}>
              {addButton && (
                <Button
                  color="primary"
                  component={RouterLink}
                  startIcon={<PlusIcon fontSize="small" />}
                  sx={{ m: 1 }}
                  to={addButton}
                  variant="contained"
                >
                  Create new
                </Button>
              )}
                {editButton && (
                  <Button
                    color="primary"
                    component={RouterLink}
                    startIcon={<PencilAltIcon fontSize="small" />}
                    sx={{ m: 1 }}
                    to={editButton}
                    variant="contained"
                  >
                    Edit
                  </Button>
                )}
                {backButton && (
                  <Button
                    color="primary"
                    component={RouterLink}
                    startIcon={<ArrowLeftIcon fontSize="small" />}
                    sx={{ m: 1 }}
                    to={backButton}
                    variant="outlined"
                  >
                    Back
                  </Button>
                )}
                {cancelButton && (
                  <Button
                    color="primary"
                    component={RouterLink}
                    startIcon={<ArrowLeftIcon fontSize="small" />}
                    sx={{ m: 1 }}
                    to={cancelButton}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
        {/* Route content (Outlet) */}
        {children}
      </Container>
    </Box>
  );
};

export default ContentLayout;

import { DataOriginBreadcrumb } from "../breadcrumbs/dataOrigin";
import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import { DATA } from "../constants";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";
import PermissionsGuard from "../components/routes/PermissionsGuard";

// Pages
const DataOriginList = Loadable(
  lazy(() => import("../pages/dataOrigins/DataOriginList"))
);
const DataOriginView = Loadable(
  lazy(() => import("../pages/dataOrigins/DataOriginView"))
);
const DataOriginEdit = Loadable(
  lazy(() => import("../pages/dataOrigins/DataOriginEdit"))
);

// Direct parent pages
const AccountView = Loadable(
  lazy(() => import("../pages/accounts/AccountView"))
);

const dataOriginRoutes = (directParentType = "", grandParentTypes = []) => {
  const listComponent =
    directParentType === DATA.ACCOUNT.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.ACCOUNT.type, action: "READ" },
          { feature: DATA.DATAORIGIN.type, action: "READ" },
        ]}
      >
        <AccountView tab="dataOrigins" parentTypes={grandParentTypes} />
      </PermissionsGuard>
    ) : (
      <PermissionsGuard
        neededPermissions={[{ feature: DATA.DATAORIGIN.type, action: "READ" }]}
      >
        <DataOriginList />
      </PermissionsGuard>
    );

  const route = {
    path: "dataOrigins",
    children: [
      {
        path: "",
        element: listComponent,
        breadcrumb: capitalizeFirstLetter(DATA.DATAORIGIN.readablePlural),
      },
      {
        path: ":dataOriginId",
        children: [
          {
            path: "",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.DATAORIGIN.type, action: "READ" },
                ]}
              >
                <DataOriginView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: DataOriginBreadcrumb,
          },
          {
            path: "edit",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.DATAORIGIN.type, action: "UPDATE" },
                ]}
              >
                <DataOriginEdit
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Edit",
          },
        ],
      },
    ],
  };

  return route;
};

export default dataOriginRoutes;

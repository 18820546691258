import * as api from "../apis/countriesApi";
import { NotFoundException } from "../customExceptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetCountries = () =>
  useQuery(["countries"], api.getCountries, {
    useErrorBoundary: true,
  });

export const useGetCountry = (id) => {
  const queryClient = useQueryClient();
  return useQuery(
    ["country", id],
    () => api.getCountry(id),
    {
      enabled: Boolean(id),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
      initialData: () =>
        queryClient
          .getQueryData(["countries"])
          ?.find((country) => country.id === id),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(["countries"])?.dataUpdatedAt,
    }
  );
};

import { AccountBreadcrumb } from "../breadcrumbs/account";
import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import { DATA } from "../constants";
import dataOriginRoutes from "./dataOrigin";
import postRoutes from "./post";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";
import PermissionsGuard from "../components/routes/PermissionsGuard";

// Pages
const AccountList = Loadable(
  lazy(() => import("../pages/accounts/AccountList"))
);
const AccountCreate = Loadable(
  lazy(() => import("../pages/accounts/AccountCreate"))
);
const AccountView = Loadable(
  lazy(() => import("../pages/accounts/AccountView"))
);
const AccountEdit = Loadable(
  lazy(() => import("../pages/accounts/AccountEdit"))
);

// Direct parent pages
const AdvertiserView = Loadable(
  lazy(() => import("../pages/advertisers/AdvertiserView"))
);
const AdvertiserAccountCreate = Loadable(
  lazy(() => import("../pages/advertisers/AdvertiserAccountCreate"))
);
const PlatformView = Loadable(
  lazy(() => import("../pages/platforms/PlatformView"))
);
const PlatformAccountCreate = Loadable(
  lazy(() => import("../pages/platforms/PlatformAccountCreate"))
);

const accountRoutes = (directParentType = "", grandParentTypes = []) => {
  const listComponent =
    directParentType === DATA.ADVERTISER.type ? (
      // Here we call the parent component. So we pass grand-parents as parents
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.ADVERTISER.type, action: "READ" },
          { feature: DATA.ACCOUNT.type, action: "READ" },
        ]}
      >
        <AdvertiserView tab="accounts" parentTypes={grandParentTypes} />
      </PermissionsGuard>
    ) : directParentType === DATA.PLATFORM.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.PLATFORM.type, action: "READ" },
          { feature: DATA.ACCOUNT.type, action: "READ" },
        ]}
      >
        <PlatformView tab="accounts" parentTypes={grandParentTypes} />
      </PermissionsGuard>
    ) : (
      <PermissionsGuard
        neededPermissions={[{ feature: DATA.ACCOUNT.type, action: "READ" }]}
      >
        <AccountList />
      </PermissionsGuard>
    );
  const createComponent =
    directParentType === DATA.ADVERTISER.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.ADVERTISER.type, action: "READ" },
          { feature: DATA.ACCOUNT.type, action: "CREATE" },
        ]}
      >
        <AdvertiserAccountCreate
          parentTypes={
            directParentType ? [...grandParentTypes, directParentType] : []
          }
        />
      </PermissionsGuard>
    ) : directParentType === DATA.PLATFORM.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.PLATFORM.type, action: "READ" },
          { feature: DATA.ACCOUNT.type, action: "CREATE" },
        ]}
      >
        <PlatformAccountCreate
          parentTypes={
            directParentType ? [...grandParentTypes, directParentType] : []
          }
        />
      </PermissionsGuard>
    ) : (
      <PermissionsGuard
        neededPermissions={[{ feature: DATA.ACCOUNT.type, action: "CREATE" }]}
      >
        <AccountCreate />
      </PermissionsGuard>
    );

  const route = {
    path: "accounts",
    children: [
      {
        path: "",
        element: listComponent,
        breadcrumb: capitalizeFirstLetter(DATA.ACCOUNT.readablePlural),
      },
      {
        path: "create",
        element: createComponent,
        breadcrumb: "Create new",
      },
      {
        path: ":accountId",
        children: [
          {
            path: "",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ACCOUNT.type, action: "READ" },
                ]}
              >
                <AccountView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: AccountBreadcrumb,
          },
          {
            path: "edit",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ACCOUNT.type, action: "UPDATE" },
                ]}
              >
                <AccountEdit
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Edit",
          },
          {
            path: "about",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ACCOUNT.type, action: "READ" },
                ]}
              >
                <AccountView
                  tab="about"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "About",
          },
          {
            path: "followers",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ACCOUNT.type, action: "READ" },
                ]}
              >
                <AccountView
                  tab="followers"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Followers",
          },
          {
            path: "reporting",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ACCOUNT.type, action: "READ" },
                ]}
              >
                <AccountView
                  tab="reporting"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Reporting",
          },
          {
            path: "presentation",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ACCOUNT.type, action: "READ" },
                ]}
              >
                <AccountView
                  tab="presentation"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Presentation",
          },
          {
            ...postRoutes(
              DATA.ACCOUNT.type,
              directParentType ? [...grandParentTypes, directParentType] : []
            ),
          },
          {
            ...dataOriginRoutes(
              DATA.ACCOUNT.type,
              directParentType ? [...grandParentTypes, directParentType] : []
            ),
          },
        ],
      },
    ],
  };

  return route;
};

export default accountRoutes;

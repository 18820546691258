import { MetricBreadcrumb } from "../breadcrumbs/metric";
import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import { DATA } from "../constants";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";
import PermissionsGuard from "../components/routes/PermissionsGuard";

// Pages
const MetricList = Loadable(lazy(() => import("../pages/metrics/MetricList")));
const MetricView = Loadable(lazy(() => import("../pages/metrics/MetricView")));
const MetricEdit = Loadable(lazy(() => import("../pages/metrics/MetricEdit")));
const MetricCreate = Loadable(
  lazy(() => import("../pages/metrics/MetricCreate"))
);

// Direct parent pages
const PlatformView = Loadable(
  lazy(() => import("../pages/platforms/PlatformView"))
);
const PlatformMetricCreate = Loadable(
  lazy(() => import("../pages/platforms/PlatformMetricCreate"))
);

const metricRoutes = (directParentType = "", grandParentTypes = []) => {
  const listComponent =
    directParentType === DATA.PLATFORM.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.PLATFORM.type, action: "READ" },
          { feature: DATA.METRIC.type, action: "READ" },
        ]}
      >
        <PlatformView tab="metrics" />
      </PermissionsGuard>
    ) : (
      <PermissionsGuard
        neededPermissions={[{ feature: DATA.METRIC.type, action: "READ" }]}
      >
        <MetricList />
      </PermissionsGuard>
    );
  const createComponent =
    directParentType === DATA.PLATFORM.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.PLATFORM.type, action: "READ" },
          { feature: DATA.METRIC.type, action: "CREATE" },
        ]}
      >
        <PlatformMetricCreate
          parentTypes={
            directParentType ? [...grandParentTypes, directParentType] : []
          }
        />
      </PermissionsGuard>
    ) : (
      <PermissionsGuard
        neededPermissions={[{ feature: DATA.METRIC.type, action: "CREATE" }]}
      >
        <MetricCreate />
      </PermissionsGuard>
    );

  const route = {
    path: "metrics",
    children: [
      {
        path: "",
        element: listComponent,
        breadcrumb: capitalizeFirstLetter(DATA.METRIC.readablePlural),
      },
      {
        path: "create",
        element: createComponent,
        breadcrumb: "Create new",
      },
      {
        path: ":metricId",
        children: [
          {
            path: "",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.METRIC.type, action: "READ" },
                ]}
              >
                <MetricView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: MetricBreadcrumb,
          },
          {
            path: "edit",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.METRIC.type, action: "UPDATE" },
                ]}
              >
                <MetricEdit
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Edit",
          },
          {
            path: "about",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.METRIC.type, action: "READ" },
                ]}
              >
                <MetricView
                  tab="about"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "About",
          },
        ],
      },
    ],
  };

  return route;
};

export default metricRoutes;

import * as api from "../apis/offersApi";
import { NotFoundException } from "../customExceptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetOffers = () => {
  return useQuery(["offers"], api.getOffers, {
    useErrorBoundary: true,
  });
};

export const useGetOffersByPlatform = (platformId) => {
  const queryClient = useQueryClient();

  return useQuery(
    ["platform.offers", platformId],
    () => api.getOffersByPlatform(platformId),
    {
      enabled: Boolean(platformId),
      useErrorBoundary: true,
      initialData: () =>
        queryClient
          .getQueryData(["offers"])
          ?.filter((offer) => offer.platform.id === platformId),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(["offers"])?.dataUpdatedAt,
    }
  );
};

export const useGetOffersByCountry = (countryId) => {
  const queryClient = useQueryClient();

  return useQuery(
    ["country.offers", countryId],
    () => api.getOffersByCountry(countryId),
    {
      enabled: Boolean(countryId),
      useErrorBoundary: true,
      initialData: () =>
        queryClient
          .getQueryData(["offers"])
          ?.filter((offer) => offer.country.id === countryId),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(["offers"])?.dataUpdatedAt,
    }
  );
};

export const useGetOffersByAdFormat = (adFormatId) => {
  const queryClient = useQueryClient();

  return useQuery(
    ["adFormat.offers", adFormatId],
    () => api.getOffersByAdFormat(adFormatId),
    {
      enabled: Boolean(adFormatId),
      useErrorBoundary: true,
      initialData: () =>
        queryClient
          .getQueryData(["offers"])
          ?.filter((offer) => offer.adFormat.id === adFormatId),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(["offers"])?.dataUpdatedAt,
    }
  );
};

export const useGetOffersBySpecTemplate = (specTemplateId) => {
  const queryClient = useQueryClient();

  return useQuery(
    ["specTemplate.offers", specTemplateId],
    () => api.getOffersBySpecTemplate(specTemplateId),
    {
      enabled: Boolean(specTemplateId),
      useErrorBoundary: true,
      initialData: () =>
        queryClient
          .getQueryData(["offers"])
          ?.filter((offer) => offer.specTemplate.id === specTemplateId),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(["offers"])?.dataUpdatedAt,
    }
  );
};

export const useGetOffersByAdOpsObjective = (adOpsObjectiveId) => {
  const queryClient = useQueryClient();

  return useQuery(
    ["adOpsObjective.offers", adOpsObjectiveId],
    () => api.getOffersByAdOpsObjective(adOpsObjectiveId),
    {
      enabled: Boolean(adOpsObjectiveId),
      useErrorBoundary: true,
      initialData: () =>
        queryClient
          .getQueryData(["offers"])
          ?.filter((offer) => offer.adOpsObjective.id === adOpsObjectiveId),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(["offers"])?.dataUpdatedAt,
    }
  );
};




export const useGetOffer = (id) => {
  const queryClient = useQueryClient();
  let queryKey;

  return useQuery(["offer", id], () => api.getOffer(id), {
    enabled: Boolean(id),
    useErrorBoundary: (error) => !(error instanceof NotFoundException),
    initialData: () => {
      // if "offers" query exist, we use it to init data
      if (queryClient.getQueryState(["offers"])) {
        queryKey = ["offers"];
        return queryClient
          .getQueryData(["offers"])
          ?.find((offer) => offer.id === id);
      }

      // otherwise, we try to find the offer in one of the "platform.offers" queries
      let found;

      queryClient.getQueriesData(["platform.offers"]).forEach((query) => {
        const data = query[1];
        if (data) {
          data.forEach((offer) => {
            if (offer.id === id) {
              found = offer;
              queryKey = query[0];
            }
          });
        }
      });

      if (!found) {
        // otherwise, we try to find the offer in one of the "country.offers" queries

        queryClient.getQueriesData(["country.offers"]).forEach((query) => {
          const data = query[1];
          if (data) {
            data.forEach((offer) => {
              if (offer.id === id) {
                found = offer;
                queryKey = query[0];
              }
            });
          }
        });
      }

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

export const useGetOfferPriceStats = (offerId) => {
  return useQuery(
    ["offer.priceStats", offerId],
    () => api.getOfferPriceStats(offerId),
    {
      enabled: Boolean(offerId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

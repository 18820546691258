import * as api from "../apis/adOpsObjectivesApi";
import { NotFoundException } from "../customExceptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetAdOpsObjectives = () =>
  useQuery(["adOpsObjectives"], api.getAdOpsObjectives, {
    useErrorBoundary: true,
  });

export const useGetAdOpsObjective = (id) => {
  const queryClient = useQueryClient();
  return useQuery(
    ["adOpsObjective", id],
    () => api.getAdOpsObjective(id),
    {
      enabled: Boolean(id),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
      initialData: () =>
        queryClient
          .getQueryData(["adOpsObjectives"])
          ?.find((adOpsObjective) => adOpsObjective.id === id),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(["adOpsObjectives"])?.dataUpdatedAt,
    }
  );
};

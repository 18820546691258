import * as api from "../apis/advertisersApi";
import { NotFoundException } from "../customExceptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetAdvertisers = () =>
  useQuery(["advertisers"], api.getAdvertisers, {
    useErrorBoundary: true,
  });

export const useGetAdvertiser = (id) => {
  const queryClient = useQueryClient();
  return useQuery(
    ["advertiser", id],
    () => api.getAdvertiser(id),
    {
      enabled: Boolean(id),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
      initialData: () =>
        queryClient
          .getQueryData(["advertisers"])
          ?.find((advertiser) => advertiser.id === id),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(["advertisers"])?.dataUpdatedAt,
    }
  );
};

import * as api from "../apis/usersApi";
import { NotFoundException } from "../customExceptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetUsers = () =>
  useQuery(["users"], api.getUsers, {
    useErrorBoundary: true,
  });

export const useGetUser = (id) => {
  const queryClient = useQueryClient();
  return useQuery(
    ["user", id],
    () => api.getUser(id),
    {
      enabled: Boolean(id),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
      initialData: () =>
        queryClient
          .getQueryData(["users"])
          ?.find((user) => user.id === id),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(["users"])?.dataUpdatedAt,
    }
  );
};

export const useGetUserPermissions = (id) => {
  return useQuery(
    ["userPermissions", id],
    () => api.getUserPermissions(id),
    {
      enabled: Boolean(id),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
      
    }
  );
};


import * as api from "../apis/campaignsApi";
import { NotFoundException } from "../customExceptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetCampaigns = () => {
  return useQuery(["campaigns"], api.getCampaigns, {
    useErrorBoundary: true,
  });
};

export const useGetCampaignsByAdvertiser = (advertiserId) => {
  const queryClient = useQueryClient();

  return useQuery(
    ["advertiser.campaigns", advertiserId],
    () => api.getCampaignsByAdvertiser(advertiserId),
    {
      enabled: Boolean(advertiserId),
      useErrorBoundary: true,
      initialData: () =>
        queryClient
          .getQueryData(["campaigns"])
          ?.filter((campaign) => campaign.advertiser.id === advertiserId),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(["campaigns"])?.dataUpdatedAt,
    }
  );
};

export const useGetCampaignsByBrand = (brandId) => {
  const queryClient = useQueryClient();

  return useQuery(
    ["brand.campaigns", brandId],
    () => api.getCampaignsByBrand(brandId),
    {
      enabled: Boolean(brandId),
      useErrorBoundary: true,
      initialData: () =>
        queryClient
          .getQueryData(["campaigns"])
          ?.filter((campaign) => campaign.brand?.id === brandId),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(["campaigns"])?.dataUpdatedAt,
    }
  );
};

export const useGetCampaign = (id) => {
  const queryClient = useQueryClient();
  let queryKey;

  return useQuery(["campaign", id], () => api.getCampaign(id), {
    enabled: Boolean(id),
    useErrorBoundary: (error) => !(error instanceof NotFoundException),
    initialData: () => {
      // if "campaigns" query exist, we use it to init data
      if (queryClient.getQueryState(["campaigns"])) {
        queryKey = ["campaigns"];
        return queryClient
          .getQueryData(["campaigns"])
          ?.find((campaign) => campaign.id === id);
      }

      // otherwise, we try to find the campaign in one of the "advertiser.campaigns" queries
      let found;

      queryClient.getQueriesData(["advertiser.campaigns"]).forEach((query) => {
        const data = query[1];
        data.forEach((campaign) => {
          if (campaign.id === id) {
            found = campaign;
            queryKey = query[0];
          }
        });
      });

      if (!found) {
        // otherwise, we try to find the campaign in one of the "brand.campaigns" queries

        queryClient.getQueriesData(["brand.campaigns"]).forEach((query) => {
          const data = query[1];
          data.forEach((campaign) => {
            if (campaign.id === id) {
              found = campaign;
              queryKey = query[0];
            }
          });
        });
      }

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

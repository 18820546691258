import fetchServer from "../helpers/api/fetchServer";
import fetchServerUpdateImage from "../helpers/api/fetchServerUpdateImage";

export const createAdvertiser = async ({ name, defaultMarginRate }) => {
  const method = "POST";
  const urlAPI = "/api/advertisers";
  const body = {
    name,
    defaultMarginRate,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const getAdvertisers = async () => {
  const method = "GET";
  const urlAPI = "/api/advertisers";
  return await fetchServer({ method, urlAPI });
};

export const getAdvertiser = async (id) => {
  const method = "GET";
  const urlAPI = `/api/advertisers/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const updateAdvertiser = async ({ id, name, defaultMarginRate }) => {
  const method = "PUT";
  const urlAPI = `/api/advertisers/${id}`;
  const body = { name, defaultMarginRate };
  return await fetchServer({ method, urlAPI, body });
};

export const updateAdvertiserCompany = async ({ id, name }) => {
  const method = "PUT";
  const urlAPI = `/api/advertisers/${id}/company`;
  const body = { name };
  return await fetchServer({ method, urlAPI, body });
};

export const updateAdvertiserMarginRate = async ({ id, defaultMarginRate }) => {
  const method = "PUT";
  const urlAPI = `/api/advertisers/${id}/defaultMarginRate`;
  const body = { defaultMarginRate };
  return await fetchServer({ method, urlAPI, body });
};

export const updateAdvertiserLogo = async ({ id, formData }) => {
  const urlAPI = `/api/advertisers/${id}/logo`;
  return await fetchServerUpdateImage({ urlAPI, formData });
};

export const deleteAdvertiserLogo = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/advertisers/${id}/logo`;
  return await fetchServer({ method, urlAPI });
};

export const deleteAdvertiser = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/advertisers/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const restoreAdvertiser = async (id) => {
  const method = "PUT";
  const urlAPI = `/api/advertisers/${id}/restore`;
  return await fetchServer({ method, urlAPI });
};

export const deleteAdvertisers = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/advertisers/delete-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};

export const restoreAdvertisers = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/advertisers/restore-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};

import { AdPlatformBreadcrumb } from "../breadcrumbs/adPlatform";
import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import { DATA } from "../constants";
import adAccountRoutes from "./adAccount";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";
import PermissionsGuard from "../components/routes/PermissionsGuard";

// Pages
const AdPlatformList = Loadable(
  lazy(() => import("../pages/adPlatforms/AdPlatformList"))
);
const AdPlatformView = Loadable(
  lazy(() => import("../pages/adPlatforms/AdPlatformView"))
);

const adPlatformRoutes = (directParentType = "", grandParentTypes = []) => {
  // there is no parent for adPlatform
  const listComponent = (
    <PermissionsGuard
    neededPermissions={[
      { feature: DATA.ADPLATFORM.type, action: "READ" },
    ]}
    >
      <AdPlatformList />
    </PermissionsGuard>
  );

  const route = {
    path: "adPlatforms",
    children: [
      {
        path: "",
        element: listComponent,
        breadcrumb: capitalizeFirstLetter(DATA.ADPLATFORM.readablePlural),
      },
      {
        path: ":adPlatformId",
        children: [
          {
            path: "",
            element: (
              <PermissionsGuard
              neededPermissions={[
                { feature: DATA.ADPLATFORM.type, action: "READ" },
              ]}
              >
                <AdPlatformView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: AdPlatformBreadcrumb,
          },
          {
            path: "about",
            element: (
              <PermissionsGuard
              neededPermissions={[
                { feature: DATA.ADPLATFORM.type, action: "READ" },
              ]}
              >
                <AdPlatformView
                  tab="about"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "About",
          },
          {
            ...adAccountRoutes(
              DATA.ADPLATFORM.type,
              directParentType ? [...grandParentTypes, directParentType] : []
            ),
          },
        ],
      },
    ],
  };

  return route;
};

export default adPlatformRoutes;

import { createSlice } from "@reduxjs/toolkit";

const initialPagination = {
  page: 1,
  nbDocsPerPage: 25,
};

// INITIAL STATE
const initialState = {
  etlTasks: initialPagination,
  etlRuns: [],
};

const etlTaskPaginationSlice = createSlice({
  name: "etlTaskPagination",
  initialState,
  reducers: {
    setPage: (state, action) => {
      switch (action.payload.module) {
        case "etlTasks":
          state.etlTasks.page = action.payload.page;
          break;
        case "etlRuns":
          state.etlRuns = state.etlRuns.map((etlRun) => {
            if (etlRun.id === action.payload.id) {
              const updatedPagination = {
                ...etlRun.pagination,
                page: action.payload.page,
              };
              return { ...etlRun, pagination: updatedPagination };
            } else {
              return etlRun;
            }
          });
          break;

        default:
          break;
      }
    },
    setNbDocsPerPage: (state, action) => {
      switch (action.payload.module) {
        case "etlTasks":
          state.etlTasks.nbDocsPerPage = action.payload.nbDocsPerPage;
          break;
        case "etlRuns":
          state.etlRuns = state.etlRuns.map((etlRun) => {
            if (etlRun.id === action.payload.id) {
              const updatedPagination = {
                ...etlRun.pagination,
                nbDocsPerPage: action.payload.nbDocsPerPage,
              };
              return { ...etlRun, pagination: updatedPagination };
            } else {
              return etlRun;
            }
          });
          break;
        default:
          break;
      }
    },
    initPaginationSet: (state, action) => {
      switch (action.payload.module) {
        case "etlTasks":
          state.etlTasks = initialPagination;
          break;
        case "etlRuns":
          if (
            !state.etlRuns.find((etlRun) => etlRun.id === action.payload.id)
          ) {
            state.etlRuns.push({
              id: action.payload.id,
              pagination: initialPagination,
            });
          }
          break;
        default:
          break;
      }
    },
  },
});

export const { setPage, setNbDocsPerPage, initPaginationSet } =
  etlTaskPaginationSlice.actions;
export const etlTaskPaginationReducer = etlTaskPaginationSlice.reducer;

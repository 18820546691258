import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import AuthProvider from "../components/routes/AuthProvider";
import GuestGuard from "../components/routes/GuestGuard";
import UserEmailVerifiedGuard from "../components/routes/UserEmailVerifiedGuard";

// Pages
const Login = Loadable(lazy(() => import("../pages/authentication/Login")));
const ForgotPassword = Loadable(
  lazy(() => import("../pages/authentication/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("../pages/authentication/ResetPassword"))
);
const SignUp = Loadable(lazy(() => import("../pages/authentication/SignUp")));
const VerifyEmail = Loadable(
  lazy(() => import("../pages/authentication/VerifyEmail"))
);

const authRoute = () => ({
  path: "authentication",
  breadcrumb: null,
  children: [
    {
      path: "login",
      element: (
        <AuthProvider>
          <GuestGuard>
            <Login />
          </GuestGuard>
        </AuthProvider>
      ),
      breadcrumb: null,
    },
    {
      path: "forgot-password",
      element: <ForgotPassword />,
      breadcrumb: null,
    },
    {
      path: "reset-password",
      element: <ResetPassword />,
      breadcrumb: null,
    },
    {
      path: "signup",
      element: (
        <AuthProvider>
          <GuestGuard>
            <SignUp />
          </GuestGuard>
        </AuthProvider>
      ),
      breadcrumb: null,
    },
    {
      path: "verify-email",
      element: (
        <AuthProvider>
          <UserEmailVerifiedGuard>
            <VerifyEmail />
          </UserEmailVerifiedGuard>
        </AuthProvider>
      ),
      breadcrumb: null,
    },
  ],
});

export default authRoute;

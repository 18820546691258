import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import { useGetPlatform } from "../queries/platforms";

export const PlatformBreadcrumb = ({ match }) => {
  const {
    data: platform,
    isLoading,
    isError,
    error,
  } = useGetPlatform(match.params.platformId);

  if (isLoading) {
    //return <span>Loading...</span>;
    return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
  }

  if (isError && error instanceof NotFoundException) {
    return <span>Not found</span>;
  }

  if (platform) {
    return <span>{platform.name}</span>;
  }

  return null;
};

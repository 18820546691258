import { createSlice } from "@reduxjs/toolkit";

// INITIAL STATE
const initialState = {
  tab: "all",
  text: "",
  sortBy: "startDate-desc",
};

const etlRunFiltersSlice = createSlice({
  name: "etlRunFilters",
  initialState,
  reducers: {
    setTabFilter: (state, action) => {
      state.tab = action.payload.tab;
    },
    setTextFilter: (state, action) => {
      state.text = action.payload.text;
    },
    sortByNameAsc: (state, action) => {
      state.sortBy = "name-asc";
    },
    sortByNameDesc: (state, action) => {
      state.sortBy = "name-desc";
    },
    sortByStartDateAsc: (state, action) => {
      state.sortBy = "startDate-asc";
    },
    sortByStartDateDesc: (state, action) => {
      state.sortBy = "startDate-desc";
    },
  },
});

export const {
  setTabFilter,
  setTextFilter,
  sortByNameAsc,
  sortByNameDesc,
  sortByStartDateAsc,
  sortByStartDateDesc,
} = etlRunFiltersSlice.actions;
export const etlRunFiltersReducer = etlRunFiltersSlice.reducer;

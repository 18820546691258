import * as api from "../apis/postBoostCampaignsApi";
import { NotFoundException } from "../customExceptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetPostBoostCampaigns = ({
  filterText,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  return useQuery(
    [
      "postBoostCampaigns",
      filterText,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    () =>
      api.getPostBoostCampaigns({
        filterText,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    {
      useErrorBoundary: true,
      keepPreviousData: true,
    }
  );
};

export const useGetPostBoostCampaignsByPost = ({
  postId,
  filterText,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  return useQuery(
    [
      "post.postBoostCampaigns",
      postId,
      filterText,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    () =>
      api.getPostBoostCampaignsByPost({
        postId,
        filterText,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    {
      enabled: Boolean(postId),
      useErrorBoundary: true,
      keepPreviousData: true,
    }
  );
};

export const useGetPostBoostCampaign = (id) => {
  const queryClient = useQueryClient();
  let queryKey;

  return useQuery(
    ["postBoostCampaign", id],
    () => api.getPostBoostCampaign(id),
    {
      enabled: Boolean(id),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
      initialData: () => {
        // to init data, we try to find the postBoostCampaign in one of the "postBoostCampaigns" queries
        let found;

        queryClient
          .getQueriesData(["postBoostCampaigns"])
          .forEach((query) => {
            const data = query[1]?.data;
            if (data) {
              data.forEach((postBoostCampaign) => {
                if (postBoostCampaign.id === id) {
                  found = postBoostCampaign;
                  queryKey = query[0];
                }
              });
            }
          });

        // otherwise, we try to find the postBoostCampaign in one of the "post.postBoostCampaigns" queries

        if (!found) {
          queryClient
            .getQueriesData(["post.postBoostCampaigns"])
            .forEach((query) => {
              const data = query[1]?.data;
              if (data) {
                data.forEach((postBoostCampaign) => {
                  if (postBoostCampaign.id === id) {
                    found = postBoostCampaign;
                    queryKey = query[0];
                  }
                });
              }
            });
        }

        return found;
      },
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(queryKey)?.dataUpdatedAt,
    }
  );
};

export const useGetPostBoostCampaignStatsByDay = (
  postBoostCampaignId
) => {
  return useQuery(
    ["postBoostCampaign.statsByDay", postBoostCampaignId],
    () =>
      api.getPostBoostCampaignStatsByDay(postBoostCampaignId),
    {
      enabled: Boolean(postBoostCampaignId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

import { AdOpsCampaignBreadcrumb } from "../breadcrumbs/adOpsCampaign";
import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import { DATA } from "../constants";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";
import PermissionsGuard from "../components/routes/PermissionsGuard";

// Pages
const AdOpsCampaignList = Loadable(
  lazy(() => import("../pages/adOpsCampaigns/AdOpsCampaignList"))
);
const AdOpsCampaignView = Loadable(
  lazy(() => import("../pages/adOpsCampaigns/AdOpsCampaignView"))
);

// Direct parent pages
const LineItemView = Loadable(
  lazy(() => import("../pages/lineItems/LineItemView"))
);
const AdAccountView = Loadable(
  lazy(() => import("../pages/adAccounts/AdAccountView"))
);

const adOpsCampaignRoutes = (directParentType = "", grandParentTypes = []) => {
  const listComponent =
    directParentType === DATA.LINEITEM.type ? (
      // Here we call the parent component. So we pass grand-parents as parents
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.LINEITEM.type, action: "READ" },
          { feature: DATA.ADOPSCAMPAIGN.type, action: "READ" },
        ]}
      >
        <LineItemView tab="adOpsCampaigns" parentTypes={grandParentTypes} />
      </PermissionsGuard>
    ) : directParentType === DATA.ADACCOUNT.type ? (
      // Here we call the parent component. So we pass grand-parents as parents
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.ADACCOUNT.type, action: "READ" },
          { feature: DATA.ADOPSCAMPAIGN.type, action: "READ" },
        ]}
      >
        <AdAccountView tab="adOpsCampaigns" parentTypes={grandParentTypes} />
      </PermissionsGuard>
    ) : (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.ADOPSCAMPAIGN.type, action: "READ" },
        ]}
      >
        <AdOpsCampaignList />
      </PermissionsGuard>
    );

  const route = {
    path: "adOpsCampaigns",
    children: [
      {
        path: "",
        element: listComponent,
        breadcrumb: capitalizeFirstLetter(DATA.ADOPSCAMPAIGN.readablePlural),
      },
      {
        path: ":adOpsCampaignId",
        children: [
          {
            path: "",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ADOPSCAMPAIGN.type, action: "READ" },
                ]}
              >
                <AdOpsCampaignView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: AdOpsCampaignBreadcrumb,
          },
          {
            path: "performance",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ADOPSCAMPAIGN.type, action: "READ" },
                ]}
              >
                <AdOpsCampaignView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                  tab="performance"
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Performance",
          },
          {
            path: "dataCheck",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.ADOPSCAMPAIGN.type, action: "READ" },
                ]}
              >
                <AdOpsCampaignView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                  tab="dataCheck"
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Data check",
          },
        ],
      },
    ],
  };

  return route;
};

export default adOpsCampaignRoutes;

import { createSlice } from "@reduxjs/toolkit";

// INITIAL STATE
const initialState = {
  tab: "all",
  text: "",
  sortBy: "name-asc"
};

const platformFiltersSlice = createSlice({
  name: "platformFilters",
  initialState,
  reducers: {
    setTabFilter: (state, action) => {
      state.tab = action.payload.tab;
    },
    setTextFilter: (state, action) => {
      state.text = action.payload.text;
    },
    sortByNameAsc: (state, action) => {
      state.sortBy = "name-asc";
    },
    sortByNameDesc: (state, action) => {
      state.sortBy = "name-desc";
    }
  },
});

export const { setTabFilter, setTextFilter, sortByNameAsc, sortByNameDesc } = platformFiltersSlice.actions;
export const platformFiltersReducer = platformFiltersSlice.reducer;

import { SpecTemplateBreadcrumb } from "../breadcrumbs/specTemplate";
import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import { DATA } from "../constants";
import offerRoutes from "./offer";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";
import PermissionsGuard from "../components/routes/PermissionsGuard";

const SpecTemplateList = Loadable(
  lazy(() => import("../pages/specTemplates/SpecTemplateList"))
);
const SpecTemplateCreate = Loadable(
  lazy(() => import("../pages/specTemplates/SpecTemplateCreate"))
);
const SpecTemplateView = Loadable(
  lazy(() => import("../pages/specTemplates/SpecTemplateView"))
);
const SpecTemplateEdit = Loadable(
  lazy(() => import("../pages/specTemplates/SpecTemplateEdit"))
);

const specTemplateRoutes = (directParentType = "", grandParentTypes = []) => {
  // there is no parent for specTemplate
  const listComponent = (
    <PermissionsGuard
      neededPermissions={[{ feature: DATA.SPECTEMPLATE.type, action: "READ" }]}
    >
      <SpecTemplateList />
    </PermissionsGuard>
  );
  const createComponent = (
    <PermissionsGuard
      neededPermissions={[
        { feature: DATA.SPECTEMPLATE.type, action: "CREATE" },
      ]}
    >
      <SpecTemplateCreate />
    </PermissionsGuard>
  );

  const route = {
    path: "specTemplates",
    children: [
      {
        path: "",
        element: listComponent,
        breadcrumb: capitalizeFirstLetter(DATA.SPECTEMPLATE.readablePlural),
      },
      {
        path: "create",
        element: createComponent,
        breadcrumb: "Create new",
      },
      {
        path: ":specTemplateId",
        children: [
          {
            path: "",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.SPECTEMPLATE.type, action: "READ" },
                ]}
              >
                <SpecTemplateView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: SpecTemplateBreadcrumb,
          },
          {
            path: "edit",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.SPECTEMPLATE.type, action: "UPDATE" },
                ]}
              >
                <SpecTemplateEdit
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Edit",
          },
          {
            path: "about",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.SPECTEMPLATE.type, action: "READ" },
                ]}
              >
                <SpecTemplateView
                  tab="about"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "About",
          },
          {
            ...offerRoutes(
              DATA.SPECTEMPLATE.type,
              directParentType ? [...grandParentTypes, directParentType] : []
            ),
          },
        ],
      },
    ],
  };

  return route;
};

export default specTemplateRoutes;

import * as api from "../apis/postsApi";
import { NotFoundException } from "../customExceptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useGetPosts = ({
  filterFormatPost,
  filterText,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  return useQuery(
    [
      "posts",
      filterFormatPost,
      filterText,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    () =>
      api.getPosts({
        filterFormatPost,
        filterText,
        filterStartDate,
        filterEndDate,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    {
      useErrorBoundary: true,
      keepPreviousData: true,
    }
  );
};

export const useGetPostsByAccount = ({
  accountId,
  filterFormatPost,
  filterText,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  return useQuery(
    [
      "account.posts",
      accountId,
      filterFormatPost,
      filterText,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    () =>
      api.getPostsByAccount({
        accountId,
        filterFormatPost,
        filterText,
        filterStartDate,
        filterEndDate,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    {
      enabled: Boolean(accountId),
      useErrorBoundary: true,
      keepPreviousData: true,
    }
  );
};

export const useGetPostsByBrand = ({
  brandId,
  filterFormatPost,
  filterText,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  return useQuery(
    [
      "brand.posts",
      brandId,
      filterFormatPost,
      filterText,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    () =>
      api.getPostsByBrand({
        brandId,
        filterFormatPost,
        filterText,
        filterStartDate,
        filterEndDate,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    {
      enabled: Boolean(brandId),
      useErrorBoundary: true,
      keepPreviousData: true,
    }
  );
};

export const useGetPostsByProblemType = ({
  problemTypeId,
  filterFormatPost,
  filterText,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  return useQuery(
    [
      "problemType.posts",
      problemTypeId,
      filterFormatPost,
      filterText,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    () =>
      api.getPostsByProblemType({
        problemTypeId,
        filterFormatPost,
        filterText,
        filterStartDate,
        filterEndDate,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    {
      enabled: Boolean(problemTypeId),
      useErrorBoundary: true,
      keepPreviousData: true,
    }
  );
};

export const useGetPost = (id) => {
  const queryClient = useQueryClient();
  let queryKey;

  return useQuery(["post", id], () => api.getPost(id), {
    enabled: Boolean(id),
    useErrorBoundary: (error) => !(error instanceof NotFoundException),
    initialData: () => {
      // to init data, we try to find the post in one of the "posts" queries
      let found;

      queryClient.getQueriesData(["posts"]).forEach((query) => {
        const data = query[1]?.data;
        if (data) {
          data.forEach((post) => {
            if (post.id === id) {
              found = post;
              queryKey = query[0];
            }
          });
        }
      });

      // otherwise, we try to find the post in one of the "account.posts" queries

      if (!found) {
        queryClient.getQueriesData(["account.posts"]).forEach((query) => {
          const data = query[1]?.data;
          if (data) {
            data.forEach((post) => {
              if (post.id === id) {
                found = post;
                queryKey = query[0];
              }
            });
          }
        });
      }

      if (!found) {
        // otherwise, we try to find the post in one of the "brand.posts" queries

        queryClient.getQueriesData(["brand.posts"]).forEach((query) => {
          const data = query[1]?.data;
          if (data) {
            data.forEach((post) => {
              if (post.id === id) {
                found = post;
                queryKey = query[0];
              }
            });
          }
        });
      }

      if (!found) {
        // otherwise, we try to find the post in one of the "dataOrigin.posts" queries

        queryClient.getQueriesData(["dataOrigin.posts"]).forEach((query) => {
          const data = query[1]?.data;
          if (data) {
            data.forEach((post) => {
              if (post.id === id) {
                found = post;
                queryKey = query[0];
              }
            });
          }
        });
      }

      if (!found) {
        // otherwise, we try to find the post in one of the "problemType.posts" queries

        queryClient.getQueriesData(["problemType.posts"]).forEach((query) => {
          const data = query[1]?.data;
          if (data) {
            data.forEach((post) => {
              if (post.id === id) {
                found = post;
                queryKey = query[0];
              }
            });
          }
        });
      }

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

export const useGetPostPaidStatsByDay = (postId) => {
  return useQuery(
    ["post.paidStatsByDay", postId],
    () => api.getPostPaidStatsByDay(postId),
    {
      enabled: Boolean(postId),
      useErrorBoundary: (error) => !(error instanceof NotFoundException),
    }
  );
};

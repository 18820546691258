import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import PermissionsGuard from "../components/routes/PermissionsGuard";

// Pages
const PostBoostDoctor = Loadable(
  lazy(() => import("../pages/postBoostDoctor/PostBoostDoctor"))
);

const postBoostDoctorRoute = () => ({
  path: "postBoostDoctor",
  element: (
    <PermissionsGuard
      neededPermissions={[{ feature: "postBoostDoctor", action: "READ" }]}
    >
      <PostBoostDoctor />
    </PermissionsGuard>
  ),
  breadcrumb: "Doctor (Post boost)",
});

export default postBoostDoctorRoute;

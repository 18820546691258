import { CampaignBreadcrumb } from "../breadcrumbs/campaign";
import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import { DATA } from "../constants";
import lineItemRoutes from "./lineItem";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";
import PermissionsGuard from "../components/routes/PermissionsGuard";

// Pages
const CampaignList = Loadable(
  lazy(() => import("../pages/campaigns/CampaignList"))
);
const CampaignCreate = Loadable(
  lazy(() => import("../pages/campaigns/CampaignCreate"))
);
const CampaignView = Loadable(
  lazy(() => import("../pages/campaigns/CampaignView"))
);
const CampaignEdit = Loadable(
  lazy(() => import("../pages/campaigns/CampaignEdit"))
);

// Direct parent pages
const AdvertiserView = Loadable(
  lazy(() => import("../pages/advertisers/AdvertiserView"))
);
const AdvertiserCampaignCreate = Loadable(
  lazy(() => import("../pages/advertisers/AdvertiserCampaignCreate"))
);
const BrandView = Loadable(lazy(() => import("../pages/brands/BrandView")));
const BrandCampaignCreate = Loadable(
  lazy(() => import("../pages/brands/BrandCampaignCreate"))
);

const campaignRoute = (directParentType = "", grandParentTypes = []) => {
  const listComponent =
    directParentType === DATA.ADVERTISER.type ? (
      // Here we call the parent component. So we pass grand-parents as parents
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.ADVERTISER.type, action: "READ" },
          { feature: DATA.CAMPAIGN.type, action: "READ" },
        ]}
      >
        <AdvertiserView tab="campaigns" parentTypes={grandParentTypes} />
      </PermissionsGuard>
    ) : directParentType === DATA.BRAND.type ? (
      // Here we call the parent component. So we pass grand-parents as parents
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.BRAND.type, action: "READ" },
          { feature: DATA.CAMPAIGN.type, action: "READ" },
        ]}
      >
        <BrandView tab="campaigns" parentTypes={grandParentTypes} />
      </PermissionsGuard>
    ) : (
      <PermissionsGuard
        neededPermissions={[{ feature: DATA.CAMPAIGN.type, action: "READ" }]}
      >
        <CampaignList />
      </PermissionsGuard>
    );
  const createComponent =
    directParentType === DATA.ADVERTISER.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.ADVERTISER.type, action: "READ" },
          { feature: DATA.CAMPAIGN.type, action: "CREATE" },
        ]}
      >
        <AdvertiserCampaignCreate
          parentTypes={
            directParentType ? [...grandParentTypes, directParentType] : []
          }
        />
      </PermissionsGuard>
    ) : directParentType === DATA.BRAND.type ? (
      <PermissionsGuard
        neededPermissions={[
          { feature: DATA.BRAND.type, action: "READ" },
          { feature: DATA.CAMPAIGN.type, action: "CREATE" },
        ]}
      >
        <BrandCampaignCreate
          parentTypes={
            directParentType ? [...grandParentTypes, directParentType] : []
          }
        />
      </PermissionsGuard>
    ) : (
      <PermissionsGuard
        neededPermissions={[{ feature: DATA.CAMPAIGN.type, action: "CREATE" }]}
      >
        <CampaignCreate />
      </PermissionsGuard>
    );

  const route = {
    path: "campaigns",
    children: [
      {
        path: "",
        element: listComponent,
        breadcrumb: capitalizeFirstLetter(DATA.CAMPAIGN.readablePlural),
      },
      {
        path: "create",
        element: createComponent,
        breadcrumb: "Create new",
      },
      {
        path: ":campaignId",
        children: [
          {
            path: "",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.CAMPAIGN.type, action: "READ" },
                ]}
              >
                <CampaignView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: CampaignBreadcrumb,
          },
          {
            path: "edit",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.CAMPAIGN.type, action: "UPDATE" },
                ]}
              >
                <CampaignEdit
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Edit",
          },
          {
            path: "about",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.CAMPAIGN.type, action: "READ" },
                ]}
              >
                <CampaignView
                  tab="about"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "About",
          },
          {
            path: "structure",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.CAMPAIGN.type, action: "READ" },
                ]}
              >
                <CampaignView
                  tab="structure"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Structure",
          },
          {
            path: "workflow",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.CAMPAIGN.type, action: "READ" },
                ]}
              >
                <CampaignView
                  tab="workflow"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Workflow",
          },
          {
            ...lineItemRoutes(
              DATA.CAMPAIGN.type,
              directParentType ? [...grandParentTypes, directParentType] : []
            ),
          },
        ],
      },
    ],
  };

  return route;
};

export default campaignRoute;

import { CountryBreadcrumb } from "../breadcrumbs/country";
import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import { DATA } from "../constants";
import offerRoutes from "./offer";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";
import PermissionsGuard from "../components/routes/PermissionsGuard";

const CountryList = Loadable(
  lazy(() => import("../pages/countries/CountryList"))
);
const CountryCreate = Loadable(
  lazy(() => import("../pages/countries/CountryCreate"))
);
const CountryView = Loadable(
  lazy(() => import("../pages/countries/CountryView"))
);
const CountryEdit = Loadable(
  lazy(() => import("../pages/countries/CountryEdit"))
);

const countryRoutes = (directParentType = "", grandParentTypes = []) => {
  // there is no parent for country
  const listComponent = (
    <PermissionsGuard
      neededPermissions={[{ feature: DATA.COUNTRY.type, action: "READ" }]}
    >
      <CountryList />
    </PermissionsGuard>
  );
  const createComponent = (
    <PermissionsGuard
      neededPermissions={[{ feature: DATA.COUNTRY.type, action: "CREATE" }]}
    >
      <CountryCreate />
    </PermissionsGuard>
  );

  const route = {
    path: "countries",
    children: [
      {
        path: "",
        element: listComponent,
        breadcrumb: capitalizeFirstLetter(DATA.COUNTRY.readablePlural),
      },
      {
        path: "create",
        element: createComponent,
        breadcrumb: "Create new",
      },
      {
        path: ":countryId",
        children: [
          {
            path: "",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.COUNTRY.type, action: "READ" },
                ]}
              >
                <CountryView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: CountryBreadcrumb,
          },
          {
            path: "edit",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.COUNTRY.type, action: "UPDATE" },
                ]}
              >
                <CountryEdit
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Edit",
          },
          {
            path: "about",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.COUNTRY.type, action: "READ" },
                ]}
              >
                <CountryView
                  tab="about"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "About",
          },
          {
            ...offerRoutes(
              DATA.COUNTRY.type,
              directParentType ? [...grandParentTypes, directParentType] : []
            ),
          },
        ],
      },
    ],
  };

  return route;
};

export default countryRoutes;

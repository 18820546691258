import { Navigate } from "react-router-dom";
import { Fragment } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const GuestGuard = ({ children }) => {
  // Hooks

  const { isAuthenticated, currentUser } = useSelector((state) => state.auth);

  // Rendering

  if (isAuthenticated) {
    if (!currentUser.hasVerifiedEmail) {
      return <Navigate to="/authentication/verify-email" />;
    }
    return <Navigate to="/app/" />;
  }

  return <Fragment>{children}</Fragment>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;

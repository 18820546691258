import { PlatformBreadcrumb } from "../breadcrumbs/platform";
import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import { DATA } from "../constants";
import accountRoutes from "./account";
import problemTypesRoutes from "./problemType";
import metricRoutes from "./metric";
import offerRoutes from "./offer";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";
import PermissionsGuard from "../components/routes/PermissionsGuard";

// Pages
const PlatformList = Loadable(
  lazy(() => import("../pages/platforms/PlatformList"))
);
const PlatformCreate = Loadable(
  lazy(() => import("../pages/platforms/PlatformCreate"))
);
const PlatformView = Loadable(
  lazy(() => import("../pages/platforms/PlatformView"))
);
const PlatformEdit = Loadable(
  lazy(() => import("../pages/platforms/PlatformEdit"))
);

const platformRoutes = (directParentType = "", grandParentTypes = []) => {
  // there is no parent for platform
  const listComponent = (
    <PermissionsGuard
      neededPermissions={[{ feature: DATA.PLATFORM.type, action: "READ" }]}
    >
      <PlatformList />
    </PermissionsGuard>
  );
  const createComponent = (
    <PermissionsGuard
      neededPermissions={[{ feature: DATA.PLATFORM.type, action: "CREATE" }]}
    >
      <PlatformCreate />
    </PermissionsGuard>
  );

  const route = {
    path: "platforms",
    children: [
      {
        path: "",
        element: listComponent,
        breadcrumb: capitalizeFirstLetter(DATA.PLATFORM.readablePlural),
      },
      {
        path: "create",
        element: createComponent,
        breadcrumb: "Create new",
      },
      {
        path: ":platformId",
        children: [
          {
            path: "",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.PLATFORM.type, action: "READ" },
                ]}
              >
                <PlatformView
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: PlatformBreadcrumb,
          },
          {
            path: "edit",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.PLATFORM.type, action: "UPDATE" },
                ]}
              >
                <PlatformEdit
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "Edit",
          },
          {
            path: "about",
            element: (
              <PermissionsGuard
                neededPermissions={[
                  { feature: DATA.PLATFORM.type, action: "READ" },
                ]}
              >
                <PlatformView
                  tab="about"
                  parentTypes={
                    directParentType
                      ? [...grandParentTypes, directParentType]
                      : []
                  }
                />
              </PermissionsGuard>
            ),
            breadcrumb: "About",
          },
          {
            ...metricRoutes(
              DATA.PLATFORM.type,
              directParentType ? [...grandParentTypes, directParentType] : []
            ),
          },
          {
            ...problemTypesRoutes(
              DATA.PLATFORM.type,
              directParentType ? [...grandParentTypes, directParentType] : []
            ),
          },
          {
            ...accountRoutes(
              DATA.PLATFORM.type,
              directParentType ? [...grandParentTypes, directParentType] : []
            ),
          },
          {
            ...offerRoutes(
              DATA.PLATFORM.type,
              directParentType ? [...grandParentTypes, directParentType] : []
            ),
          },
        ],
      },
    ],
  };

  return route;
};

export default platformRoutes;

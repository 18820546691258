import Loadable from "../components/general/Loadable";
import { lazy } from "react";
import PermissionsGuard from "../components/routes/PermissionsGuard";

// Pages
const CampaignAgenda = Loadable(
  lazy(() => import("../pages/campaignAgenda/CampaignAgenda"))
);

const campaignAgendaRoute = () => ({
  path: "campaignAgenda",
  element: (
    <PermissionsGuard
      neededPermissions={[{ feature: "campaignAgenda", action: "READ" }]}
    >
      <CampaignAgenda />
    </PermissionsGuard>
  ),
  breadcrumb: "Campaigns Agenda",
});

export default campaignAgendaRoute;

import { useEffect, Fragment } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Box, Button, Container, Link, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import gtm from "../../helpers/gtm";

const UnauthorizedError = ({ resetErrorBoundary }) => {

  // Hooks

  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  // Google Tag Manager

useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  // Handle Go Home

  const handleGoHome = () => {
    resetErrorBoundary();
    navigate("/app");
  };

  // Rendering

  return (
    <Fragment>
      <Helmet>
        <title>Lean ads | Authorization Required</title>
      </Helmet>
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "background.default",
          display: "flex",
          minHeight: "100%",
          px: 3,
          py: "80px",
        }}
      >
        <Container>
          <Typography
            align="center"
            color="textPrimary"
            variant={mobileDevice ? "h4" : "h1"}
          >
            401: Authorization required
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 0.5 }}
            variant="subtitle2"
          >
            Sorry, it looks like you are not authorized to do this 🛑 ✋
          </Typography>
          <Typography
          align="center"
          color="textSecondary"
          sx={{ mt: 2 }}
          variant="subtitle2"
        >
          Maybe, you can ask your admin to grant you access ?
        </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 0.5 }}
            variant="subtitle2"
          >
            Or you can just simply{" "}
            <Link
              color="textPrimary"
              href="#"
              underline="hover"
              onClick={handleGoHome}
            >
              go back home
            </Link>
            .
          </Typography>
          
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Box
              alt="Authorization required"
              component="img"
              src={`/static/error/error401_${theme.palette.mode}.svg`}
              sx={{
                height: "auto",
                maxWidth: "100%",
                width: 300,
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Button
              color="primary"
              component={RouterLink}
              onClick={handleGoHome}
              to="/app/"
              variant="outlined"
            >
              Back Home
            </Button>
          </Box>
        </Container>
      </Box>
    </Fragment>
  );
};

export default UnauthorizedError;
